import { MoreVert, SvgIconComponent } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { FC, MouseEvent, useState } from "react";

export type TableAction<Row> = {
  name: string | ((row: Row) => string);
  icon: SvgIconComponent;
  onClick: (row: Row) => void | Promise<void>;
};

export interface ActionProps {
  actions: TableAction<any>[];
  params: GridRenderCellParams;
}

const Action: FC<ActionProps> = (props) => {
  const { actions, params } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="primary" onClick={handleOpen} size="large">
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions &&
          actions.map((action, idx) => (
            <MenuItem
              key={idx}
              onClick={async () => {
                handleClose();
                await action.onClick(params.row);
              }}
            >
              <ListItemIcon>
                <action.icon />
              </ListItemIcon>
              {typeof action.name === "string" ? action.name : action.name(params.row)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default Action;
