import { Delete } from "@mui/icons-material";
import { updateCacheAfterRemove } from "@placehires/react-component-library";
import React from "react";
import GenericTable from "../../components/table/GenericTable";
import {
  useCertificationAllQuery,
  useCreateSkillsMutation,
  useDeleteCertificationMutation,
  useDeleteSkillMutation,
  useSkillAllQuery
} from "../../generated/graphqlHooks";
import {
  CertificationAllDocument,
  SkillAllDocument,
  SkillFieldsInput
} from "../../generated/graphqlTypes";
import useModalState from "../../hooks/useModalState";
import CreateSkillAndCertificationModal from "./CreateSkillAndCertificationModal";

const ManageSkillsAndCertifications = () => {
  const { data, loading, refetch } = useSkillAllQuery();
  const { data: certificationData, loading: certificationLoading } = useCertificationAllQuery();
  const [createSkillOpen, handleCreateSkillOpen, handleCreateSkillClose] = useModalState();
  const [createCertificationOpen, handleCreateCertificationOpen, handleCreateCertificationClose] =
    useModalState();
  const [deleteSkill] = useDeleteSkillMutation();
  const [deleteCertification] = useDeleteCertificationMutation();

  const [createSkills, { loading: loadingCreateSkills }] = useCreateSkillsMutation();

  return (
    <>
      <GenericTable
        title="Skills"
        description="All pre-created skills"
        columns={["name"]}
        rows={data?.skills}
        loading={loading || loadingCreateSkills}
        toolbarProps={{
          primaryButton: {
            text: "Create new Skill",
            onClick: handleCreateSkillOpen
          },
          importButton: {
            text: "Import Skills from CSV",
            onFileParsed: async (skills) => {
              await createSkills({ variables: { skillsFields: skills as SkillFieldsInput[] } });
              await refetch();
            },
            failedReportFilename: "Failed_skills"
          }
        }}
        actions={[
          {
            name: "Delete Skill",
            icon: Delete,
            onClick: (skill) => {
              deleteSkill({
                variables: {
                  id: skill._id
                },
                update: (cache) => {
                  updateCacheAfterRemove(cache, SkillAllDocument, skill._id);
                }
              }).catch((_) => {});
            }
          }
        ]}
      />
      <CreateSkillAndCertificationModal
        key="createSkillModal"
        open={createSkillOpen}
        handleClose={handleCreateSkillClose}
        type="skill"
      />
      <GenericTable
        title="Certifications"
        description="All pre-created certifications"
        columns={["name"]}
        rows={certificationData?.certifications}
        loading={certificationLoading}
        toolbarProps={{
          primaryButton: {
            text: "Create new Certification",
            onClick: handleCreateCertificationOpen
          }
        }}
        actions={[
          {
            name: "Delete Certification",
            icon: Delete,
            onClick: (certificate) => {
              deleteCertification({
                variables: {
                  id: certificate._id
                },
                update: (cache) => {
                  updateCacheAfterRemove(cache, CertificationAllDocument, certificate._id);
                }
              }).catch((_) => {});
            }
          }
        ]}
      />
      <CreateSkillAndCertificationModal
        key="createCertificationModal"
        open={createCertificationOpen}
        handleClose={handleCreateCertificationClose}
        type="certification"
      />
    </>
  );
};

export default ManageSkillsAndCertifications;
