/* This file was generated based on "codegen.yml". Do not edit manually. */
/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: Date;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** Integer Range scalar */
  IntRange: any;
  /** Language scalar as specified by ISO 639-1. */
  Language: any;
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: any;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: any;
  /** Integers that will have a value greater than 0. */
  PositiveInt: any;
  /** Url scalar same as https://github.com/Urigo/graphql-scalars/blob/master/src/scalars/URL.ts, but treats empty string as null */
  URL: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: File;
};

/** Admin user of the platform */
export type Admin = {
  __typename?: 'Admin';
  _id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
};

/** Sign up fields to create an admin user */
export type AdminSignUpInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

/** Questionnaire answer for some question */
export type Answer = {
  __typename?: 'Answer';
  optionId?: Maybe<Scalars['ObjectID']>;
  question: Question;
  questionId: Scalars['ObjectID'];
};

/** An applicant user on the platform */
export type Applicant = {
  __typename?: 'Applicant';
  _id: Scalars['ID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  certifications?: Maybe<Array<ApplicantCertification>>;
  education?: Maybe<Array<Education>>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Array<Experience>>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['ObjectID']>;
  jobPreferences?: Maybe<JobPreferences>;
  lastName: Scalars['String'];
  projects?: Maybe<Array<Project>>;
  resumeURL?: Maybe<Scalars['URL']>;
  skills?: Maybe<Array<Scalars['ObjectID']>>;
  summary?: Maybe<Scalars['String']>;
};

/** Applicant Certification details */
export type ApplicantCertification = {
  __typename?: 'ApplicantCertification';
  /** This _id must match _id of one of existing Certifications */
  _id: Scalars['ObjectID'];
  credentialID?: Maybe<Scalars['String']>;
  credentialURL?: Maybe<Scalars['URL']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  issuingOrganization: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** Applicant's certification details input */
export type ApplicantCertificationInput = {
  credentialID?: InputMaybe<Scalars['String']>;
  credentialURL?: InputMaybe<Scalars['URL']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  issueDate?: InputMaybe<Scalars['DateTime']>;
  issuingOrganization: Scalars['String'];
};

/** Intro fields of applicant and recruiters */
export type ApplicantRecruiterIntroInput = {
  backgroundFile?: InputMaybe<Scalars['Upload']>;
  firstName?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  industryId?: InputMaybe<Scalars['ObjectID']>;
  lastName?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
};

/** Sign up fields to create applicant or recruiter user */
export type ApplicantRecruiterSignUpInput = {
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  industry: Scalars['ObjectID'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

/** A job application sent on the platform */
export type Application = {
  __typename?: 'Application';
  _id: Scalars['ObjectID'];
  applicant?: Maybe<Applicant>;
  applicantId?: Maybe<Scalars['ID']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['ObjectID']>;
  matchDetails?: Maybe<MatchDetails>;
  status?: Maybe<ApplicationStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Status of an application */
export enum ApplicationStatus {
  CONSIDERED_MATCH = 'CONSIDERED_MATCH',
  CREATED = 'CREATED',
  DISCARDED = 'DISCARDED',
  HIRED = 'HIRED',
  INTERESTED = 'INTERESTED',
  INTERVIEWED = 'INTERVIEWED',
  MATCHED = 'MATCHED',
  NO_LONGER_UNDER_CONSIDERATION = 'NO_LONGER_UNDER_CONSIDERATION',
  SELECTED_FOR_INTERVIEW = 'SELECTED_FOR_INTERVIEW',
  UNDER_REVIEW = 'UNDER_REVIEW'
}

/** Certifications for job template */
export type Certification = {
  __typename?: 'Certification';
  _id: Scalars['ObjectID'];
  name?: Maybe<Scalars['String']>;
};

/** A company on the platform */
export type Company = {
  __typename?: 'Company';
  _id: Scalars['ObjectID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  companySize?: Maybe<Scalars['Int']>;
  headquarters?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['URL']>>;
  industryId?: Maybe<Scalars['ObjectID']>;
  industryName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['URL']>;
};

export type CompanyBasicInfoInput = {
  companySize?: InputMaybe<Scalars['Int']>;
  headquarters?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['URL']>>;
  industryId?: InputMaybe<Scalars['ObjectID']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['URL']>;
};

/** Input to create a Company (when signing up a recruiter) */
export type CompanySignUpInput = {
  name: Scalars['String'];
  size: Scalars['NonNegativeInt'];
};

/** Education details */
export type Education = {
  __typename?: 'Education';
  _id: Scalars['ObjectID'];
  degree: EducationDegree;
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  field?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  institution: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** Education degree */
export enum EducationDegree {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  POST_SECONDARY = 'POST_SECONDARY'
}

/** User's education details input */
export type EducationInput = {
  degree: EducationDegree;
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  field?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Scalars['String']>;
  institution: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** Employment type */
export enum EmploymentType {
  CONTRACTOR = 'CONTRACTOR',
  FULL_TIME = 'FULL_TIME',
  INTERN = 'INTERN',
  PART_TIME = 'PART_TIME'
}

/** Error codes for Apollo Errors */
export enum ErrorCode {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  BATCH_INSERT_ERROR = 'BATCH_INSERT_ERROR',
  CONFLICT = 'CONFLICT',
  FORBIDDEN = 'FORBIDDEN',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  NOT_FOUND = 'NOT_FOUND',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  UNAUTHENTICATED = 'UNAUTHENTICATED'
}

/** Work experience details */
export type Experience = {
  __typename?: 'Experience';
  _id: Scalars['ObjectID'];
  company: Scalars['String'];
  companyAvatarURL?: Maybe<Scalars['URL']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employmentType?: Maybe<EmploymentType>;
  endDate?: Maybe<Scalars['DateTime']>;
  position: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** User's work experience details input */
export type ExperienceInput = {
  company: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  employmentType?: InputMaybe<EmploymentType>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  position: Scalars['String'];
  startDate: Scalars['DateTime'];
};

/** User feedback */
export type Feedback = {
  __typename?: 'Feedback';
  _id: Scalars['ObjectID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['PositiveInt']>;
  sender?: Maybe<Scalars['String']>;
};

/** Industry that the app supports */
export type Industry = {
  __typename?: 'Industry';
  _id: Scalars['ObjectID'];
  active?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** Interview */
export type Interview = {
  __typename?: 'Interview';
  _id: Scalars['ObjectID'];
  applicantId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  jobId: Scalars['ObjectID'];
  start?: Maybe<Scalars['DateTime']>;
};

/** Interview details */
export type InterviewDetails = {
  __typename?: 'InterviewDetails';
  description?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  minimumNotice?: Maybe<Scalars['Int']>;
};

export type InterviewDetailsFieldsInput = {
  description?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  minimumNotice?: InputMaybe<Scalars['Int']>;
};

export type InterviewFieldsInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  jobId: Scalars['ObjectID'];
  start?: InputMaybe<Scalars['DateTime']>;
};

/** Issues reported by users */
export type Issue = {
  __typename?: 'Issue';
  _id: Scalars['ObjectID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  sender?: Maybe<Scalars['String']>;
};

/** A job posting on the platform */
export type Job = {
  __typename?: 'Job';
  _id: Scalars['ObjectID'];
  certifications?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  company?: Maybe<Company>;
  companyId: Scalars['ObjectID'];
  description: Scalars['String'];
  employmentType: EmploymentType;
  filterDeadline: Scalars['DateTime'];
  interviewDetails?: Maybe<InterviewDetails>;
  language?: Maybe<Scalars['Language']>;
  languageLevel?: Maybe<LanguageProficiencyType>;
  location: Scalars['String'];
  maxAppliedApplicants: Scalars['NonNegativeInt'];
  maxFilteredApplicants: Scalars['NonNegativeInt'];
  position?: Maybe<Position>;
  positionId: Scalars['ObjectID'];
  recruiterId: Scalars['ID'];
  salary?: Maybe<Scalars['IntRange']>;
  skills?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  status?: Maybe<JobStatus>;
  yearsOfExperience?: Maybe<Scalars['IntRange']>;
};

export type JobFieldsInput = {
  certifications?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  employmentType: EmploymentType;
  filterDeadline: Scalars['DateTime'];
  language?: InputMaybe<Scalars['Language']>;
  languageLevel?: InputMaybe<LanguageProficiencyType>;
  location: Scalars['String'];
  maxAppliedApplicants: Scalars['NonNegativeInt'];
  maxFilteredApplicants: Scalars['NonNegativeInt'];
  positionId: Scalars['ObjectID'];
  salary?: InputMaybe<Scalars['IntRange']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  status?: InputMaybe<JobStatus>;
  yearsOfExperience?: InputMaybe<Scalars['IntRange']>;
};

/** Applicant job preferences */
export type JobPreferences = {
  __typename?: 'JobPreferences';
  jobTypes?: Maybe<Array<Maybe<EmploymentType>>>;
  positionsIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  seeking?: Maybe<Scalars['Boolean']>;
};

/** Applicant's job preferences details input */
export type JobPreferencesInput = {
  jobTypes?: InputMaybe<Array<InputMaybe<EmploymentType>>>;
  positionsIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  seeking?: InputMaybe<Scalars['Boolean']>;
};

/** Status of a job posting */
export enum JobStatus {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  FILTERED = 'FILTERED',
  HIRED = 'HIRED',
  MATCHED = 'MATCHED',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED'
}

/** A job template on the platform */
export type JobTemplate = {
  __typename?: 'JobTemplate';
  _id: Scalars['ObjectID'];
  certifications?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  position?: Maybe<Position>;
  positionId: Scalars['ObjectID'];
  skills?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
};

export type JobTemplateFieldsInput = {
  certifications?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  description: Scalars['String'];
  positionId: Scalars['ObjectID'];
  skills?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
};

/** Language proficiency type */
export enum LanguageProficiencyType {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  ELEMENTARY = 'ELEMENTARY',
  INTERMEDIATE = 'INTERMEDIATE',
  PROFICIENT = 'PROFICIENT'
}

/** Match details of current application */
export type MatchDetails = {
  __typename?: 'MatchDetails';
  numSkillsMatched: Scalars['NonNegativeInt'];
  questionnaireMatchScore: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a new certification record to applicant */
  addApplicantCertification?: Maybe<ApplicantCertification>;
  /** Add a new education record to applicant */
  addApplicantEducation?: Maybe<Education>;
  /** Add a new experience record to applicant */
  addApplicantExperience?: Maybe<Experience>;
  /** Approve the job and make it available to applicants */
  approveJob?: Maybe<Job>;
  /** Create applicant profile for existing firebase user (used with third party sign up) */
  createApplicantProfile?: Maybe<Applicant>;
  /** Create a billing portal session for a customer. Returns url of customer's billing portal. Only users with stripe id allowed */
  createBillingPortalSession: Scalars['String'];
  /** Create a new certification */
  createCertification?: Maybe<Certification>;
  /** Create a new feedback. Gives a TOO_MANY_REQUESTS error when last feedback was less than 4 months ago */
  createFeedback?: Maybe<Feedback>;
  /** Create a new industry */
  createIndustry?: Maybe<Industry>;
  /** Create a new interview slot */
  createInterview?: Maybe<Interview>;
  /** Create (report) a new issue */
  createIssue: Issue;
  /** Create a new job */
  createJob?: Maybe<Job>;
  /** Create a new job template */
  createJobTemplate?: Maybe<JobTemplate>;
  /** Create a new option group */
  createOptionGroup?: Maybe<OptionGroup>;
  /** Create a new position */
  createPosition?: Maybe<Position>;
  /** Create a new question */
  createQuestion?: Maybe<Question>;
  /** Create a new questionnaire */
  createQuestionnaire?: Maybe<Questionnaire>;
  /** Create a new questionnaire variable */
  createQuestionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Create new questions */
  createQuestions?: Maybe<Array<Maybe<Question>>>;
  /** Create a new skill */
  createSkill?: Maybe<Skill>;
  /** Create new skills */
  createSkills?: Maybe<Array<Maybe<Skill>>>;
  /**
   * Create Stripe subscription checkout session. Returns a Stripe session id.
   *         Throws ConflictError if user already has a subscription.
   *
   */
  createSubscriptionCheckoutSession: Scalars['ID'];
  /** Delete an existing education record from an applicant */
  deleteApplicantCertification?: Maybe<ApplicantCertification>;
  /** Delete an existing education record from an applicant */
  deleteApplicantEducation?: Maybe<Education>;
  /** Delete an existing experience record from an applicant */
  deleteApplicantExperience?: Maybe<Experience>;
  /** Delete a specific certification */
  deleteCertification?: Maybe<Certification>;
  /** Delete a an industry by id */
  deleteIndustry?: Maybe<Industry>;
  /** Delete an interview slot */
  deleteInterview?: Maybe<Interview>;
  /** Delete a specific job template */
  deleteJobTemplate?: Maybe<JobTemplate>;
  /** Delete a option group. This is only allowed if there aren't any questions that use this group */
  deleteOptionGroup?: Maybe<OptionGroup>;
  /** Delete an existing position if unused */
  deletePosition?: Maybe<Position>;
  /** Delete a question. This is only if question is not used by questionnaire */
  deleteQuestion?: Maybe<Question>;
  /** Delete a questionnaire variable. This is only allowed if there aren't any questions that use this variable */
  deleteQuestionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Delete a specific skill */
  deleteSkill?: Maybe<Skill>;
  /** Disapprove the job. The job stays hidden from applicants */
  disapproveJob?: Maybe<Job>;
  /** Update application status to MATCHED and ask applicants to confirm interest */
  filterApplications: Array<Maybe<Application>>;
  /**
   * Paused for manual override. Applications will not be automatically matched.
   *       This should be done when we have insufficient number of applicants for the job and we want to delay matching
   */
  pauseJob?: Maybe<Job>;
  /**
   * Post-process successful checkout . Returns the customer stripe id of the session.
   *         Note: this may update user's custom claims, so refresh firebase token!
   *
   */
  processSuccessfulCheckoutSession: Scalars['ID'];
  /** Update the read status of a notification to true */
  readNotification: Notification;
  /**
   * Remove application, used when applicant decides they are no longer interested in
   *        the job (this will set application status to 'discarded', so it can still be searched if needed
   */
  removeApplication?: Maybe<Application>;
  /** Mark issue as resolved */
  resolveIssue: Issue;
  /** Assign an applicantId to an interview */
  selectInterview?: Maybe<Interview>;
  /** Sign up an Admin user. Only another admin can do this. */
  signUpAdmin?: Maybe<Admin>;
  /** Sign up an Applicant user */
  signUpApplicant?: Maybe<Applicant>;
  /** Sign up a Recruiter user */
  signUpRecruiter?: Maybe<Recruiter>;
  /** Unpause to continue the matching process */
  unpauseJob?: Maybe<Job>;
  /** Mark issue as unresolved */
  unresolveIssue: Issue;
  /** (Partially) Update a specific applicant user profile details. Only provided fields will be updated */
  updateApplicant?: Maybe<Applicant>;
  /** Update an existing certification record from an applicant */
  updateApplicantCertification?: Maybe<ApplicantCertification>;
  /** Update an existing education record from an applicant */
  updateApplicantEducation?: Maybe<Education>;
  /** Update an existing experience record from an applicant */
  updateApplicantExperience?: Maybe<Experience>;
  /** Update a specific applicant user job details */
  updateApplicantJobPreferences?: Maybe<Applicant>;
  /** Update application status accordingly */
  updateApplicationStatus?: Maybe<Application>;
  /** Update a company's profile details */
  updateCompany?: Maybe<Company>;
  /** Update industry information */
  updateIndustry?: Maybe<Industry>;
  /** Update the details of a specific job posting's interview */
  updateInterviewDetails?: Maybe<Job>;
  /** Update the details of a specific job posting */
  updateJob?: Maybe<Job>;
  /** Update the details of a specific job template */
  updateJobTemplate?: Maybe<JobTemplate>;
  /** Update questionnaire answers */
  updateQuestionnaireAnswers?: Maybe<QuestionnaireResult>;
  /** Update the profile details of a specific recruiter user */
  updateRecruiter?: Maybe<Recruiter>;
  /** Update verified status, only allowed by admins */
  verifyRecruiter?: Maybe<Recruiter>;
};


export type MutationAddApplicantCertificationArgs = {
  _id: Scalars['ObjectID'];
  certificationFields: ApplicantCertificationInput;
};


export type MutationAddApplicantEducationArgs = {
  educationFields: EducationInput;
};


export type MutationAddApplicantExperienceArgs = {
  experienceFields: ExperienceInput;
};


export type MutationApproveJobArgs = {
  id: Scalars['ID'];
};


export type MutationCreateApplicantProfileArgs = {
  uid: Scalars['String'];
};


export type MutationCreateBillingPortalSessionArgs = {
  returnUrl: Scalars['URL'];
};


export type MutationCreateCertificationArgs = {
  name: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  message: Scalars['String'];
  rating: Scalars['PositiveInt'];
};


export type MutationCreateIndustryArgs = {
  name: Scalars['String'];
};


export type MutationCreateInterviewArgs = {
  interviewFields: InterviewFieldsInput;
};


export type MutationCreateIssueArgs = {
  message: Scalars['String'];
};


export type MutationCreateJobArgs = {
  jobFields: JobFieldsInput;
};


export type MutationCreateJobTemplateArgs = {
  jobTemplateFields: JobTemplateFieldsInput;
};


export type MutationCreateOptionGroupArgs = {
  optionGroupFields: OptionGroupInput;
};


export type MutationCreatePositionArgs = {
  industries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
};


export type MutationCreateQuestionArgs = {
  questionFields: QuestionInput;
};


export type MutationCreateQuestionnaireArgs = {
  questionnaireFields: QuestionnaireInput;
};


export type MutationCreateQuestionnaireVariableArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateQuestionsArgs = {
  questionsFields: Array<QuestionInput>;
};


export type MutationCreateSkillArgs = {
  skillFields: SkillFieldsInput;
};


export type MutationCreateSkillsArgs = {
  skillsFields: Array<SkillFieldsInput>;
};


export type MutationCreateSubscriptionCheckoutSessionArgs = {
  cancelUrl: Scalars['URL'];
  plan: SubscriptionPlan;
  successUrl: Scalars['URL'];
};


export type MutationDeleteApplicantCertificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApplicantEducationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteApplicantExperienceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCertificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIndustryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInterviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOptionGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeletePositionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuestionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteQuestionnaireVariableArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteSkillArgs = {
  id: Scalars['ID'];
};


export type MutationDisapproveJobArgs = {
  id: Scalars['ID'];
};


export type MutationFilterApplicationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationPauseJobArgs = {
  id: Scalars['ID'];
};


export type MutationProcessSuccessfulCheckoutSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationReadNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveApplicationArgs = {
  id: Scalars['ID'];
};


export type MutationResolveIssueArgs = {
  id: Scalars['ID'];
};


export type MutationSelectInterviewArgs = {
  id: Scalars['ID'];
};


export type MutationSignUpAdminArgs = {
  userInput: AdminSignUpInput;
};


export type MutationSignUpApplicantArgs = {
  signUpInput: ApplicantRecruiterSignUpInput;
};


export type MutationSignUpRecruiterArgs = {
  companyInput: CompanySignUpInput;
  userInput: ApplicantRecruiterSignUpInput;
};


export type MutationUnpauseJobArgs = {
  id: Scalars['ID'];
};


export type MutationUnresolveIssueArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateApplicantArgs = {
  applicantRecruiterIntroInput?: InputMaybe<ApplicantRecruiterIntroInput>;
  certifications?: InputMaybe<Array<Scalars['ObjectID']>>;
  resumeFile?: InputMaybe<Scalars['Upload']>;
  skills?: InputMaybe<Array<Scalars['ObjectID']>>;
};


export type MutationUpdateApplicantCertificationArgs = {
  certificationFields: ApplicantCertificationInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantEducationArgs = {
  educationFields: EducationInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantExperienceArgs = {
  experienceFields: ExperienceInput;
  id: Scalars['ID'];
};


export type MutationUpdateApplicantJobPreferencesArgs = {
  jobPreferences: JobPreferencesInput;
};


export type MutationUpdateApplicationStatusArgs = {
  id: Scalars['ID'];
  status: ApplicationStatus;
};


export type MutationUpdateCompanyArgs = {
  avatarFile?: InputMaybe<Scalars['Upload']>;
  backgroundFile?: InputMaybe<Scalars['Upload']>;
  basicInfo?: InputMaybe<CompanyBasicInfoInput>;
  id: Scalars['ID'];
};


export type MutationUpdateIndustryArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateInterviewDetailsArgs = {
  id: Scalars['ID'];
  interviewDetailsFields: InterviewDetailsFieldsInput;
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID'];
  jobFields: JobFieldsInput;
};


export type MutationUpdateJobTemplateArgs = {
  id: Scalars['ID'];
  updateFields: JobTemplateFieldsInput;
};


export type MutationUpdateQuestionnaireAnswersArgs = {
  answers: Array<InputMaybe<QuestionnaireAnswerInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateRecruiterArgs = {
  applicantRecruiterIntroInput: ApplicantRecruiterIntroInput;
};


export type MutationVerifyRecruiterArgs = {
  id: Scalars['ID'];
};

/** A notification sent on the platform */
export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['ObjectID'];
  avatarURL?: Maybe<Scalars['URL']>;
  avatarVariant?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<Scalars['String']>;
  redirectLink?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
};

/** A group of reusable options */
export type OptionGroup = {
  __typename?: 'OptionGroup';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
  options: Array<Maybe<QuestionOption>>;
  withScores?: Maybe<Scalars['Boolean']>;
};

export type OptionGroupInput = {
  name: Scalars['String'];
  options: Array<QuestionOptionInput>;
};

/** A position on job template */
export type Position = {
  __typename?: 'Position';
  _id: Scalars['ObjectID'];
  industries?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  jobTemplate?: Maybe<JobTemplate>;
  name?: Maybe<Scalars['String']>;
};

/** Project details */
export type Project = {
  __typename?: 'Project';
  _id: Scalars['ObjectID'];
  description: Scalars['String'];
  link?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Get all admin users of the platform */
  admins?: Maybe<Array<Maybe<Admin>>>;
  /** Get a specific applicant user of the platform */
  applicant?: Maybe<Applicant>;
  /** Get applicant's job application */
  applicantApplication?: Maybe<Application>;
  /** Get applicant's applications with CREATED status (applications with CREATED status are applications to which applicants need to show interest in before the matching begins) */
  applicantCreatedApplications?: Maybe<Array<Application>>;
  /** Get applicant's applications that he/she can view */
  applicantViewableApplications?: Maybe<Array<Application>>;
  /** Get a specific job application */
  application?: Maybe<Application>;
  /** Get a specific certification */
  certification?: Maybe<Certification>;
  /** Get all certifications */
  certifications?: Maybe<Array<Certification>>;
  /** Get all companies on the platform */
  companies?: Maybe<Array<Maybe<Company>>>;
  /** Get a specific company on the platform */
  company?: Maybe<Company>;
  /** Count number of applications filtered for the job */
  countJobMatchedApplications: Scalars['Int'];
  /** Get a specific company on the platform */
  currentRecruiterCompany?: Maybe<Company>;
  /** Get user feedback (paginated) */
  feedback?: Maybe<Array<Feedback>>;
  /** Get all industries */
  industries?: Maybe<Array<Industry>>;
  /** Get a specific industry */
  industry?: Maybe<Industry>;
  /** Get a specific interview */
  interview?: Maybe<Interview>;
  /** Get all interviews for an applicant */
  interviewsForApplicant?: Maybe<Array<Interview>>;
  /** Get all interviews for a job */
  interviewsForJob?: Maybe<Array<Interview>>;
  /** Get reported issues (paginated) */
  issues?: Maybe<Array<Issue>>;
  /** Get a specific job posting */
  job?: Maybe<Job>;
  /** Get applications of applicants with CONSIDERED_MATCH status - applicants we're considering to send to recruiter */
  jobConsideredMatchApplications?: Maybe<Array<Application>>;
  /** Get applications of applicants with INTERESTED status. Used to show applicants that need filtering */
  jobInterestedApplications?: Maybe<Array<Application>>;
  /** Get a specific job template */
  jobTemplate?: Maybe<JobTemplate>;
  /** Get job template of a position. */
  jobTemplateByPosition?: Maybe<JobTemplate>;
  /** Get all job templates. Only admin users can do this. */
  jobTemplates?: Maybe<Array<JobTemplate>>;
  /** Get last user feedback */
  lastFeedback?: Maybe<Feedback>;
  /** Get all matched jobs - jobs that required applicant filtering. Only admin users can view them */
  matchedJobs?: Maybe<Array<Job>>;
  /** Get all matched and paused jobs. Only admin users can view them */
  matchedOrPausedJobs?: Maybe<Array<Job>>;
  /** Get all notifications of the current user (limited at 20) */
  notifications?: Maybe<Array<Notification>>;
  /** Get all recently posted jobs - jobs that required review to be visible to applicants */
  openJobs?: Maybe<Array<Job>>;
  /** Get all option groups */
  optionGroups?: Maybe<Array<OptionGroup>>;
  /** Get a specific position */
  position?: Maybe<Position>;
  /** Get all position. Only admin users can do this. */
  positions?: Maybe<Array<Position>>;
  /** Get user questionnaire result */
  questionnaireResult: QuestionnaireResult;
  /** Get user questionnaire result progress (% questions answered) */
  questionnaireResultProgress: Scalars['NonNegativeInt'];
  /** Get a specific variable */
  questionnaireVariable?: Maybe<QuestionnaireVariable>;
  /** Get all variables */
  questionnaireVariables?: Maybe<Array<QuestionnaireVariable>>;
  /** Get all questionnaires */
  questionnaires?: Maybe<Array<Questionnaire>>;
  /** Get all questions with variables */
  questionsWithVariables?: Maybe<Array<Question>>;
  /** Get a specific recruiter user on the platform */
  recruiter?: Maybe<Recruiter>;
  /** Get all job applications of all jobs of the given status posted by this current recruiter user */
  recruiterApplicationsByStatus?: Maybe<Array<Application>>;
  /** Get current recruiter's jobs */
  recruiterJobs?: Maybe<Array<Job>>;
  /** Get job's applications that recruiter can view */
  recruiterViewableJobApplications?: Maybe<Array<Application>>;
  /** Get resolved issues on the platform. Admin users allowed only */
  resolvedIssues?: Maybe<Array<Issue>>;
  /** Get a specific skill */
  skill?: Maybe<Skill>;
  /** Get all skills */
  skills?: Maybe<Array<Skill>>;
  /** Get unresolved issues on the platform. Admin users allowed only */
  unresolvedIssues?: Maybe<Array<Issue>>;
  /** Get unverified recruiters on the platform. Admin users allowed only. */
  unverifiedRecruiters?: Maybe<Array<Recruiter>>;
};


export type QueryApplicantArgs = {
  id: Scalars['ID'];
};


export type QueryApplicantApplicationArgs = {
  jobId: Scalars['ID'];
};


export type QueryApplicationArgs = {
  id: Scalars['ID'];
};


export type QueryCertificationArgs = {
  id: Scalars['ID'];
};


export type QueryCertificationsArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryCompaniesArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCountJobMatchedApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryFeedbackArgs = {
  itemsPerPage: Scalars['PositiveInt'];
  page: Scalars['PositiveInt'];
};


export type QueryIndustriesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};


export type QueryIndustryArgs = {
  id: Scalars['ID'];
};


export type QueryInterviewArgs = {
  id: Scalars['ID'];
};


export type QueryInterviewsForJobArgs = {
  jobId: Scalars['ObjectID'];
};


export type QueryIssuesArgs = {
  itemsPerPage: Scalars['PositiveInt'];
  page: Scalars['PositiveInt'];
  resolved?: InputMaybe<Scalars['Boolean']>;
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryJobConsideredMatchApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobInterestedApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryJobTemplateByPositionArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  receiver: Scalars['ID'];
};


export type QueryPositionArgs = {
  id: Scalars['ID'];
};


export type QueryPositionsArgs = {
  industry?: InputMaybe<Scalars['ObjectID']>;
  sortNameBy?: InputMaybe<SortOrder>;
};


export type QueryQuestionnaireVariableArgs = {
  id: Scalars['ID'];
};


export type QueryRecruiterArgs = {
  id: Scalars['ID'];
};


export type QueryRecruiterApplicationsByStatusArgs = {
  status: ApplicationStatus;
};


export type QueryRecruiterViewableJobApplicationsArgs = {
  jobId: Scalars['ID'];
};


export type QuerySkillArgs = {
  id: Scalars['ID'];
};


export type QuerySkillsArgs = {
  sortNameBy?: InputMaybe<SortOrder>;
};

/** Question used in questionnaires */
export type Question = {
  __typename?: 'Question';
  _id: Scalars['ObjectID'];
  optionGroupId?: Maybe<Scalars['ObjectID']>;
  options: Array<QuestionOption>;
  singleVariableDetails?: Maybe<SingleVariableDetails>;
  title: Scalars['String'];
  twoVariablesDetails?: Maybe<TwoVariablesDetails>;
  type: QuestionType;
  userTarget: Role;
};

export type QuestionInput = {
  optionGroupId?: InputMaybe<Scalars['ObjectID']>;
  options?: InputMaybe<Array<QuestionOptionInput>>;
  singleVariableDetails?: InputMaybe<SingleVariableDetailsInput>;
  title: Scalars['String'];
  twoVariablesDetails?: InputMaybe<TwoVariablesDetailsInput>;
  type: QuestionType;
  userTarget: Role;
};

/** An option in a question */
export type QuestionOption = {
  __typename?: 'QuestionOption';
  _id: Scalars['ObjectID'];
  score?: Maybe<Scalars['Float']>;
  text: Scalars['String'];
};

export type QuestionOptionInput = {
  score?: InputMaybe<Scalars['Float']>;
  text: Scalars['String'];
};

/** Type of questionnaire question */
export enum QuestionType {
  CHECKBOX = 'CHECKBOX',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_VARIABLE = 'SINGLE_VARIABLE',
  TEXT_LONG = 'TEXT_LONG',
  TEXT_SHORT = 'TEXT_SHORT',
  TWO_VARIABLES = 'TWO_VARIABLES'
}

/** Questionnaire used to help the matching */
export type Questionnaire = {
  __typename?: 'Questionnaire';
  _id: Scalars['ObjectID'];
  industryId: Scalars['ObjectID'];
  industryName: Scalars['String'];
  nonVarQuestionsIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  singleVarConfigs?: Maybe<Array<Maybe<SingleVarConfig>>>;
  twoVarIds?: Maybe<Array<Maybe<Scalars['ObjectID']>>>;
  twoVariables?: Maybe<Array<Maybe<QuestionnaireVariable>>>;
  userTarget: Role;
};

export type QuestionnaireAnswerInput = {
  optionId?: InputMaybe<Scalars['ObjectID']>;
  questionId: Scalars['ObjectID'];
};

export type QuestionnaireInput = {
  industryId: Scalars['ObjectID'];
  nonVarQuestionsIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  singleVarConfigs?: InputMaybe<Array<InputMaybe<SingleVarConfigInput>>>;
  twoVarIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectID']>>>;
  userTarget: Role;
};

/** User's questionnaire result object */
export type QuestionnaireResult = {
  __typename?: 'QuestionnaireResult';
  _id: Scalars['ObjectID'];
  answers: Array<Maybe<Answer>>;
  completed: Scalars['Boolean'];
  questionnaireId: Scalars['ObjectID'];
  uid: Scalars['String'];
};

/** A variable to be measured using questionnaire */
export type QuestionnaireVariable = {
  __typename?: 'QuestionnaireVariable';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
};

/** Recruiter user of the platform */
export type Recruiter = {
  __typename?: 'Recruiter';
  _id: Scalars['ID'];
  avatarURL?: Maybe<Scalars['URL']>;
  backgroundURL?: Maybe<Scalars['URL']>;
  company?: Maybe<Company>;
  companyId: Scalars['ObjectID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  industryId?: Maybe<Scalars['ObjectID']>;
  lastName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** User role */
export enum Role {
  ADMIN = 'ADMIN',
  APPLICANT = 'APPLICANT',
  RECRUITER = 'RECRUITER'
}

/** Configuration object for single variables within questionnaire */
export type SingleVarConfig = {
  __typename?: 'SingleVarConfig';
  numQuestions?: Maybe<Scalars['PositiveInt']>;
  variable: QuestionnaireVariable;
  variableId: Scalars['ObjectID'];
};

export type SingleVarConfigInput = {
  numQuestions?: InputMaybe<Scalars['PositiveInt']>;
  variableId: Scalars['ObjectID'];
};

/** Details for `singleVariable` type questions */
export type SingleVariableDetails = {
  __typename?: 'SingleVariableDetails';
  variableId: Scalars['ObjectID'];
  variableName: Scalars['String'];
  variableResult: VariableResult;
};

export type SingleVariableDetailsInput = {
  variableId: Scalars['ObjectID'];
  variableResult: VariableResult;
};

/** Skills for job template */
export type Skill = {
  __typename?: 'Skill';
  _id: Scalars['ObjectID'];
  name: Scalars['String'];
};

export type SkillFieldsInput = {
  name: Scalars['String'];
};

/** Sort order for appropriate queries */
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  /** Add a new notification for a specific user. */
  notificationAdded?: Maybe<Notification>;
};


export type SubscriptionNotificationAddedArgs = {
  receiver: Scalars['ID'];
};

/** Recruiter subscription plans */
export enum SubscriptionPlan {
  Basic = 'Basic'
}

/** Details for `twoVariables` type questions */
export type TwoVariablesDetails = {
  __typename?: 'TwoVariablesDetails';
  negativeVariableId: Scalars['ObjectID'];
  negativeVariableName: Scalars['String'];
  positiveVariableId: Scalars['ObjectID'];
  positiveVariableName: Scalars['String'];
};

export type TwoVariablesDetailsInput = {
  negativeVariableId: Scalars['ObjectID'];
  positiveVariableId: Scalars['ObjectID'];
};

/** Variable result type */
export enum VariableResult {
  NEGATIVE = 'NEGATIVE',
  PLACEBO = 'PLACEBO',
  POSITIVE = 'POSITIVE'
}

export type ApplicantIntroFieldsFragment = { __typename?: 'Applicant', _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: any, headline?: string, backgroundURL?: any, industryId?: any };

export type EducationFieldsFragment = { __typename?: 'Education', _id: any, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string };

export type ExperienceFieldsFragment = { __typename?: 'Experience', _id: any, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: any, description?: string, startDate: Date, endDate?: Date };

export type ApplicantCertificationFieldsFragment = { __typename?: 'ApplicantCertification', _id: any, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: any };

export type JobPreferencesFieldsFragment = { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<any> };

export type ApplicantFieldsFragment = { __typename?: 'Applicant', resumeURL?: any, industryId?: any, skills?: Array<any>, _id: string, firstName: string, lastName: string, summary?: string, avatarURL?: any, headline?: string, backgroundURL?: any, jobPreferences?: { __typename?: 'JobPreferences', seeking?: boolean, jobTypes?: Array<EmploymentType>, positionsIds?: Array<any> }, experience?: Array<{ __typename?: 'Experience', _id: any, position: string, employmentType?: EmploymentType, company: string, companyId?: string, companyName?: string, companyAvatarURL?: any, description?: string, startDate: Date, endDate?: Date }>, education?: Array<{ __typename?: 'Education', _id: any, institution: string, degree: EducationDegree, startDate: Date, endDate: Date, field?: string, grade?: string, description?: string }>, certifications?: Array<{ __typename?: 'ApplicantCertification', _id: any, name?: string, issuingOrganization: string, issueDate?: Date, expirationDate?: Date, credentialID?: string, credentialURL?: any }> };

export type CompanyFieldsFragment = { __typename?: 'Company', _id: any, avatarURL?: any, backgroundURL?: any, name?: string, overview?: string, headquarters?: string, locations?: Array<string>, industryId?: any, website?: any, companySize?: number };

export type RecruiterFieldsFragment = { __typename?: 'Recruiter', _id: string, avatarURL?: any, backgroundURL?: any, firstName: string, lastName: string, summary?: string, headline?: string, companyId: any, industryId?: any };

export type NotificationFieldsFragment = { __typename?: 'Notification', _id: any, message?: string, read?: boolean, sender?: string, createdAt?: Date, redirectLink?: string };

export type JobBaseFieldsFragment = { __typename?: 'Job', _id: any, positionId: any, employmentType: EmploymentType, recruiterId: string, companyId: any, description: string, location: string, yearsOfExperience?: any };

export type JobFieldsFragment = { __typename?: 'Job', skills?: Array<any>, certifications?: Array<any>, _id: any, positionId: any, employmentType: EmploymentType, recruiterId: string, companyId: any, description: string, location: string, yearsOfExperience?: any };

export type FeedbackFieldsFragment = { __typename?: 'Feedback', _id: any, sender?: string, message?: string, rating?: any };

export type IssueFieldsFragment = { __typename?: 'Issue', _id: any, sender?: string, message?: string };

export type IndustryFieldsFragment = { __typename?: 'Industry', _id: any, name: string, active?: boolean };

export type PositionFieldsFragment = { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> };

export type CertificationFieldsFragment = { __typename?: 'Certification', _id: any, name?: string };

export type JobTemplateFieldsFragment = { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any> };

export type SkillFieldsFragment = { __typename?: 'Skill', _id: any, name: string };

export type MatchDetailsFieldsFragment = { __typename?: 'MatchDetails', questionnaireMatchScore: number, numSkillsMatched: any };

export type ApplicantBaseFieldsFragment = { __typename?: 'Application', _id: any, status?: ApplicationStatus };

export type ApplicationForFilterFragment = { __typename?: 'Application', _id: any, applicant?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string }, matchDetails?: { __typename?: 'MatchDetails', questionnaireMatchScore: number, numSkillsMatched: any } };

export type JobPostingTableInfoFragment = { __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } };

export type QuestionOptionFieldsFragment = { __typename?: 'QuestionOption', _id: any, text: string, score?: number };

export type OptionGroupBaseFieldsFragment = { __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean };

export type OptionGroupFieldsFragment = { __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean, options: Array<{ __typename?: 'QuestionOption', _id: any, text: string, score?: number }> };

export type VariableFieldsFragment = { __typename?: 'QuestionnaireVariable', _id: any, name: string };

export type QuestionBaseFieldsFragment = { __typename?: 'Question', _id: any, title: string, type: QuestionType, userTarget: Role, optionGroupId?: any };

export type QuestionFieldsFragment = { __typename?: 'Question', _id: any, title: string, type: QuestionType, userTarget: Role, optionGroupId?: any, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableId: any, variableResult: VariableResult, variableName: string }, twoVariablesDetails?: { __typename?: 'TwoVariablesDetails', positiveVariableId: any, negativeVariableId: any, negativeVariableName: string, positiveVariableName: string } };

export type QuestionnaireBaseFieldsFragment = { __typename?: 'Questionnaire', _id: any, industryId: any, userTarget: Role, twoVarIds?: Array<any>, nonVarQuestionsIds?: Array<any>, singleVarConfigs?: Array<{ __typename?: 'SingleVarConfig', variableId: any, numQuestions?: any, variable: { __typename?: 'QuestionnaireVariable', _id: any, name: string } }> };

export type QuestionnaireTableFieldsFragment = { __typename?: 'Questionnaire', industryName: string, _id: any, industryId: any, userTarget: Role, twoVarIds?: Array<any>, nonVarQuestionsIds?: Array<any>, twoVariables?: Array<{ __typename?: 'QuestionnaireVariable', name: string }>, singleVarConfigs?: Array<{ __typename?: 'SingleVarConfig', variableId: any, numQuestions?: any, variable: { __typename?: 'QuestionnaireVariable', _id: any, name: string } }> };

export type AdminsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminsQuery = { __typename?: 'Query', admins?: Array<{ __typename?: 'Admin', _id: string, fullName?: string, email?: string }> };

export type SignUpAdminMutationVariables = Exact<{
  userInput: AdminSignUpInput;
}>;


export type SignUpAdminMutation = { __typename?: 'Mutation', signUpAdmin?: { __typename?: 'Admin', fullName?: string, email?: string } };

export type JobInterestedApplicationsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type JobInterestedApplicationsQuery = { __typename?: 'Query', jobInterestedApplications?: Array<{ __typename?: 'Application', _id: any, applicant?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string }, matchDetails?: { __typename?: 'MatchDetails', questionnaireMatchScore: number, numSkillsMatched: any } }> };

export type JobConsideredMatchApplicationQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type JobConsideredMatchApplicationQuery = { __typename?: 'Query', jobConsideredMatchApplications?: Array<{ __typename?: 'Application', _id: any, applicant?: { __typename?: 'Applicant', _id: string, firstName: string, lastName: string }, matchDetails?: { __typename?: 'MatchDetails', questionnaireMatchScore: number, numSkillsMatched: any } }> };

export type NumJobChosenApplicationsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type NumJobChosenApplicationsQuery = { __typename?: 'Query', countJobMatchedApplications: number };

export type UpdateApplicationToConsideredMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateApplicationToConsideredMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: any, status?: ApplicationStatus } };

export type UpdateApplicationToInterestedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UpdateApplicationToInterestedMutation = { __typename?: 'Mutation', updateApplicationStatus?: { __typename?: 'Application', _id: any, status?: ApplicationStatus } };

export type FilterApplicationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type FilterApplicationsMutation = { __typename?: 'Mutation', filterApplications: Array<{ __typename?: 'Application', _id: any, status?: ApplicationStatus }> };

export type CertificationBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CertificationBasicQuery = { __typename?: 'Query', certification?: { __typename?: 'Certification', _id: any, name?: string } };

export type CertificationAllQueryVariables = Exact<{ [key: string]: never; }>;


export type CertificationAllQuery = { __typename?: 'Query', certifications?: Array<{ __typename?: 'Certification', _id: any, name?: string }> };

export type CreateCertificationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCertificationMutation = { __typename?: 'Mutation', createCertification?: { __typename?: 'Certification', _id: any, name?: string } };

export type DeleteCertificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCertificationMutation = { __typename?: 'Mutation', deleteCertification?: { __typename?: 'Certification', _id: any, name?: string } };

export type UserFeedbackQueryVariables = Exact<{
  page: Scalars['PositiveInt'];
  itemsPerPage: Scalars['PositiveInt'];
}>;


export type UserFeedbackQuery = { __typename?: 'Query', feedback?: Array<{ __typename?: 'Feedback', _id: any, sender?: string, message?: string, rating?: any }> };

export type IndustryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type IndustryQuery = { __typename?: 'Query', industry?: { __typename?: 'Industry', _id: any, name: string, active?: boolean } };

export type IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustriesQuery = { __typename?: 'Query', industries?: Array<{ __typename?: 'Industry', _id: any, name: string, active?: boolean }> };

export type CreateIndustryMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateIndustryMutation = { __typename?: 'Mutation', createIndustry?: { __typename?: 'Industry', _id: any, name: string, active?: boolean } };

export type ActivateIndustryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateIndustryMutation = { __typename?: 'Mutation', updateIndustry?: { __typename?: 'Industry', _id: any, name: string, active?: boolean } };

export type UnresolvedIssuesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnresolvedIssuesQuery = { __typename?: 'Query', unresolvedIssues?: Array<{ __typename?: 'Issue', _id: any, sender?: string, message?: string }> };

export type ResolvedIssuesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResolvedIssuesQuery = { __typename?: 'Query', resolvedIssues?: Array<{ __typename?: 'Issue', _id: any, sender?: string, message?: string }> };

export type ResolveIssueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResolveIssueMutation = { __typename?: 'Mutation', resolveIssue: { __typename?: 'Issue', _id: any, resolved?: boolean } };

export type UnresolveIssueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnresolveIssueMutation = { __typename?: 'Mutation', unresolveIssue: { __typename?: 'Issue', _id: any, resolved?: boolean } };

export type JobBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobBasicQuery = { __typename?: 'Query', job?: { __typename?: 'Job', _id: any, positionId: any, status?: JobStatus, location: string, certifications?: Array<any>, skills?: Array<any>, maxAppliedApplicants: any, maxFilteredApplicants: any } };

export type JobWithCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobWithCompanyQuery = { __typename?: 'Query', job?: { __typename?: 'Job', recruiterId: string, skills?: Array<any>, certifications?: Array<any>, _id: any, positionId: any, employmentType: EmploymentType, companyId: any, description: string, location: string, yearsOfExperience?: any, company?: { __typename?: 'Company', _id: any, avatarURL?: any, backgroundURL?: any, name?: string } } };

export type MatchedJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type MatchedJobsQuery = { __typename?: 'Query', matchedJobs?: Array<{ __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } }> };

export type MatchedOrPausedJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type MatchedOrPausedJobsQuery = { __typename?: 'Query', matchedOrPausedJobs?: Array<{ __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } }> };

export type PostedJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type PostedJobsQuery = { __typename?: 'Query', openJobs?: Array<{ __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } }> };

export type ApproveJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveJobMutation = { __typename?: 'Mutation', approveJob?: { __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } } };

export type DisapproveJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisapproveJobMutation = { __typename?: 'Mutation', disapproveJob?: { __typename?: 'Job', _id: any, status?: JobStatus, filterDeadline: Date, company?: { __typename?: 'Company', name?: string }, position?: { __typename?: 'Position', name?: string } } };

export type PauseJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PauseJobMutation = { __typename?: 'Mutation', pauseJob?: { __typename?: 'Job', _id: any, status?: JobStatus } };

export type UnpauseJobMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpauseJobMutation = { __typename?: 'Mutation', unpauseJob?: { __typename?: 'Job', _id: any, status?: JobStatus } };

export type JobTemplateBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobTemplateBasicQuery = { __typename?: 'Query', jobTemplate?: { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any>, position?: { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> } } };

export type JobTemplateAllQueryVariables = Exact<{ [key: string]: never; }>;


export type JobTemplateAllQuery = { __typename?: 'Query', jobTemplates?: Array<{ __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any>, position?: { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> } }> };

export type CreateJobTemplateMutationVariables = Exact<{
  jobTemplateFields: JobTemplateFieldsInput;
}>;


export type CreateJobTemplateMutation = { __typename?: 'Mutation', createJobTemplate?: { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any> } };

export type UpdateJobTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  jobTemplateFields: JobTemplateFieldsInput;
}>;


export type UpdateJobTemplateMutation = { __typename?: 'Mutation', updateJobTemplate?: { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any> } };

export type DeleteJobTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteJobTemplateMutation = { __typename?: 'Mutation', deleteJobTemplate?: { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any> } };

export type PositionBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PositionBasicQuery = { __typename?: 'Query', position?: { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> } };

export type PositionAllQueryVariables = Exact<{ [key: string]: never; }>;


export type PositionAllQuery = { __typename?: 'Query', positions?: Array<{ __typename?: 'Position', _id: any, name?: string, industries?: Array<any>, jobTemplate?: { __typename?: 'JobTemplate', _id: any, positionId: any, description: string, certifications?: Array<any>, skills?: Array<any> } }> };

export type CreatePositionMutationVariables = Exact<{
  name: Scalars['String'];
  industries?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type CreatePositionMutation = { __typename?: 'Mutation', createPosition?: { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> } };

export type DeletePositionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePositionMutation = { __typename?: 'Mutation', deletePosition?: { __typename?: 'Position', _id: any, name?: string, industries?: Array<any> } };

export type OptionGroupsBaseFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type OptionGroupsBaseFieldsQuery = { __typename?: 'Query', optionGroups?: Array<{ __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean }> };

export type OptionGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type OptionGroupsQuery = { __typename?: 'Query', optionGroups?: Array<{ __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean, options: Array<{ __typename?: 'QuestionOption', _id: any, text: string, score?: number }> }> };

export type CreateOptionGroupMutationVariables = Exact<{
  optionGroupFields: OptionGroupInput;
}>;


export type CreateOptionGroupMutation = { __typename?: 'Mutation', createOptionGroup?: { __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean, options: Array<{ __typename?: 'QuestionOption', _id: any, text: string, score?: number }> } };

export type DeleteOptionGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOptionGroupMutation = { __typename?: 'Mutation', deleteOptionGroup?: { __typename?: 'OptionGroup', _id: any, name: string, withScores?: boolean, options: Array<{ __typename?: 'QuestionOption', _id: any, text: string, score?: number }> } };

export type QuestionsWithVariablesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionsWithVariablesQuery = { __typename?: 'Query', questionsWithVariables?: Array<{ __typename?: 'Question', _id: any, title: string, type: QuestionType, userTarget: Role, optionGroupId?: any, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableId: any, variableResult: VariableResult, variableName: string }, twoVariablesDetails?: { __typename?: 'TwoVariablesDetails', positiveVariableId: any, negativeVariableId: any, negativeVariableName: string, positiveVariableName: string } }> };

export type CreateQuestionMutationVariables = Exact<{
  questionFields: QuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion?: { __typename?: 'Question', _id: any, title: string, type: QuestionType, userTarget: Role, optionGroupId?: any, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableId: any, variableResult: VariableResult, variableName: string }, twoVariablesDetails?: { __typename?: 'TwoVariablesDetails', positiveVariableId: any, negativeVariableId: any, negativeVariableName: string, positiveVariableName: string } } };

export type CreateQuestionsMutationVariables = Exact<{
  questionsFields: Array<QuestionInput> | QuestionInput;
}>;


export type CreateQuestionsMutation = { __typename?: 'Mutation', createQuestions?: Array<{ __typename?: 'Question', _id: any, title: string, type: QuestionType, userTarget: Role, optionGroupId?: any, singleVariableDetails?: { __typename?: 'SingleVariableDetails', variableId: any, variableResult: VariableResult, variableName: string }, twoVariablesDetails?: { __typename?: 'TwoVariablesDetails', positiveVariableId: any, negativeVariableId: any, negativeVariableName: string, positiveVariableName: string } }> };

export type QuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnairesQuery = { __typename?: 'Query', questionnaires?: Array<{ __typename?: 'Questionnaire', industryName: string, _id: any, industryId: any, userTarget: Role, twoVarIds?: Array<any>, nonVarQuestionsIds?: Array<any>, twoVariables?: Array<{ __typename?: 'QuestionnaireVariable', name: string }>, singleVarConfigs?: Array<{ __typename?: 'SingleVarConfig', variableId: any, numQuestions?: any, variable: { __typename?: 'QuestionnaireVariable', _id: any, name: string } }> }> };

export type CreateQuestionnaireMutationVariables = Exact<{
  questionnaireFields: QuestionnaireInput;
}>;


export type CreateQuestionnaireMutation = { __typename?: 'Mutation', createQuestionnaire?: { __typename?: 'Questionnaire', industryName: string, _id: any, industryId: any, userTarget: Role, twoVarIds?: Array<any>, nonVarQuestionsIds?: Array<any>, twoVariables?: Array<{ __typename?: 'QuestionnaireVariable', name: string }>, singleVarConfigs?: Array<{ __typename?: 'SingleVarConfig', variableId: any, numQuestions?: any, variable: { __typename?: 'QuestionnaireVariable', _id: any, name: string } }> } };

export type QuestionnaireVariablesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnaireVariablesQuery = { __typename?: 'Query', questionnaireVariables?: Array<{ __typename?: 'QuestionnaireVariable', _id: any, name: string }> };

export type CreateQuestionnaireVariableMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateQuestionnaireVariableMutation = { __typename?: 'Mutation', createQuestionnaireVariable?: { __typename?: 'QuestionnaireVariable', _id: any, name: string } };

export type DeleteQuestionnaireVariableMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQuestionnaireVariableMutation = { __typename?: 'Mutation', deleteQuestionnaireVariable?: { __typename?: 'QuestionnaireVariable', _id: any, name: string } };

export type UnverifiedRecruitersQueryVariables = Exact<{ [key: string]: never; }>;


export type UnverifiedRecruitersQuery = { __typename?: 'Query', unverifiedRecruiters?: Array<{ __typename?: 'Recruiter', _id: string, firstName: string, lastName: string, headline?: string, companyId: any }> };

export type VerifyRecruiterMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VerifyRecruiterMutation = { __typename?: 'Mutation', verifyRecruiter?: { __typename?: 'Recruiter', _id: string, verified?: boolean } };

export type SkillBasicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SkillBasicQuery = { __typename?: 'Query', skill?: { __typename?: 'Skill', _id: any, name: string } };

export type SkillAllQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillAllQuery = { __typename?: 'Query', skills?: Array<{ __typename?: 'Skill', _id: any, name: string }> };

export type CreateSkillMutationVariables = Exact<{
  skillFields: SkillFieldsInput;
}>;


export type CreateSkillMutation = { __typename?: 'Mutation', createSkill?: { __typename?: 'Skill', _id: any, name: string } };

export type CreateSkillsMutationVariables = Exact<{
  skillsFields: Array<SkillFieldsInput> | SkillFieldsInput;
}>;


export type CreateSkillsMutation = { __typename?: 'Mutation', createSkills?: Array<{ __typename?: 'Skill', _id: any, name: string }> };

export type DeleteSkillMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSkillMutation = { __typename?: 'Mutation', deleteSkill?: { __typename?: 'Skill', _id: any, name: string } };

export const ApplicantIntroFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantIntroFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Applicant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}}]}}]} as unknown as DocumentNode<ApplicantIntroFieldsFragment, unknown>;
export const JobPreferencesFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobPreferencesFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"JobPreferences"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"seeking"}},{"kind":"Field","name":{"kind":"Name","value":"jobTypes"}},{"kind":"Field","name":{"kind":"Name","value":"positionsIds"}}]}}]} as unknown as DocumentNode<JobPreferencesFieldsFragment, unknown>;
export const ExperienceFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ExperienceFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Experience"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"position"}},{"kind":"Field","name":{"kind":"Name","value":"employmentType"}},{"kind":"Field","name":{"kind":"Name","value":"company"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"companyName"}},{"kind":"Field","name":{"kind":"Name","value":"companyAvatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}}]}}]} as unknown as DocumentNode<ExperienceFieldsFragment, unknown>;
export const EducationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"EducationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Education"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"institution"}},{"kind":"Field","name":{"kind":"Name","value":"degree"}},{"kind":"Field","name":{"kind":"Name","value":"startDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"field"}},{"kind":"Field","name":{"kind":"Name","value":"grade"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]} as unknown as DocumentNode<EducationFieldsFragment, unknown>;
export const ApplicantCertificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantCertificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ApplicantCertification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"issuingOrganization"}},{"kind":"Field","name":{"kind":"Name","value":"issueDate"}},{"kind":"Field","name":{"kind":"Name","value":"expirationDate"}},{"kind":"Field","name":{"kind":"Name","value":"credentialID"}},{"kind":"Field","name":{"kind":"Name","value":"credentialURL"}}]}}]} as unknown as DocumentNode<ApplicantCertificationFieldsFragment, unknown>;
export const ApplicantFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Applicant"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantIntroFields"}},{"kind":"Field","name":{"kind":"Name","value":"resumeURL"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}},{"kind":"Field","name":{"kind":"Name","value":"jobPreferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPreferencesFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"experience"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ExperienceFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"education"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EducationFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"certifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicantCertificationFields"}}]}},{"kind":"Field","name":{"kind":"Name","value":"skills"}}]}},...ApplicantIntroFieldsFragmentDoc.definitions,...JobPreferencesFieldsFragmentDoc.definitions,...ExperienceFieldsFragmentDoc.definitions,...EducationFieldsFragmentDoc.definitions,...ApplicantCertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicantFieldsFragment, unknown>;
export const CompanyFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CompanyFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Company"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"overview"}},{"kind":"Field","name":{"kind":"Name","value":"headquarters"}},{"kind":"Field","name":{"kind":"Name","value":"locations"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}},{"kind":"Field","name":{"kind":"Name","value":"website"}},{"kind":"Field","name":{"kind":"Name","value":"companySize"}}]}}]} as unknown as DocumentNode<CompanyFieldsFragment, unknown>;
export const RecruiterFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RecruiterFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Recruiter"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"summary"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}}]}}]} as unknown as DocumentNode<RecruiterFieldsFragment, unknown>;
export const NotificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NotificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Notification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"read"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"redirectLink"}}]}}]} as unknown as DocumentNode<NotificationFieldsFragment, unknown>;
export const JobBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"employmentType"}},{"kind":"Field","name":{"kind":"Name","value":"recruiterId"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"yearsOfExperience"}}]}}]} as unknown as DocumentNode<JobBaseFieldsFragment, unknown>;
export const JobFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}},{"kind":"Field","name":{"kind":"Name","value":"certifications"}}]}},...JobBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobFieldsFragment, unknown>;
export const FeedbackFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FeedbackFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Feedback"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}}]}}]} as unknown as DocumentNode<FeedbackFieldsFragment, unknown>;
export const IssueFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IssueFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Issue"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]} as unknown as DocumentNode<IssueFieldsFragment, unknown>;
export const IndustryFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"IndustryFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Industry"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"active"}}]}}]} as unknown as DocumentNode<IndustryFieldsFragment, unknown>;
export const PositionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PositionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Position"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}}]}}]} as unknown as DocumentNode<PositionFieldsFragment, unknown>;
export const CertificationFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CertificationFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Certification"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<CertificationFieldsFragment, unknown>;
export const JobTemplateFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobTemplateFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"JobTemplate"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"certifications"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}}]}}]} as unknown as DocumentNode<JobTemplateFieldsFragment, unknown>;
export const SkillFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SkillFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Skill"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<SkillFieldsFragment, unknown>;
export const ApplicantBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicantBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]} as unknown as DocumentNode<ApplicantBaseFieldsFragment, unknown>;
export const MatchDetailsFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MatchDetailsFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MatchDetails"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionnaireMatchScore"}},{"kind":"Field","name":{"kind":"Name","value":"numSkillsMatched"}}]}}]} as unknown as DocumentNode<MatchDetailsFieldsFragment, unknown>;
export const ApplicationForFilterFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ApplicationForFilter"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"applicant"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"matchDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MatchDetailsFields"}}]}}]}},...MatchDetailsFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ApplicationForFilterFragment, unknown>;
export const JobPostingTableInfoFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"JobPostingTableInfo"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Job"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"filterDeadline"}}]}}]} as unknown as DocumentNode<JobPostingTableInfoFragment, unknown>;
export const OptionGroupBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OptionGroupBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OptionGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"withScores"}}]}}]} as unknown as DocumentNode<OptionGroupBaseFieldsFragment, unknown>;
export const QuestionOptionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionOptionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionOption"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"text"}},{"kind":"Field","name":{"kind":"Name","value":"score"}}]}}]} as unknown as DocumentNode<QuestionOptionFieldsFragment, unknown>;
export const OptionGroupFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OptionGroupFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OptionGroup"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OptionGroupBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionOptionFields"}}]}}]}},...OptionGroupBaseFieldsFragmentDoc.definitions,...QuestionOptionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OptionGroupFieldsFragment, unknown>;
export const VariableFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"VariableFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionnaireVariable"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]} as unknown as DocumentNode<VariableFieldsFragment, unknown>;
export const QuestionBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Question"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"userTarget"}},{"kind":"Field","name":{"kind":"Name","value":"optionGroupId"}}]}}]} as unknown as DocumentNode<QuestionBaseFieldsFragment, unknown>;
export const QuestionFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Question"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"singleVariableDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variableId"}},{"kind":"Field","name":{"kind":"Name","value":"variableResult"}},{"kind":"Field","name":{"kind":"Name","value":"variableName"}}]}},{"kind":"Field","name":{"kind":"Name","value":"twoVariablesDetails"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"positiveVariableId"}},{"kind":"Field","name":{"kind":"Name","value":"negativeVariableId"}},{"kind":"Field","name":{"kind":"Name","value":"negativeVariableName"}},{"kind":"Field","name":{"kind":"Name","value":"positiveVariableName"}}]}}]}},...QuestionBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionFieldsFragment, unknown>;
export const QuestionnaireBaseFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionnaireBaseFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Questionnaire"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"industryId"}},{"kind":"Field","name":{"kind":"Name","value":"userTarget"}},{"kind":"Field","name":{"kind":"Name","value":"singleVarConfigs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variableId"}},{"kind":"Field","name":{"kind":"Name","value":"numQuestions"}},{"kind":"Field","name":{"kind":"Name","value":"variable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"twoVarIds"}},{"kind":"Field","name":{"kind":"Name","value":"nonVarQuestionsIds"}}]}}]} as unknown as DocumentNode<QuestionnaireBaseFieldsFragment, unknown>;
export const QuestionnaireTableFieldsFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"QuestionnaireTableFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Questionnaire"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireBaseFields"}},{"kind":"Field","name":{"kind":"Name","value":"industryName"}},{"kind":"Field","name":{"kind":"Name","value":"twoVariables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}},...QuestionnaireBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnaireTableFieldsFragment, unknown>;
export const AdminsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Admins"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"admins"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<AdminsQuery, AdminsQueryVariables>;
export const SignUpAdminDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignUpAdmin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminSignUpInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signUpAdmin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userInput"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<SignUpAdminMutation, SignUpAdminMutationVariables>;
export const JobInterestedApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobInterestedApplications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jobInterestedApplications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationForFilter"}}]}}]}},...ApplicationForFilterFragmentDoc.definitions]} as unknown as DocumentNode<JobInterestedApplicationsQuery, JobInterestedApplicationsQueryVariables>;
export const JobConsideredMatchApplicationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobConsideredMatchApplication"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jobConsideredMatchApplications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ApplicationForFilter"}}]}}]}},...ApplicationForFilterFragmentDoc.definitions]} as unknown as DocumentNode<JobConsideredMatchApplicationQuery, JobConsideredMatchApplicationQueryVariables>;
export const NumJobChosenApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NumJobChosenApplications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countJobMatchedApplications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobId"}}}]}]}}]} as unknown as DocumentNode<NumJobChosenApplicationsQuery, NumJobChosenApplicationsQueryVariables>;
export const UpdateApplicationToConsideredDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicationToConsidered"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"CONSIDERED_MATCH"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<UpdateApplicationToConsideredMutation, UpdateApplicationToConsideredMutationVariables>;
export const UpdateApplicationToInterestedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateApplicationToInterested"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateApplicationStatus"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"status"},"value":{"kind":"EnumValue","value":"INTERESTED"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<UpdateApplicationToInterestedMutation, UpdateApplicationToInterestedMutationVariables>;
export const FilterApplicationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"FilterApplications"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"ids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"filterApplications"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"ids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"ids"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<FilterApplicationsMutation, FilterApplicationsMutationVariables>;
export const CertificationBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CertificationBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"certification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CertificationBasicQuery, CertificationBasicQueryVariables>;
export const CertificationAllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CertificationAll"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"certifications"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<CertificationAllQuery, CertificationAllQueryVariables>;
export const CreateCertificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateCertification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createCertification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CertificationFields"}}]}}]}},...CertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateCertificationMutation, CreateCertificationMutationVariables>;
export const DeleteCertificationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteCertification"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteCertification"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CertificationFields"}}]}}]}},...CertificationFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteCertificationMutation, DeleteCertificationMutationVariables>;
export const UserFeedbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserFeedback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"page"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PositiveInt"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"itemsPerPage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PositiveInt"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feedback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"Variable","name":{"kind":"Name","value":"page"}}},{"kind":"Argument","name":{"kind":"Name","value":"itemsPerPage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"itemsPerPage"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"rating"}}]}}]}}]} as unknown as DocumentNode<UserFeedbackQuery, UserFeedbackQueryVariables>;
export const IndustryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Industry"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"industry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IndustryFields"}}]}}]}},...IndustryFieldsFragmentDoc.definitions]} as unknown as DocumentNode<IndustryQuery, IndustryQueryVariables>;
export const IndustriesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Industries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"industries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IndustryFields"}}]}}]}},...IndustryFieldsFragmentDoc.definitions]} as unknown as DocumentNode<IndustriesQuery, IndustriesQueryVariables>;
export const CreateIndustryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateIndustry"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createIndustry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IndustryFields"}}]}}]}},...IndustryFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateIndustryMutation, CreateIndustryMutationVariables>;
export const ActivateIndustryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ActivateIndustry"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateIndustry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"active"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"IndustryFields"}}]}}]}},...IndustryFieldsFragmentDoc.definitions]} as unknown as DocumentNode<ActivateIndustryMutation, ActivateIndustryMutationVariables>;
export const UnresolvedIssuesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UnresolvedIssues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unresolvedIssues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<UnresolvedIssuesQuery, UnresolvedIssuesQueryVariables>;
export const ResolvedIssuesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ResolvedIssues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resolvedIssues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"sender"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<ResolvedIssuesQuery, ResolvedIssuesQueryVariables>;
export const ResolveIssueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResolveIssue"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resolveIssue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"resolved"}}]}}]}}]} as unknown as DocumentNode<ResolveIssueMutation, ResolveIssueMutationVariables>;
export const UnresolveIssueDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnresolveIssue"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unresolveIssue"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"resolved"}}]}}]}}]} as unknown as DocumentNode<UnresolveIssueMutation, UnresolveIssueMutationVariables>;
export const JobBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"job"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"positionId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"location"}},{"kind":"Field","name":{"kind":"Name","value":"certifications"}},{"kind":"Field","name":{"kind":"Name","value":"skills"}},{"kind":"Field","name":{"kind":"Name","value":"maxAppliedApplicants"}},{"kind":"Field","name":{"kind":"Name","value":"maxFilteredApplicants"}}]}}]}}]} as unknown as DocumentNode<JobBasicQuery, JobBasicQueryVariables>;
export const JobWithCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobWithCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"job"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobFields"}},{"kind":"Field","name":{"kind":"Name","value":"company"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"avatarURL"}},{"kind":"Field","name":{"kind":"Name","value":"backgroundURL"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"recruiterId"}}]}}]}},...JobFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobWithCompanyQuery, JobWithCompanyQueryVariables>;
export const MatchedJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MatchedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"matchedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPostingTableInfo"}}]}}]}},...JobPostingTableInfoFragmentDoc.definitions]} as unknown as DocumentNode<MatchedJobsQuery, MatchedJobsQueryVariables>;
export const MatchedOrPausedJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MatchedOrPausedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"matchedOrPausedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPostingTableInfo"}}]}}]}},...JobPostingTableInfoFragmentDoc.definitions]} as unknown as DocumentNode<MatchedOrPausedJobsQuery, MatchedOrPausedJobsQueryVariables>;
export const PostedJobsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PostedJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"openJobs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPostingTableInfo"}}]}}]}},...JobPostingTableInfoFragmentDoc.definitions]} as unknown as DocumentNode<PostedJobsQuery, PostedJobsQueryVariables>;
export const ApproveJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ApproveJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approveJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPostingTableInfo"}}]}}]}},...JobPostingTableInfoFragmentDoc.definitions]} as unknown as DocumentNode<ApproveJobMutation, ApproveJobMutationVariables>;
export const DisapproveJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisapproveJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disapproveJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobPostingTableInfo"}}]}}]}},...JobPostingTableInfoFragmentDoc.definitions]} as unknown as DocumentNode<DisapproveJobMutation, DisapproveJobMutationVariables>;
export const PauseJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"PauseJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"pauseJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<PauseJobMutation, PauseJobMutationVariables>;
export const UnpauseJobDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UnpauseJob"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unpauseJob"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]} as unknown as DocumentNode<UnpauseJobMutation, UnpauseJobMutationVariables>;
export const JobTemplateBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobTemplateBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jobTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PositionFields"}}]}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions,...PositionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobTemplateBasicQuery, JobTemplateBasicQueryVariables>;
export const JobTemplateAllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"JobTemplateAll"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"jobTemplates"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}},{"kind":"Field","name":{"kind":"Name","value":"position"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PositionFields"}}]}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions,...PositionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<JobTemplateAllQuery, JobTemplateAllQueryVariables>;
export const CreateJobTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateJobTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobTemplateFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JobTemplateFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createJobTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"jobTemplateFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobTemplateFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateJobTemplateMutation, CreateJobTemplateMutationVariables>;
export const UpdateJobTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateJobTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"jobTemplateFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"JobTemplateFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateJobTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"updateFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"jobTemplateFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions]} as unknown as DocumentNode<UpdateJobTemplateMutation, UpdateJobTemplateMutationVariables>;
export const DeleteJobTemplateDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteJobTemplate"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteJobTemplate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteJobTemplateMutation, DeleteJobTemplateMutationVariables>;
export const PositionBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PositionBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"position"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}}]}}]}}]} as unknown as DocumentNode<PositionBasicQuery, PositionBasicQueryVariables>;
export const PositionAllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PositionAll"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"positions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"industries"}},{"kind":"Field","name":{"kind":"Name","value":"jobTemplate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"JobTemplateFields"}}]}}]}}]}},...JobTemplateFieldsFragmentDoc.definitions]} as unknown as DocumentNode<PositionAllQuery, PositionAllQueryVariables>;
export const CreatePositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatePosition"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"industries"}},"type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createPosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"industries"},"value":{"kind":"Variable","name":{"kind":"Name","value":"industries"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PositionFields"}}]}}]}},...PositionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreatePositionMutation, CreatePositionMutationVariables>;
export const DeletePositionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeletePosition"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletePosition"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PositionFields"}}]}}]}},...PositionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeletePositionMutation, DeletePositionMutationVariables>;
export const OptionGroupsBaseFieldsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OptionGroupsBaseFields"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"optionGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OptionGroupBaseFields"}}]}}]}},...OptionGroupBaseFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OptionGroupsBaseFieldsQuery, OptionGroupsBaseFieldsQueryVariables>;
export const OptionGroupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"OptionGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"optionGroups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OptionGroupFields"}}]}}]}},...OptionGroupFieldsFragmentDoc.definitions]} as unknown as DocumentNode<OptionGroupsQuery, OptionGroupsQueryVariables>;
export const CreateOptionGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateOptionGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"optionGroupFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"OptionGroupInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createOptionGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"optionGroupFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"optionGroupFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OptionGroupFields"}}]}}]}},...OptionGroupFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateOptionGroupMutation, CreateOptionGroupMutationVariables>;
export const DeleteOptionGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteOptionGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteOptionGroup"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"OptionGroupFields"}}]}}]}},...OptionGroupFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteOptionGroupMutation, DeleteOptionGroupMutationVariables>;
export const QuestionsWithVariablesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuestionsWithVariables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionsWithVariables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionFields"}}]}}]}},...QuestionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionsWithVariablesQuery, QuestionsWithVariablesQueryVariables>;
export const CreateQuestionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateQuestion"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"questionFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createQuestion"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"questionFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"questionFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionFields"}}]}}]}},...QuestionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const CreateQuestionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateQuestions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"questionsFields"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createQuestions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"questionsFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"questionsFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionFields"}}]}}]}},...QuestionFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateQuestionsMutation, CreateQuestionsMutationVariables>;
export const QuestionnairesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Questionnaires"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionnaires"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireTableFields"}}]}}]}},...QuestionnaireTableFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnairesQuery, QuestionnairesQueryVariables>;
export const CreateQuestionnaireDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateQuestionnaire"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"questionnaireFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"QuestionnaireInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createQuestionnaire"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"questionnaireFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"questionnaireFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"QuestionnaireTableFields"}}]}}]}},...QuestionnaireTableFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;
export const QuestionnaireVariablesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"QuestionnaireVariables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"questionnaireVariables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariableFields"}}]}}]}},...VariableFieldsFragmentDoc.definitions]} as unknown as DocumentNode<QuestionnaireVariablesQuery, QuestionnaireVariablesQueryVariables>;
export const CreateQuestionnaireVariableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateQuestionnaireVariable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createQuestionnaireVariable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariableFields"}}]}}]}},...VariableFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateQuestionnaireVariableMutation, CreateQuestionnaireVariableMutationVariables>;
export const DeleteQuestionnaireVariableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteQuestionnaireVariable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteQuestionnaireVariable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"VariableFields"}}]}}]}},...VariableFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteQuestionnaireVariableMutation, DeleteQuestionnaireVariableMutationVariables>;
export const UnverifiedRecruitersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UnverifiedRecruiters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"unverifiedRecruiters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"headline"}},{"kind":"Field","name":{"kind":"Name","value":"companyId"}}]}}]}}]} as unknown as DocumentNode<UnverifiedRecruitersQuery, UnverifiedRecruitersQueryVariables>;
export const VerifyRecruiterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"VerifyRecruiter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"verifyRecruiter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"verified"}}]}}]}}]} as unknown as DocumentNode<VerifyRecruiterMutation, VerifyRecruiterMutationVariables>;
export const SkillBasicDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SkillBasic"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"skill"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<SkillBasicQuery, SkillBasicQueryVariables>;
export const SkillAllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SkillAll"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"skills"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<SkillAllQuery, SkillAllQueryVariables>;
export const CreateSkillDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSkill"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"skillFields"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SkillFieldsInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSkill"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"skillFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"skillFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SkillFields"}}]}}]}},...SkillFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateSkillMutation, CreateSkillMutationVariables>;
export const CreateSkillsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateSkills"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"skillsFields"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SkillFieldsInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createSkills"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"skillsFields"},"value":{"kind":"Variable","name":{"kind":"Name","value":"skillsFields"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SkillFields"}}]}}]}},...SkillFieldsFragmentDoc.definitions]} as unknown as DocumentNode<CreateSkillsMutation, CreateSkillsMutationVariables>;
export const DeleteSkillDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteSkill"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteSkill"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SkillFields"}}]}}]}},...SkillFieldsFragmentDoc.definitions]} as unknown as DocumentNode<DeleteSkillMutation, DeleteSkillMutationVariables>;