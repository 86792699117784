import { Grid } from "@mui/material";
import {
  FormikDialog,
  FormikTextField,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import React from "react";
import { useCreateQuestionnaireVariableMutation } from "../../../generated/graphqlHooks";
import { ErrorCode, QuestionnaireVariablesDocument } from "../../../generated/graphqlTypes";

type CreateVariableProps = {
  open: boolean;
  handleClose: () => void;
};

const CreateVariableModal: React.FC<CreateVariableProps> = ({ open, handleClose }) => {
  const [createVariable] = useCreateQuestionnaireVariableMutation();

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Create new Variable"
      formikConfig={{
        initialValues: {
          name: ""
        },
        onSaveSubmit: async (values) => {
          await createVariable({
            variables: values,
            update: (cache, mutationResult) => {
              updateCacheAfterAdd(
                cache,
                QuestionnaireVariablesDocument,
                mutationResult.data?.createQuestionnaireVariable
              );
            }
          });
        },
        errorsToFieldName: {
          [ErrorCode.CONFLICT]: {
            fieldName: "name",
            customErrorMessage: "Variable with this name already exists!"
          }
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikTextField required name="name" autoFocus fullWidth />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default CreateVariableModal;
