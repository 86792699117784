import GenericTable from "../../components/table/GenericTable";
import { useAdminsQuery } from "../../generated/graphqlHooks";
import useModalState from "../../hooks/useModalState";
import CreateAdminModal from "./CreateAdminModal";

const ManageAdmins = () => {
  const { data: adminsDate, loading: loadingAdmins } = useAdminsQuery();
  const [createAdminOpen, openCreateAdmin, closeCreateAdmin] = useModalState();

  return (
    <>
      <GenericTable
        title="Admins"
        description="Admins on the platform"
        columns={["fullName", "email"]}
        loading={loadingAdmins}
        rows={adminsDate?.admins}
        toolbarProps={{
          primaryButton: {
            text: "Create new Admin",
            onClick: openCreateAdmin
          }
        }}
      />
      <CreateAdminModal open={createAdminOpen} handleClose={closeCreateAdmin} />
    </>
  );
};

export default ManageAdmins;
