import { isApolloError } from "@apollo/client";
import { GetApp, SvgIconComponent } from "@mui/icons-material";
import {
  Button,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import {
  alertError,
  isApolloErrorWithOneOfCodes,
  isApolloNetworkErrorWithOneOfStatusCodes,
  makeStyles
} from "@placehires/react-component-library";
import React, { useMemo } from "react";
import { ErrorCode } from "../../generated/graphqlTypes";
import useHiddenFileSelect from "../../hooks/useHiddenFileSelect";
import { parseCsvIntoInsertItems, reportFailedInsertItems } from "../../utils/csvUtils";
import { sleep } from "../../utils/utils";

export const TOOLBAR_HEIGHT = 48;

export type PreMadeToolbarButton = "columns" | "filters" | "density" | "export";

export type ToolbarProps = {
  primaryButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  textFieldButtons?: Array<TextFieldProps>;
  tooltipButtons?: Array<{
    Icon: SvgIconComponent;
    text: string;
    onClick: () => void;
  }>;
  /** Defaults to "all" when toolbar is present (i.e. primary button/tooltip buttons are present) */
  preMadeButtons?: "all" | PreMadeToolbarButton[];
  importButton?: {
    text: string;
    failedReportFilename: string;
    /** No additional error handling needed - Apollo errors and FileReader parse error are caught and handled */
    onFileParsed: (parsedItems: unknown[]) => Promise<void>;
  };
};

const preMadeToolbarButtons = {
  columns: GridToolbarColumnsButton,
  filters: GridToolbarFilterButton,
  density: GridToolbarDensitySelector,
  export: GridToolbarExport
};

const getPreMadeComponent = (name: PreMadeToolbarButton) => {
  const PreMadeButtonComponent = preMadeToolbarButtons[name];
  return <PreMadeButtonComponent key={name} />;
};

const Toolbar: React.FC<ToolbarProps & { setCustomLoading: (loading: boolean) => void }> = ({
  primaryButton,
  tooltipButtons = [],
  textFieldButtons = [],
  importButton,
  setCustomLoading,
  preMadeButtons = primaryButton || tooltipButtons ? ["columns", "filters", "export"] : undefined
}) => {
  const { classes } = useStyles();
  const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  if (primaryButton && xsScreen) preMadeButtons = undefined;

  const [inputProps, openFileSelect] = useHiddenFileSelect(async (files) => {
    const { onFileParsed, failedReportFilename } = importButton!;
    if (files && files[0]) {
      const csvFile = files[0];
      setCustomLoading(true);
      await sleep(100);
      try {
        const parsedItems = await parseCsvIntoInsertItems(csvFile);
        await onFileParsed(parsedItems);
      } catch (err) {
        if (isApolloErrorWithOneOfCodes(err, ErrorCode.BATCH_INSERT_ERROR)) {
          reportFailedInsertItems(err, failedReportFilename);
        } else if (isApolloNetworkErrorWithOneOfStatusCodes(err, 400, 413)) {
          const { statusCode } = err.networkError;
          if (statusCode === 400) {
            alertError("CSV does not have the correct format");
          } else {
            alertError("CSV file is too large");
          }
        } else if (isApolloError(err as Error)) {
          alertError();
        } else {
          alertError("Failed to parse the CSV file");
        }
      }
      setCustomLoading(false);
    }
  });

  tooltipButtons = useMemo(() => {
    const buttons = [...tooltipButtons];
    if (importButton)
      buttons.push({
        Icon: GetApp,
        text: importButton.text,
        onClick: () => openFileSelect()
      });
    return buttons;
  }, [tooltipButtons, importButton, openFileSelect]);

  return (
    <div className={classes.container}>
      <input {...inputProps} accept=".csv" />
      {preMadeButtons && (
        <div className={classes.existing}>
          {preMadeButtons === "all"
            ? (Object.keys(preMadeToolbarButtons) as PreMadeToolbarButton[]).map(
                getPreMadeComponent
              )
            : preMadeButtons.map(getPreMadeComponent)}
        </div>
      )}
      {tooltipButtons.map(({ text, onClick, Icon }, index) => (
        <Tooltip key={index} title={text} onClick={onClick} arrow>
          <IconButton className={classes.iconButton} color="secondary" size="large">
            <Icon fontSize="small" />
          </IconButton>
        </Tooltip>
      ))}
      {textFieldButtons.map((textFieldProps, index) => {
        return (
          <TextField
            key={index}
            variant="outlined"
            classes={{
              root: classes.textFieldRoot
            }}
            SelectProps={{
              native: true,
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                }
              }
            }}
            {...textFieldProps}
          />
        );
      })}
      {primaryButton && (
        <Button
          color="primary"
          variant="contained"
          className={classes.primaryButton}
          onClick={primaryButton.onClick}
          disabled={primaryButton.disabled}
        >
          {primaryButton.text}
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    height: TOOLBAR_HEIGHT,
    position: "absolute",
    top: -TOOLBAR_HEIGHT,
    justifyContent: "flex-end",
    width: "100%"
  },
  primaryButton: {
    boxShadow: "none",
    textTransform: "capitalize"
  },
  iconButton: {
    borderRadius: 4,
    padding: 0,
    width: TOOLBAR_HEIGHT
  },
  existing: {
    flex: 1,
    display: "flex"
  },
  textFieldRoot: {
    "& .MuiInputBase-root": {
      height: "100%"
    },
    "& select": {
      height: "100%",
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}));

export default Toolbar;
