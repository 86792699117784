import Menu from "@mui/icons-material/Menu";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@placehires/react-component-library";
import { FC, useMemo } from "react";
import { matchPath, PathMatch, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { Routes } from "../utils/globalTypes";

type TopBarProps = {
  openDrawer: () => void;
};

const TopBar: FC<TopBarProps> = ({ openDrawer }) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const pageName = useMemo(() => {
    let match: PathMatch | null = null;
    const rootRoute = Object.values(ROUTES as Routes).find(({ path }) => {
      match = matchPath(`${path}/*`, pathname);
      return match;
    });
    if (!rootRoute) return "";
    const exactRoute = matchPath(rootRoute.path, pathname)
      ? rootRoute
      : Object.values(rootRoute.subroutes || {}).find(({ path, notExact }) => {
          match = matchPath(path, pathname);
          return match;
        });
    if (!exactRoute) return "";
    return exactRoute.getName && match
      ? exactRoute.getName((match as PathMatch).params || {})
      : exactRoute.name;
  }, [pathname]);

  return (
    <AppBar className={classes.appBar} color="transparent">
      <Toolbar className={classes.toolbar}>
        <div className={classes.pageName}>
          <Typography variant="h5" align="center">
            {pageName}
          </Typography>
        </div>
        <Hidden mdUp>
          <IconButton color="inherit" aria-label="open drawer" onClick={openDrawer} size="large">
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
    paddingRight: "0 !important"
  },
  toolbar: {
    minHeight: 0
  },
  pageName: {
    flex: 1,
    padding: theme.spacing(2.5, 0)
  }
}));

export default TopBar;
