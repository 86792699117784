import { Container } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { FC, ReactNode, useState } from "react";
import logoCircle from "../images/logoCircle.png";
import { SIDEBAR_WIDTH } from "../utils/styles";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

type LayoutProps = {
  children: NonNullable<ReactNode>;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  const { classes } = useStyles();
  const [toggleDrawerOpen, setToggleDrawerOpen] = useState(false);

  return (
    <>
      <Sidebar
        logo={logoCircle}
        logoText="PlaceHires Admin"
        toggleDrawerOpen={toggleDrawerOpen}
        closeDrawer={() => setToggleDrawerOpen(false)}
      />
      <div className={classes.mainPanel}>
        <TopBar openDrawer={() => setToggleDrawerOpen(true)} />
        <Container className={classes.body}>{children}</Container>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${SIDEBAR_WIDTH}px)`
    },
    position: "relative",
    float: "right",
    maxHeight: "100%",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  body: {
    flex: 1
  }
}));

export default Layout;
