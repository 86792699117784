import { PropTypes } from "@mui/material";

export const hexToRgbValues = (input: string) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ", " + parseInt(second, 16) + ", " + parseInt(last, 16);
};

export const SIDEBAR_WIDTH = 260;
export const BLACK_COLOR = "#000";
export const WHITE_COLOR = "#fff";

export const BOX_SHADOW = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgbValues(BLACK_COLOR) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgbValues(BLACK_COLOR) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgbValues(BLACK_COLOR) +
    ", 0.2)"
};

export const isPropTypesColor = (color: string | undefined): color is PropTypes.Color => {
  return ["inherit", "primary", "secondary", "default"].includes(color as string);
};
