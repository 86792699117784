import { Grid } from "@mui/material";
import {
  alertAction,
  FormikAutocomplete,
  FormikDialog,
  FormikTextField,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import React, { useEffect } from "react";
import { useCreatePositionMutation, useIndustriesQuery } from "../../generated/graphqlHooks";
import { ErrorCode, Industry, PositionAllDocument } from "../../generated/graphqlTypes";

type CreatePositionProps = {
  open: boolean;
  handleClose: () => void;
};

const CreatePositionModal: React.FC<CreatePositionProps> = ({ open, handleClose }) => {
  const [createPosition] = useCreatePositionMutation();
  const { data: industryData, loading: industryLoading } = useIndustriesQuery();

  const industryOptions = industryData?.industries;

  useEffect(() => {
    if (open && !industryLoading && !industryOptions) {
      handleClose();
      alertAction("Create an industry first");
    }
  }, [open, industryLoading, industryOptions]); // eslint-disable-line

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Create new Position"
      formikConfig={{
        initialValues: {
          name: "",
          industries: [] as Industry[]
        },
        onSaveSubmit: async (values) => {
          const { name, industries } = values;
          await createPosition({
            variables: {
              name,
              industries: industries?.map((industry) => industry._id)
            },
            update: (cache, mutationResult) => {
              updateCacheAfterAdd(cache, PositionAllDocument, mutationResult.data?.createPosition, {
                unshift: true
              });
            }
          });
        },
        errorsToFieldName: {
          [ErrorCode.CONFLICT]: {
            fieldName: "name",
            customErrorMessage: "Position with this name already exists!"
          }
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikTextField required name="name" label="Position name" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormikAutocomplete
            required
            multiple
            loading={industryLoading}
            name="industries"
            label="Industries"
            options={industryOptions || []}
            getOptionLabel={(option) => option.name || ""}
          />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default CreatePositionModal;
