import { findSelectedOptions } from "@placehires/react-component-library";
import { useMemo } from "react";
import { useCertificationAllQuery } from "../../generated/graphqlHooks";

export const useCertificationsObjects = (certificationsIds: string[] | undefined) => {
  const queryResult = useCertificationAllQuery();
  const skillsObjs = useMemo(
    () => findSelectedOptions(certificationsIds, queryResult.data?.certifications!, "_id"), // TODO make findSelectedOptions allow undefined for options
    [certificationsIds, queryResult.data?.certifications]
  );

  return { ...queryResult, data: skillsObjs };
};
