import { AddCircleOutlineRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { FieldArray, useField } from "formik";
import React from "react";

export type FieldArrayWithAddProps<T extends Record<string, unknown>> = {
  name: string;
  renderItems: (items: T[]) => React.ReactNode;
  viewOnly?: boolean;
};

/** A formik FieldArray with add new item button */
const FieldArrayWithAdd = <T extends Record<string, unknown>>({
  name,
  renderItems,
  viewOnly
}: FieldArrayWithAddProps<T>) => {
  const { classes } = useStyles();
  const [{ value: items }] = useField(name);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div className={classes.root}>
          <div className={classes.itemsWrapper}>{renderItems(items)}</div>
          {!viewOnly && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.addButton}
              startIcon={<AddCircleOutlineRounded />}
              onClick={() => arrayHelpers.push({})}
            >
              Add item
            </Button>
          )}
        </div>
      )}
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  itemsWrapper: {
    width: "100%"
  },
  addButton: {
    marginTop: theme.spacing(2)
  }
}));

export default FieldArrayWithAdd;
