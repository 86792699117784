import { AccountBox, Send } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  openNewTab,
  updateCacheAfterAdd,
  updateCacheAfterRemove,
  useConfirm
} from "@placehires/react-component-library";
import { useParams } from "react-router-dom";
import GenericTable, { Columns } from "../components/table/GenericTable";
import {
  useFilterApplicationsMutation,
  useJobBasicQuery,
  useJobConsideredMatchApplicationQuery,
  useJobInterestedApplicationsQuery,
  useNumJobChosenApplicationsQuery,
  usePauseJobMutation,
  useUnpauseJobMutation,
  useUpdateApplicationToConsideredMutation,
  useUpdateApplicationToInterestedMutation
} from "../generated/graphqlHooks";
import {
  ApplicationForFilterFragment,
  JobConsideredMatchApplicationDocument,
  JobInterestedApplicationsDocument,
  JobStatus,
  NumJobChosenApplicationsDocument
} from "../generated/graphqlTypes";
import { useCertificationsObjects } from "../hooks/data/certificatsHooks";
import { useSkillsObjects } from "../hooks/data/skillsHooks";

const { REACT_APP_MAIN_CLIENT_URL } = process.env;

type PageParams = {
  jobId: string;
};

const JobApplicantsChoosing = () => {
  const { jobId } = useParams() as PageParams;
  const queryVars = {
    variables: {
      jobId
    }
  };
  const { data: jobInterestedApplicationsData, loading: loadingJobInterestedApplications } =
    useJobInterestedApplicationsQuery(queryVars);
  const {
    data: jobConsideredMatchApplicationsData,
    loading: loadingJobConsideredMatchApplications
  } = useJobConsideredMatchApplicationQuery(queryVars);
  const { data: jobData } = useJobBasicQuery({
    variables: {
      id: jobId
    }
  });
  const { data: numJobChosenApplicationsData } = useNumJobChosenApplicationsQuery(queryVars);
  const [updateApplicationToConsidered] = useUpdateApplicationToConsideredMutation();
  const [updateApplicationToInterested] = useUpdateApplicationToInterestedMutation();
  const [pauseJob] = usePauseJobMutation();
  const [unpauseJob] = useUnpauseJobMutation();
  const [filterApplications] = useFilterApplicationsMutation();
  const confirm = useConfirm();
  const numJobSkills = jobData?.job?.skills?.length;
  const numApplicationsFiltered = numJobChosenApplicationsData?.countJobMatchedApplications;
  const { data: skillsObjs } = useSkillsObjects(jobData?.job?.skills);
  const { data: certificationsObjs } = useCertificationsObjects(jobData?.job?.certifications);
  const jobPaused = jobData?.job?.status === JobStatus.PAUSED;

  const columns = [
    {
      field: "applicantName",
      valueGetter: ({ row }) => `${row.applicant?.firstName} ${row.applicant?.lastName}`
    },
    {
      field: "matchScore",
      valueGetter: ({ row }) => row.matchDetails?.questionnaireMatchScore
    },
    {
      field: "skillsMatched",
      valueGetter: ({ row }) => row.matchDetails?.numSkillsMatched,
      valueFormatter: ({ value }) => `${value}/${numJobSkills}`
    }
  ] as Columns<ApplicationForFilterFragment>;

  return (
    <>
      {jobPaused && (
        <Typography variant="h6">
          <strong>Status:</strong> {JobStatus.PAUSED}
        </Typography>
      )}
      <Typography variant="h6">
        <strong>Skills:</strong> {skillsObjs.map((skill) => skill.name).join(", ")}
      </Typography>
      <Typography variant="h6">
        <strong>Certifications:</strong> {certificationsObjs.map((cert) => cert.name).join(", ")}
      </Typography>
      <Typography variant="h6">
        <strong>Max Applied Applicants:</strong> {jobData?.job?.maxAppliedApplicants}
      </Typography>
      <Typography variant="h6">
        <strong>Max Filtered Applicants:</strong> {jobData?.job?.maxFilteredApplicants}
      </Typography>
      <Typography variant="h6">
        <strong>Applicants already sent:</strong> {numApplicationsFiltered}
      </Typography>
      <GenericTable
        title="Interested Applicants"
        description="Applicants who showed interest in the job"
        columns={columns}
        rows={jobInterestedApplicationsData?.jobInterestedApplications}
        toolbarProps={{
          primaryButton: {
            text: jobPaused ? "Unpause Job" : "Pause Job",
            onClick: () =>
              confirm(
                () => {
                  const operation = jobPaused ? unpauseJob : pauseJob;
                  return operation({
                    variables: {
                      id: jobId
                    }
                  });
                },
                {
                  title: jobPaused ? "Confirm to unpause" : "Confirm to pause",
                  text: jobPaused
                    ? "The job will continue the matching process"
                    : "The job will pause matching process until it gets unpaused. This should only be used to allow more time for applicants to apply"
                }
              )
          }
        }}
        actions={[
          {
            name: "View Applicant Profile",
            icon: AccountBox,
            onClick: (application) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/${application.applicant!._id}`);
            }
          },
          {
            name: "Move to considered list",
            icon: Send,
            onClick: (application) =>
              updateApplicationToConsidered({
                variables: {
                  id: application._id
                },
                update: (cache) => {
                  const opts = {
                    queryVariables: {
                      jobId
                    }
                  };
                  updateCacheAfterRemove(
                    cache,
                    JobInterestedApplicationsDocument,
                    application._id,
                    opts
                  );
                  updateCacheAfterAdd(
                    cache,
                    JobConsideredMatchApplicationDocument,
                    application,
                    opts
                  );
                }
              })
          }
        ]}
        loading={loadingJobInterestedApplications}
      />

      <GenericTable
        title="Considered Applicants"
        description="Applicants we are considering to send to recruiter (after their confirmation)"
        columns={columns}
        rows={jobConsideredMatchApplicationsData?.jobConsideredMatchApplications}
        toolbarProps={{
          primaryButton: {
            text: "Send considered applicants",
            disabled:
              !jobConsideredMatchApplicationsData?.jobConsideredMatchApplications?.length ||
              numApplicationsFiltered === undefined,
            onClick: () =>
              confirm(() => {
                const ids = jobConsideredMatchApplicationsData!.jobConsideredMatchApplications!.map(
                  (app) => app._id
                );
                filterApplications({
                  variables: {
                    ids
                  },
                  update: (cache) => {
                    cache.writeQuery({
                      query: JobConsideredMatchApplicationDocument,
                      ...queryVars,
                      data: {
                        jobConsideredMatchApplications: []
                      }
                    });
                    cache.writeQuery({
                      query: NumJobChosenApplicationsDocument,
                      ...queryVars,
                      data: {
                        countJobMatchedApplications: numApplicationsFiltered! + ids.length
                      }
                    });
                  }
                });
              })
          }
        }}
        actions={[
          {
            name: "View Applicant Profile",
            icon: AccountBox,
            onClick: (applicant) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/${applicant._id}`);
            }
          },
          {
            name: "Move back to interested list",
            icon: Send,
            onClick: (application) =>
              updateApplicationToInterested({
                variables: {
                  id: application._id
                },
                update: (cache) => {
                  const opts = {
                    queryVariables: {
                      jobId
                    }
                  };
                  updateCacheAfterRemove(
                    cache,
                    JobConsideredMatchApplicationDocument,
                    application._id,
                    opts
                  );
                  updateCacheAfterAdd(cache, JobInterestedApplicationsDocument, application, opts);
                }
              })
          }
        ]}
        loading={loadingJobConsideredMatchApplications}
      />
    </>
  );
};

export default JobApplicantsChoosing;
