import { Delete } from "@mui/icons-material";
import { updateCacheAfterRemove } from "@placehires/react-component-library";
import { FC } from "react";
import GenericTable from "../../../components/table/GenericTable";
import {
  useDeleteQuestionnaireVariableMutation,
  useQuestionnaireVariablesQuery
} from "../../../generated/graphqlHooks";
import { QuestionnaireVariablesDocument } from "../../../generated/graphqlTypes";
import { useConfirmDelete } from "../../../hooks/confirmHooks";
import useModalState from "../../../hooks/useModalState";
import CreateVariableModal from "./CreateVariableModal";

const ManageVariables: FC = () => {
  const { data, loading } = useQuestionnaireVariablesQuery();
  const [deleteVariable] = useDeleteQuestionnaireVariableMutation();
  const [open, handleOpen, handleClose] = useModalState();
  const confirmDelete = useConfirmDelete();

  return (
    <>
      <GenericTable
        title="Questionnaire variables"
        description="Variables that will be measured in questionnaires"
        columns={["name"]}
        rows={data?.questionnaireVariables}
        loading={loading}
        toolbarProps={{
          primaryButton: {
            text: "Create new variable",
            onClick: handleOpen
          }
        }}
        actions={[
          {
            name: "Delete variable",
            icon: Delete,
            onClick: (variable) => {
              confirmDelete(() =>
                deleteVariable({
                  variables: {
                    id: variable._id
                  },
                  update: (cache) => {
                    updateCacheAfterRemove(cache, QuestionnaireVariablesDocument, variable._id);
                  }
                }).catch((_) => {})
              );
            }
          }
        ]}
      />
      <CreateVariableModal open={open} handleClose={handleClose} />
    </>
  );
};

export default ManageVariables;
