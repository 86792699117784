import { Grid, Typography } from "@mui/material";
import {
  FormikDialog,
  FormikTextField,
  getInitialValues,
  makeStyles,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import React from "react";
import FieldArrayWithAdd from "../../../components/input/FieldArrayWithAdd";
import { useCreateOptionGroupMutation } from "../../../generated/graphqlHooks";
import {
  ErrorCode,
  OptionGroup,
  OptionGroupsDocument,
  QuestionOption
} from "../../../generated/graphqlTypes";

type CreateOptionGroupProps = {
  open: boolean;
  handleClose: () => void;
  defaultValues?: OptionGroup;
};

const CreateOrViewOptionGroupModal: React.FC<CreateOptionGroupProps> = ({
  open,
  handleClose,
  defaultValues
}) => {
  const [createOptionGroup] = useCreateOptionGroupMutation();
  const { classes } = useStyles();
  const viewOnly = !!defaultValues;

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title={viewOnly ? "View option group" : "Create new option group"}
      noFooter={viewOnly}
      formikConfig={{
        initialValues: getInitialValues(
          {
            name: "",
            options: [] as QuestionOption[]
          },
          defaultValues
        ),
        onSaveSubmit: async (values) => {
          await createOptionGroup({
            variables: {
              optionGroupFields: values
            },
            update: (cache, mutationResult) => {
              updateCacheAfterAdd(
                cache,
                OptionGroupsDocument,
                mutationResult.data?.createOptionGroup
              );
            }
          });
        },
        errorsToFieldName: {
          [ErrorCode.CONFLICT]: {
            fieldName: "name",
            customErrorMessage: "Option Group with this name already exists!"
          }
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikTextField required name="name" autoFocus fullWidth disabled={viewOnly} />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom className={classes.optionsLabel}>
            Options
          </Typography>
          <FieldArrayWithAdd
            name="options"
            viewOnly={viewOnly}
            renderItems={(items: QuestionOption[]) => (
              <Grid container spacing={2}>
                {items.map((item, index) => (
                  <Grid key={index} item container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <FormikTextField
                        name={`options.${index}.text`}
                        label={`Option ${index + 1} Text`}
                        required
                        fullWidth
                        disabled={viewOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormikTextField
                        name={`options.${index}.score`}
                        label={`Option ${index + 1} Score`}
                        type="number"
                        disabled={viewOnly}
                        inputProps={{
                          min: 0,
                          max: 1,
                          step: 0.01
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  optionsLabel: {
    marginBottom: theme.spacing(2)
  }
}));

export default CreateOrViewOptionGroupModal;
