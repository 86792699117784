import { Grid } from "@mui/material";
import {
  findSelectedOptions,
  FormikAutocomplete,
  FormikDialog,
  FormikRichTextField
} from "@placehires/react-component-library";
import React from "react";
import {
  useCertificationAllQuery,
  useCreateJobTemplateMutation,
  useDeleteJobTemplateMutation,
  useSkillAllQuery,
  useUpdateJobTemplateMutation
} from "../../generated/graphqlHooks";
import { Position } from "../../generated/graphqlTypes";

type UpdateJobTemplateModalProps = {
  position: Position | null;
  open: boolean;
  handleClose: () => void;
};

const CreateAndUpdateJobTemplateModal: React.FC<UpdateJobTemplateModalProps> = ({
  position,
  open,
  handleClose
}) => {
  const { data: skillData, loading: skillLoading } = useSkillAllQuery();
  const { data: certificationData, loading: certificationLoading } = useCertificationAllQuery();
  const skillOptions = skillData?.skills;
  const certificationOptions = certificationData?.certifications;

  const [createJobTemplate] = useCreateJobTemplateMutation();
  const [updateJobTemplate] = useUpdateJobTemplateMutation();
  const [deleteJobTemplate] = useDeleteJobTemplateMutation();

  const defaultValues = position?.jobTemplate;
  const isCreate = !defaultValues;

  if (!skillOptions || !certificationOptions) return null;

  return (
    <FormikDialog
      maxWidth="md"
      open={open}
      onClose={handleClose}
      title={`${isCreate ? "Create" : "Update"} a Job Template for ${position?.name}`}
      formikConfig={{
        enableReinitialize: true,
        initialValues: {
          description: defaultValues?.description || "",
          skills: findSelectedOptions(defaultValues?.skills, skillOptions, "_id"),
          certifications: findSelectedOptions(
            defaultValues?.certifications,
            certificationOptions,
            "_id"
          )
        },
        onSaveSubmit: async (values) => {
          const { description, skills = [], certifications = [] } = values;
          const jobTemplateFields = {
            positionId: position?._id,
            description,
            skills: skills.map((skill) => skill._id),
            certifications: certifications.map((certification) => certification._id)
          };
          if (isCreate) {
            await createJobTemplate({
              variables: {
                jobTemplateFields
              },
              update: (cache, mutationResult) => {
                if (mutationResult.data?.createJobTemplate && position) {
                  cache.modify({
                    id: cache.identify(position),
                    fields: {
                      jobTemplate: () => {
                        return mutationResult.data?.createJobTemplate;
                      }
                    }
                  });
                }
              }
            });
          } else {
            await updateJobTemplate({
              variables: {
                id: defaultValues?._id,
                jobTemplateFields
              },
              update: (cache, mutationResult) => {
                if (mutationResult.data?.updateJobTemplate && position) {
                  cache.modify({
                    id: cache.identify(position),
                    fields: {
                      jobTemplate: (oldTemplate) => {
                        return { ...oldTemplate, ...mutationResult.data?.updateJobTemplate };
                      }
                    }
                  });
                }
              }
            });
          }
        },
        onDeleteSubmit: isCreate
          ? undefined
          : async () => {
              await deleteJobTemplate({
                variables: {
                  id: position!.jobTemplate!._id
                },
                update: (cache, mutationResult) => {
                  if (mutationResult.data?.deleteJobTemplate && position) {
                    cache.modify({
                      id: cache.identify(position),
                      fields: {
                        jobTemplate: () => null
                      }
                    });
                  }
                }
              });
            },
        confirmDelete: true
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikRichTextField
            required
            name="description"
            label="Job Description"
            maxHeight={600}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikAutocomplete
            multiple
            fullWidth
            label="Certifications"
            name="certifications"
            loading={skillLoading}
            options={certificationOptions || []}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikAutocomplete
            multiple
            fullWidth
            label="Skills"
            name="skills"
            loading={certificationLoading}
            options={skillOptions || []}
            isOptionEqualToValue={(opt, val) => opt._id === val._id}
            getOptionLabel={(option) => {
              return option.name || "";
            }}
          />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default CreateAndUpdateJobTemplateModal;
