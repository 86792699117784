import { selectOptionsToOptionNodes } from "@placehires/react-component-library";
import { capitalize, lowerCase } from "lodash";
import { FC, useState } from "react";
import GenericTable from "../../../components/table/GenericTable";
import { useQuestionsWithVariablesQuery } from "../../../generated/graphqlHooks";
import { QuestionType, Role } from "../../../generated/graphqlTypes";
import useModalState from "../../../hooks/useModalState";
import CreateQuestionsModal from "./CreateQuestionsModal";

const singleVarColumns = [
  {
    field: "variable",
    headerName: "Variable",
    flex: 1,
    valueGetter: ({ row: question }: any) => {
      return question.singleVariableDetails?.variableName;
    }
  },
  {
    field: "result",
    headerName: "Result",
    flex: 1,
    valueGetter: ({ row: question }: any) => {
      return capitalize(lowerCase(question.singleVariableDetails?.variableResult));
    }
  }
];

const twoVarColumns = [
  {
    field: "positiveVariable",
    headerName: "Positive variable",
    flex: 1,
    valueGetter: ({ row: question }: any) => {
      return question.twoVariablesDetails?.positiveVariableName;
    }
  },
  {
    field: "negativeVariable",
    headerName: "Negative variable",
    flex: 1,
    valueGetter: ({ row: question }: any) => {
      return question.twoVariablesDetails?.negativeVariableName;
    }
  }
];

const QuestionBank: FC = () => {
  const { data, loading } = useQuestionsWithVariablesQuery();
  const [open, handleOpen, handleClose] = useModalState();
  const [questionType, setQuestionType] = useState<string>(QuestionType.SINGLE_VARIABLE);
  const [userTarget, setUserTarget] = useState<string>(Role.APPLICANT);

  return (
    <>
      <GenericTable
        title="Question bank"
        description="Question bank of questions with variables"
        columns={[
          "title",
          ...(questionType === QuestionType.SINGLE_VARIABLE ? singleVarColumns : twoVarColumns)
        ]}
        rows={(data?.questionsWithVariables || []).filter((question) => {
          return question.type === questionType && userTarget === question.userTarget;
        })}
        loading={loading}
        toolbarProps={{
          primaryButton: {
            text: "Create new questions",
            onClick: handleOpen
          },
          textFieldButtons: [
            {
              select: true,
              value: userTarget,
              label: "User Target",
              onChange: (e) => setUserTarget(e.target.value),
              children: selectOptionsToOptionNodes([
                {
                  value: Role.APPLICANT,
                  label: "Applicant"
                },
                {
                  value: Role.RECRUITER,
                  label: "Recruiter"
                }
              ])
            },
            {
              select: true,
              value: questionType,
              label: "Question Type",
              onChange: (e) => setQuestionType(e.target.value),
              children: selectOptionsToOptionNodes([
                {
                  value: QuestionType.SINGLE_VARIABLE,
                  label: "Single variable"
                },
                {
                  value: QuestionType.TWO_VARIABLES,
                  label: "Two variable"
                }
              ])
            }
          ]
        }}
      />
      <CreateQuestionsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default QuestionBank;
