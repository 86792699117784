import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Avatar, Container, Grid, TextField, Typography } from "@mui/material";
import { ExtendedButton, makeStyles } from "@placehires/react-component-library";
import { FormEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNoAuth } from "../hooks/authHooks";
import { login } from "../services/authService";

const Login = () => {
  const { state } = useLocation();
  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorEmailText, setErrorEmailText] = useState("");
  const [errorPasswordText, setErrorPasswordText] = useState("");
  const [loading, setLoading] = useState(false);
  useNoAuth((state as any)?.redirectPath);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    try {
      await login(email, password);
    } catch ({ code, message }) {
      if (
        code === "auth/invalid-email" ||
        code === "auth/user-not-found" ||
        code === "auth/user-disabled"
      ) {
        setErrorPassword(false);
        setErrorEmail(true);
        setErrorEmailText("Email does not exist");
      } else if (code === "auth/wrong-password") {
        setErrorEmail(false);
        setErrorPassword(true);
        setErrorPasswordText("Incorrect password");
      }
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errorEmail}
                helperText={errorEmail && (errorEmailText || "Please provide a valid email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete={"current-password"}
                error={errorPassword}
                helperText={
                  errorPassword &&
                  (errorPasswordText || "Please provide a password with at least 6 characters")
                }
              />
            </Grid>
          </Grid>
          <ExtendedButton
            loaderSize={25}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={loading}
          >
            Sign In
          </ExtendedButton>
        </form>
      </div>
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  link: {
    textDecoration: "none",
    fontSize: theme.typography.body1.fontSize,
    textTransform: "none",
    fontWeight: 500
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default Login;
