import { ChangeEvent, useRef } from "react";

const useHiddenFileSelect = (onFileChange: (files: FileList | null) => void) => {
  const inputRef = useRef(null as any);

  const openFileSelect = () => {
    if (inputRef.current?.click) inputRef.current?.click();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onFileChange(e.target.files);
  };

  return [{ hidden: true, type: "file", ref: inputRef, onChange }, openFileSelect] as const;
};

export default useHiddenFileSelect;
