import * as Sentry from "@sentry/react";
import { ComponentType, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthUserContext, { AuthUser } from "../contexts/AuthUserContext";
import { auth } from "../services/firebaseService";

const withAuthenticationProvider = <P extends object>(WrappedComponent: ComponentType<P>) => {
  return (props: P) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authUser, setAuthUser] = useState<AuthUser>();

    useEffect(() => {
      const unsubscribe = auth.onIdTokenChanged((user) => {
        setAuthUser((prev) => {
          if (user) {
            user.getIdTokenResult(true).then(({ claims }) => {
              const { email, uid, emailVerified, displayName, photoURL } = user;
              Sentry.setUser({
                email: email as string,
                uid,
                role: claims.role
              });
              setAuthUser({
                email: email as string,
                uid,
                emailVerified,
                displayName: displayName as string,
                photoURL: photoURL as string,
                role: claims.role
              });
            });
            return prev;
          }
          Sentry.setUser(null);
          if (location.pathname !== "/login") {
            if (prev) navigate("/login", { replace: true });
            else
              navigate("/login", {
                replace: true,
                state: {
                  redirectPath: location.pathname
                }
              });
          }
        });
      });
      return () => unsubscribe();
    }, []); // eslint-disable-line

    return (
      <AuthUserContext.Provider value={authUser}>
        <WrappedComponent {...props} />
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthenticationProvider;
