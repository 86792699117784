import { People, ThumbDown, ThumbUp, Work } from "@mui/icons-material";
import {
  openNewTab,
  updateCacheAfterRemove,
  useConfirm
} from "@placehires/react-component-library";
import { addDays, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import GenericTable, { Columns } from "../components/table/GenericTable";
import { ROUTES } from "../constants/routes";
import {
  useApproveJobMutation,
  useDisapproveJobMutation,
  useMatchedOrPausedJobsQuery,
  usePostedJobsQuery
} from "../generated/graphqlHooks";
import {
  JobPostingTableInfoFragment,
  JobStatus,
  PostedJobsDocument
} from "../generated/graphqlTypes";

const { REACT_APP_MAIN_CLIENT_URL } = process.env;

const columns = [
  {
    field: "companyName",
    valueGetter: ({ row }) => row.company?.name
  },
  {
    field: "position",
    valueGetter: ({ row }) => row.position?.name
  },
  {
    field: "filterDeadline",
    type: "dateTime",
    valueGetter: (params) => {
      return addDays(new Date(params.value as string), -1);
    },
    valueFormatter: (params) => {
      return format(params.value as Date, "MMM do", {});
    }
  }
] as Columns<JobPostingTableInfoFragment>;

const JobPostings = () => {
  const navigate = useNavigate();
  const { data: matchedOrPausedJobsData, loading: matchedOrPausedJobsLoading } =
    useMatchedOrPausedJobsQuery();
  const { data: postedJobsData, loading: postedJobsLoading } = usePostedJobsQuery();
  const [approveJob] = useApproveJobMutation();
  const [disapproveJob] = useDisapproveJobMutation();
  const showConfirm = useConfirm();

  return (
    <>
      <GenericTable
        title="Posted Job Postings"
        description="Recently posted Job Postings that need review"
        height={400}
        columns={columns}
        rows={postedJobsData?.openJobs}
        actions={[
          {
            name: "View Job Posting",
            icon: Work,
            onClick: (jobPosting) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/jobs/${jobPosting._id}`);
            }
          },
          {
            name: "Approve Job",
            icon: ThumbUp,
            onClick: (jobPosting) => {
              showConfirm(
                () =>
                  approveJob({
                    variables: {
                      id: jobPosting._id
                    },
                    update: (cache) => {
                      updateCacheAfterRemove(cache, PostedJobsDocument, jobPosting._id);
                    }
                  }),
                {
                  title: "Confirm to approve posting"
                }
              );
            }
          },
          {
            name: "Disapprove Job",
            icon: ThumbDown,
            onClick: (jobPosting) => {
              showConfirm(
                () =>
                  disapproveJob({
                    variables: {
                      id: jobPosting._id
                    },
                    update: (cache) => {
                      updateCacheAfterRemove(cache, PostedJobsDocument, jobPosting._id);
                    }
                  }),
                {
                  title: "Confirm to disapprove posting",
                  text: "IMPORTANT: remember to send an email explaining why the posting was disapproved. The system currently does not send any notifications for disapproval"
                }
              );
            }
          }
        ]}
        loading={postedJobsLoading}
      />
      <GenericTable
        title="Job Postings"
        description="Job Postings that are paused or finished matching and currently need applicants filtered"
        height={400}
        columns={[
          ...columns,
          {
            field: "paused",
            type: "boolean",
            valueGetter: ({ row }) => row.status === JobStatus.PAUSED
          }
        ]}
        rows={matchedOrPausedJobsData?.matchedOrPausedJobs}
        actions={[
          {
            name: "View Job Posting",
            icon: Work,
            onClick: (jobPosting) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/jobs/${jobPosting._id}`);
            }
          },
          {
            name: "View Matched Applicants",
            icon: People,
            onClick: (jobPosting) => {
              navigate(ROUTES.JOB_POSTINGS.subroutes.POTENTIAL_APPLICANTS.getPath(jobPosting._id));
            }
          }
        ]}
        loading={matchedOrPausedJobsLoading}
      />
    </>
  );
};

export default JobPostings;
