import { SvgIconComponent } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { DataGrid, DataGridProps, GridColDef, GridRowId } from "@mui/x-data-grid";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { ExtendedButton, makeStyles } from "@placehires/react-component-library";
import { merge, startCase } from "lodash";
import React, { useMemo, useState } from "react";
import { PaletteColorName } from "../../theme";
import Card from "../cards/Card";
import CardBody from "../cards/CardBody";
import CardHeader from "../cards/CardHeader";
import Action, { TableAction } from "./Action";
import NoRowsOverlay from "./NoRowsOverlay";
import Toolbar, { ToolbarProps, TOOLBAR_HEIGHT } from "./Toolbar";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";

export type TableBatchAction<Row> = {
  name: string;
  icon: SvgIconComponent;
  onClick: (selectedRows: Row[]) => void;
  color?: ButtonProps["color"];
};

export interface Column<Row extends GridValidRowModel> extends Omit<GridColDef<Row>, "field"> {
  /** The column identifier. It's used to map with [[RowData]] values. */
  field: keyof Row | string;
}

export type Columns<Row> = Array<Column<Row & ObjectWithId> | keyof (Row & ObjectWithId)>;

function isColumn<Row>(
  column: Column<Row & ObjectWithId> | keyof (Row & ObjectWithId)
): column is Column<Row & ObjectWithId> {
  return typeof column !== "string";
}

type CustomRowModel = (
  | { id: GridRowId }
  | {
      _id: GridRowId;
    }
) & {
  [key: string]: any;
};

type ObjectWithId = {
  id: GridRowId;
};

export interface GenericTableProps<Row = CustomRowModel>
  extends Omit<DataGridProps, "rows" | "columns" | "components" | "componentsProps" | "className"> {
  title: string;
  description: string;
  actions?: TableAction<Row>[];
  batchActions?: TableBatchAction<Row>[];
  headerColor?: PaletteColorName;
  rows: Row[] | undefined;
  columns: Columns<Row>;
  height?: number | string;
  width?: number | string;
  toolbarProps?: ToolbarProps;
}

/** A customized, extended version of Material UI DataGrid: https://material-ui.com/components/data-grid/ */
const GenericTable = <Row extends CustomRowModel>({
  description,
  title,
  headerColor = "primary",
  height = 500,
  width = "100%",
  actions,
  batchActions,
  rows = [],
  columns,
  toolbarProps,
  loading,
  pageSize: initialPageSize = 25,
  initialState,
  ...dataGridProps
}: GenericTableProps<Row>) => {
  const [selection, setSelection] = useState<(number | string)[]>([]);
  const { classes } = useStyles({ height, width, selection });
  const [customLoading, setCustomLoading] = useState(false);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const showToolbar = batchActions || toolbarProps;

  const memoizedCols = useMemo(() => {
    const newColumns = columns.map((col) => {
      let gridCol = col as Column<Row & ObjectWithId>;
      if (!isColumn(col)) {
        gridCol = {
          field: col
        };
      }
      gridCol = {
        headerName: startCase(gridCol.field as string),
        flex: gridCol.field === "id" ? 0 : 1,
        ...gridCol
      };
      return gridCol as GridColDef;
    });
    if (actions) {
      newColumns.push({
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return <Action actions={actions} params={params} />;
        }
      });
    }
    return newColumns;
  }, [actions, columns]);

  const memoizedRows = useMemo(() => {
    return rows.map((row) => ({
      ...row,
      id: row._id || row.id
    }));
  }, [rows]);

  const memoizedInitialState = useMemo(
    () =>
      merge(
        {
          columns: {
            columnVisibilityModel: { _id: false, id: false }
          }
        } as GridInitialStateCommunity,
        initialState
      ),
    [initialState]
  );

  const handleBatchAction = (callback: (selectedRows: any) => void) => {
    const selectedRows = memoizedRows
      ? selection.map((id) => {
          // eslint-disable-next-line eqeqeq
          return memoizedRows.find((element) => element.id == id);
        })
      : [];
    callback(selectedRows);
  };

  return (
    <>
      <Card>
        <CardHeader color={headerColor}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="subtitle2" className={classes.cardCategoryWhite}>
            {description}
          </Typography>
        </CardHeader>
        <CardBody>
          {showToolbar && (
            <div className={classes.toolbarPosition}>
              <div className={classes.batchActions}>
                <div className={classes.selectionNumber}>
                  <IconButton
                    className={classes.selectionClose}
                    onClick={() => setSelection([])}
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="subtitle2" color="primary">
                    {selection.length} items selected
                  </Typography>
                </div>
                <div>
                  {batchActions &&
                    batchActions.map((action, idx) => (
                      <ExtendedButton
                        key={idx}
                        startIcon={<action.icon />}
                        color="primary"
                        backgroundColor={action.color}
                        background
                        onClick={() => handleBatchAction(action.onClick)}
                      >
                        {action.name}
                      </ExtendedButton>
                    ))}
                </div>
              </div>
            </div>
          )}

          <DataGrid
            {...dataGridProps}
            initialState={memoizedInitialState}
            className={classes.table}
            pageSize={pageSize}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            rows={memoizedRows}
            columns={memoizedCols}
            loading={loading || customLoading}
            checkboxSelection={!!batchActions}
            onSelectionModelChange={(model) => {
              setSelection(model);
            }}
            selectionModel={selection}
            disableSelectionOnClick
            hideFooterSelectedRowCount
            components={{
              NoRowsOverlay,
              Toolbar: showToolbar && Toolbar
            }}
            componentsProps={{
              toolbar: { ...toolbarProps, setCustomLoading }
            }}
          />
        </CardBody>
      </Card>
    </>
  );
};

const useStyles = makeStyles<
  Pick<GenericTableProps, "height" | "width"> & { selection: unknown[] }
>()((theme, props) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    marginTop: "3px"
  },
  toolbarPosition: {
    display: "flex",
    height: TOOLBAR_HEIGHT,
    alignItems: "flex-end"
  },
  table: {
    height: props.height,
    width: props.width,
    ...(props.selection.length && {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    })
  },
  batchActions: {
    height: TOOLBAR_HEIGHT + 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
    backgroundColor: "rgb(233, 231, 248)",
    transition: "height 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    position: "relative",
    bottom: -1,
    padding: "0 11px",
    ...(!props.selection.length && {
      height: 0,
      overflow: "hidden"
    })
  },
  selectionNumber: {
    display: "flex",
    alignItems: "center"
  },
  selectionClose: {
    padding: 3,
    marginRight: theme.spacing(0.5)
  }
}));

export default GenericTable;
