import { Grid, TextField, Typography } from "@mui/material";
import {
  findSelectedOption,
  findSelectedOptions,
  FormikAutocomplete,
  FormikDialog,
  FormikTextField,
  listToSelectOptions,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import { lowerCase, startCase } from "lodash";
import React from "react";
import {
  useCreateQuestionnaireMutation,
  useIndustriesQuery,
  useQuestionnaireVariablesQuery
} from "../../../generated/graphqlHooks";
import {
  ErrorCode,
  Industry,
  Questionnaire,
  QuestionnairesDocument,
  QuestionnaireVariable,
  Role
} from "../../../generated/graphqlTypes";

type CreateQuestionnaireProps = {
  open: boolean;
  handleClose: () => void;
  defaultValues?: Questionnaire;
};

const CreateQuestionnaireModal: React.FC<CreateQuestionnaireProps> = ({
  open,
  handleClose,
  defaultValues
}) => {
  const [createQuestionnaire] = useCreateQuestionnaireMutation();
  const { data: industriesData, loading: loadingIndustries } = useIndustriesQuery();
  const { data: variablesData, loading: loadingVariables } = useQuestionnaireVariablesQuery();
  const viewOnly = !!defaultValues;

  const industryOptions = industriesData?.industries;
  const variableOptions = variablesData?.questionnaireVariables;
  if (!industryOptions || !variableOptions) return null;

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title={viewOnly ? "View Questionnaire" : "Create new Questionnaire"}
      noFooter={viewOnly}
      formikConfig={{
        initialValues: !defaultValues
          ? {
              industry: null as unknown as Industry,
              userTarget: "" as Role,
              singleVariables: [] as QuestionnaireVariable[],
              twoVariables: [] as QuestionnaireVariable[],
              singleVarConfigMap: {} as Record<string, number>
            }
          : {
              industry: findSelectedOption(defaultValues.industryId, industryOptions, "_id"),
              userTarget: defaultValues.userTarget,
              singleVariables: findSelectedOptions(
                defaultValues.singleVarConfigs?.map((config) => config.variableId),
                variableOptions,
                "_id"
              ),
              twoVariables: findSelectedOptions(defaultValues.twoVarIds, variableOptions, "_id"),
              singleVarConfigMap: (defaultValues.singleVarConfigs || []).reduce(
                (configMap, config) => {
                  configMap[config.variableId] = config.numQuestions;
                  return configMap;
                },
                {} as Record<string, number>
              )
            },
        onSaveSubmit: async ({
          userTarget,
          industry,
          singleVariables,
          singleVarConfigMap,
          twoVariables
        }) => {
          await createQuestionnaire({
            variables: {
              questionnaireFields: {
                industryId: industry._id,
                userTarget,
                singleVarConfigs: singleVariables.map((variable) => ({
                  variableId: variable._id,
                  numQuestions: singleVarConfigMap[variable._id]
                    ? Number(singleVarConfigMap[variable._id])
                    : null
                })),
                twoVarIds: twoVariables.map((variable) => variable._id)
              }
            },
            update: (cache, mutationResult) => {
              updateCacheAfterAdd(
                cache,
                QuestionnairesDocument,
                mutationResult.data?.createQuestionnaire
              );
            }
          });
        },
        errorsToFieldName: {
          [ErrorCode.CONFLICT]: {
            fieldName: "industry",
            customErrorMessage: "This industry already has questionnaire for selected user target"
          }
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikAutocomplete
              disabled={viewOnly}
              required
              name="industry"
              fullWidth
              loading={loadingIndustries}
              options={industriesData?.industries || []}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              disabled={viewOnly}
              required
              name="userTarget"
              fullWidth
              select
              SelectProps={{
                options: listToSelectOptions([Role.APPLICANT, Role.RECRUITER], (value) =>
                  startCase(lowerCase(value))
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikAutocomplete
              disabled={viewOnly}
              multiple
              name="singleVariables"
              label="Variables to measure in questionnaire"
              fullWidth
              loading={loadingVariables}
              options={variablesData?.questionnaireVariables || []}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikAutocomplete
              disabled={viewOnly}
              multiple
              name="twoVariables"
              label="AHP variables"
              fullWidth
              loading={loadingVariables}
              options={variablesData?.questionnaireVariables || []}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Single variable configurations</Typography>
          </Grid>
          {values.singleVariables.map((variable, index) => (
            <Grid item container xs={12} key={variable._id}>
              <Grid item xs={4} container alignItems="center">
                <Typography>{variable.name}</Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  disabled={viewOnly}
                  type="number"
                  label="Number of questions"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    min: 0
                  }}
                  value={values.singleVarConfigMap[variable._id]}
                  onChange={(e) => {
                    setFieldValue("singleVarConfigMap", {
                      ...values.singleVarConfigMap,
                      [variable._id]: e.target.value
                    });
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </FormikDialog>
  );
};

export default CreateQuestionnaireModal;
