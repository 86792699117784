import { Grid } from "@mui/material";
import {
  alertAction,
  FormikAutocomplete,
  FormikDialog,
  FormikTextField,
  listToSelectOptions
} from "@placehires/react-component-library";
import { camelCase, lowerCase, mapValues, random, startCase } from "lodash";
import React, { useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import {
  useCreateQuestionsMutation,
  useOptionGroupsBaseFieldsQuery,
  useQuestionnaireVariablesQuery
} from "../../../generated/graphqlHooks";
import {
  OptionGroup,
  QuestionInput,
  QuestionnaireVariable,
  QuestionsWithVariablesDocument,
  QuestionType,
  Role,
  VariableResult
} from "../../../generated/graphqlTypes";
import { parseCsvIntoInsertItems, parseFirstCsvRow } from "../../../utils/csvUtils";

const varHeaders = ["statement", "result"];
const headersMap = {
  statement: "title",
  result: "variableResult"
} as Record<string, string>;
const valuesMap = {
  "0": VariableResult.PLACEBO,
  "+1": VariableResult.POSITIVE,
  "+": VariableResult.POSITIVE,
  "-1": VariableResult.NEGATIVE,
  "-": VariableResult.NEGATIVE
} as Record<string, string>;

const twoVarTemplate = (positiveVar: QuestionnaireVariable, negativeVar: QuestionnaireVariable) => {
  return `I value ${positiveVar.name} over ${negativeVar.name}`;
};

type CreateQuestionProps = {
  open: boolean;
  handleClose: () => void;
};

const CreateQuestionModal: React.FC<CreateQuestionProps> = ({ open, handleClose }) => {
  const [createQuestions] = useCreateQuestionsMutation();
  const { data: optionGroupsData, loading: loadingOptionGroups } = useOptionGroupsBaseFieldsQuery();
  const { data: variablesData, loading: loadingVariables } = useQuestionnaireVariablesQuery();
  const [csvFile, setCsvFile] = useState<File>();

  const validCsvFile = async (file: File) => {
    let headers = await parseFirstCsvRow(file);
    headers = headers.map(lowerCase);
    return varHeaders.every((neededHeader) => {
      if (headers.every((header) => header !== neededHeader)) {
        alertAction(`Csv files is missing column: ${neededHeader}`);
        return false;
      }
      return true;
    });
  };

  const singleVarPart = () => (
    <>
      <Grid item xs={12}>
        <FormikAutocomplete
          required
          name="singleVariable"
          fullWidth
          loading={loadingVariables}
          options={variablesData?.questionnaireVariables || []}
          getOptionLabel={(option) => option.name}
        />
      </Grid>
      <Grid item xs={12}>
        <DropzoneArea
          inputProps={{
            required: true
          }}
          filesLimit={1}
          acceptedFiles={[".csv"]}
          dropzoneText={`Upload a csv file with following columns: ${varHeaders}`}
          onChange={async (files) => {
            const file = files[0];
            setCsvFile(file);
          }}
        />
      </Grid>
    </>
  );

  const twoVarPart = () => (
    <Grid item xs={12}>
      <FormikAutocomplete
        required
        multiple
        name="varsToCombo"
        label="AHP variables"
        fullWidth
        loading={loadingVariables}
        options={variablesData?.questionnaireVariables || []}
        getOptionLabel={(option) => option.name}
      />
    </Grid>
  );

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Create new Questions"
      formikConfig={{
        initialValues: {
          type: "" as QuestionType,
          userTarget: "" as Role,
          optionGroup: null as unknown as OptionGroup,
          singleVariable: null as unknown as QuestionnaireVariable,
          varsToCombo: [] as QuestionnaireVariable[]
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: async ({ type, varsToCombo }) => {
          const errors = {} as Record<string, string>;
          if (csvFile) {
            const validFile = await validCsvFile(csvFile);
            if (!validFile) {
              errors.file = "Invalid file";
            }
          }
          if (type === QuestionType.TWO_VARIABLES && varsToCombo.length < 2) {
            errors.varsToCombo = "Select at least 2 variables";
          }
          return errors;
        },
        onSaveSubmit: async ({ optionGroup, type, userTarget, singleVariable, varsToCombo }) => {
          let questionsWithVar: Array<
            Pick<QuestionInput, "title" | "singleVariableDetails" | "twoVariablesDetails">
          >;
          if (type === QuestionType.SINGLE_VARIABLE) {
            const parsedItems = (await parseCsvIntoInsertItems(csvFile!, {
              transformHeader: (header) => {
                const lowerCaseHeader = lowerCase(header);
                return headersMap[lowerCaseHeader] || camelCase(lowerCaseHeader);
              }
            })) as Record<string, string>[];
            // Map csv input to question input
            const csvValues = parsedItems.map((item) =>
              mapValues(item, (val: string) => {
                return valuesMap[val] || val;
              })
            ) as unknown as {
              title: string;
              variableResult: VariableResult;
            }[];
            questionsWithVar = csvValues.map((row) => ({
              title: row.title,
              singleVariableDetails: {
                variableId: singleVariable._id,
                variableResult: row.variableResult
              }
            }));
          } else {
            questionsWithVar = [];
            for (let i = 0; i < varsToCombo.length; i++) {
              for (let j = i + 1; j < varsToCombo.length; j++) {
                // Randomize negative and positive variable
                const negativeIndex = random(0, 1) ? i : j;
                const positiveIndex = negativeIndex === i ? j : i;
                const negativeVar = varsToCombo[negativeIndex];
                const positiveVar = varsToCombo[positiveIndex];
                const title = twoVarTemplate(positiveVar, negativeVar);
                questionsWithVar.push({
                  title,
                  twoVariablesDetails: {
                    positiveVariableId: positiveVar._id,
                    negativeVariableId: negativeVar._id
                  }
                });
              }
            }
          }
          await createQuestions({
            variables: {
              questionsFields: questionsWithVar.map((question) => ({
                type,
                optionGroupId: optionGroup._id,
                userTarget,
                ...question
              }))
            },
            refetchQueries: [{ query: QuestionsWithVariablesDocument }]
          });
        }
      }}
    >
      {({ values }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikTextField
              required
              name="userTarget"
              fullWidth
              select
              SelectProps={{
                options: listToSelectOptions([Role.APPLICANT, Role.RECRUITER], (value) =>
                  startCase(lowerCase(value))
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikAutocomplete
              required
              name="optionGroup"
              fullWidth
              loading={loadingOptionGroups}
              options={(optionGroupsData?.optionGroups || []).filter((option) => option.withScores)}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              required
              name="type"
              fullWidth
              select
              SelectProps={{
                options: listToSelectOptions(
                  [QuestionType.SINGLE_VARIABLE, QuestionType.TWO_VARIABLES],
                  startCase
                )
              }}
            />
          </Grid>
          {values.type
            ? values.type === QuestionType.SINGLE_VARIABLE
              ? singleVarPart()
              : twoVarPart()
            : ""}
        </Grid>
      )}
    </FormikDialog>
  );
};

export default CreateQuestionModal;
