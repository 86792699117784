import { createContext } from "react";
import { ADMIN_ROLE, APPLICANT_ROLE, RECRUITER_ROLE } from "../constants/general";

export type AuthUser = {
  email: string;
  uid: string;
  emailVerified: boolean;
  displayName: string;
  photoURL: string;
  role: typeof APPLICANT_ROLE | typeof RECRUITER_ROLE | typeof ADMIN_ROLE;
};

const AuthUserContext = createContext(undefined as AuthUser | undefined);

export default AuthUserContext;
