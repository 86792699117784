import { makeStyles } from "@placehires/react-component-library";
import { FC, ReactNode } from "react";
import { PaletteColorName } from "../../theme";
import { BLACK_COLOR, hexToRgbValues, WHITE_COLOR } from "../../utils/styles";

export type CardHeaderProps = {
  children: ReactNode;
  className?: string;
  color: PaletteColorName;
  plain?: boolean;
  stats?: boolean;
  icon?: boolean;
};

const CardHeader: FC<CardHeaderProps> = ({
  className,
  children,
  color = "primary",
  plain,
  stats,
  icon,
  ...rest
}) => {
  const { classes, cx } = useStyles({ color });
  const cardHeaderClasses = cx(classes.cardHeader, {
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className as string]: className !== undefined
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

const useStyles = makeStyles<Pick<CardHeaderProps, "color">>()((theme, props) => ({
  cardHeader: {
    background: `linear-gradient(60deg, ${theme.palette[props.color].light}, ${
      theme.palette[props.color].main
    })`,
    boxShadow:
      "0 4px 20px 0 rgba(" +
      hexToRgbValues(BLACK_COLOR) +
      ",.14), 0 7px 10px -5px rgba(" +
      hexToRgbValues(theme.palette[props.color].main) +
      ",.4)",
    marginBottom: 0,
    borderBottom: "none",
    margin: "0 15px",
    position: "relative",
    color: WHITE_COLOR,
    borderRadius: 3,
    marginTop: -20,
    padding: 15
  },
  cardHeaderPlain: {
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  cardHeaderStats: {
    "& $cardHeaderIcon": {
      textAlign: "right"
    },
    "& h1,& h2,& h3,& h4,& h5,& h6": {
      margin: "0 !important"
    }
  },
  cardHeaderIcon: {
    background: "transparent",
    boxShadow: "none",
    "& i,& .material-icons": {
      width: "33px",
      height: "33px",
      textAlign: "center",
      lineHeight: "33px"
    },
    "& svg": {
      width: "24px",
      height: "24px",
      textAlign: "center",
      lineHeight: "33px",
      margin: "5px 4px 0px"
    }
  }
}));

export default CardHeader;
