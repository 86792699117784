import { FC, ReactNode, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import withAuthenticationProvider from "./hocs/withAuthenticationProvider";
import { useUser } from "./hooks/authHooks";
import Layout from "./layout/Layout";
import Login from "./pages/Login";
import { Routes as RoutesMap } from "./utils/globalTypes";

const App: FC = () => {
  const user = useUser();

  const routes = useMemo(() => renderRoutes(ROUTES), []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="dashboard" replace />} />
      <Route path="/login" element={<Login />} />
      {user && (
        <Route
          path="*"
          element={
            <Layout>
              <Routes>{routes}</Routes>
            </Layout>
          }
        />
      )}
    </Routes>
  );
};

const renderRoutes = (routes: RoutesMap) => {
  const routesComponents: ReactNode[] = [];
  Object.values(routes).forEach((route) => {
    routesComponents.push(
      <Route path={route.path} element={<route.component />} key={route.path} />
    );
    if (route.subroutes) {
      routesComponents.push(...renderRoutes(route.subroutes));
    }
  });
  return routesComponents;
};

export default withAuthenticationProvider(App);
