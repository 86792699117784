import { makeStyles } from "@placehires/react-component-library";
import { FC, ReactNode } from "react";

export type CardBodyProps = {
  children: ReactNode;
  className?: string;
  plain?: boolean;
  profile?: boolean;
};

const CardBody: FC<CardBodyProps> = ({ className, children, plain, profile, ...rest }) => {
  const { classes, cx } = useStyles();
  const cardBodyClasses = cx([classes.cardBody], {
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className as string]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};

const useStyles = makeStyles()({
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    position: "relative"
  },
  cardBodyPlain: {
    paddingLeft: 5,
    paddingRight: 5
  },
  cardBodyProfile: {
    marginTop: 15
  }
});

export default CardBody;
