import axios from "axios";
import { auth } from "./firebaseService";

const AUTH_URL = `${process.env.REACT_APP_BACKEND_URL}auth/`;

// login to app
export const login = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

// logout, closing websocket connection and clearing cache
export const logout = () => {
  return auth.signOut();
};

// get the token of current user as a promise
export const getUserToken = (): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      if (user) resolve(user.getIdToken());
      else resolve(null);
    }, reject);
  });
};

// send password reset email
export const sendPasswordResetLink = (email: string) => {
  return axios.post(`${AUTH_URL}/sendResetPassword`, { email });
};
