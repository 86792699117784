import { makeStyles } from "@placehires/react-component-library";
import { FC, ReactNode } from "react";
import { BLACK_COLOR, hexToRgbValues, WHITE_COLOR } from "../../utils/styles";

type CardProps = {
  children: ReactNode;
  className?: string;
  plain?: boolean;
  profile?: boolean;
  chart?: boolean;
};

const Card: FC<CardProps> = ({ className, children, plain, profile, chart, ...rest }) => {
  const { classes, cx } = useStyles();
  const cardClasses = cx([classes.card], {
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className as string]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};

const useStyles = makeStyles()({
  card: {
    border: "0",
    marginBottom: 30,
    marginTop: 30,
    borderRadius: 6,
    color: "rgba(" + hexToRgbValues(BLACK_COLOR) + ", 0.87)",
    background: WHITE_COLOR,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgbValues(BLACK_COLOR) + ", 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem"
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: 30,
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: 0,
      paddingTop: 0
    }
  }
});

export default Card;
