import { People, Warning } from "@mui/icons-material";
import {
  openNewTab,
  updateCacheAfterRemove,
  useConfirm
} from "@placehires/react-component-library";
import GenericTable from "../components/table/GenericTable";
import {
  useResolvedIssuesQuery,
  useResolveIssueMutation,
  useUnresolvedIssuesQuery,
  useUnresolveIssueMutation
} from "../generated/graphqlHooks";
import { ResolvedIssuesDocument, UnresolvedIssuesDocument } from "../generated/graphqlTypes";

const { REACT_APP_MAIN_CLIENT_URL } = process.env;
const ManageIssues = () => {
  const { data, loading, refetch: refetchResolvedIssues } = useResolvedIssuesQuery();
  const [unresolveIssue] = useUnresolveIssueMutation();
  const {
    data: unresolvedData,
    loading: unresolvedLoading,
    refetch: refetchUnresolvedIssues
  } = useUnresolvedIssuesQuery();
  const [resolveIssue] = useResolveIssueMutation();
  const showConfirm = useConfirm();

  return (
    <>
      <GenericTable
        title="Unresolved issues report"
        description="Unresolved issues report"
        columns={["message"]}
        rows={unresolvedData?.unresolvedIssues}
        actions={[
          {
            name: "View user who reported this issue",
            icon: People,
            onClick: (issue) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/${issue.sender}`);
            }
          },
          {
            name: "Resolve Issue",
            icon: Warning,
            onClick: (issue) => {
              showConfirm(
                () =>
                  resolveIssue({
                    variables: {
                      id: issue._id
                    },
                    update: (cache) => {
                      refetchResolvedIssues();
                      updateCacheAfterRemove(cache, UnresolvedIssuesDocument, issue._id);
                    }
                  }),
                {
                  title: "Confirm to resolve issue"
                }
              );
            }
          }
        ]}
        loading={unresolvedLoading}
      />

      <GenericTable
        title="Resolved issues report"
        description="Resolved issues report"
        columns={["message"]}
        rows={data?.resolvedIssues}
        actions={[
          {
            name: "View user who reported this issue",
            icon: People,
            onClick: (issue) => {
              openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/${issue.sender}`);
            }
          },
          {
            name: "Mark as unresolved",
            icon: Warning,
            onClick: (issue) => {
              showConfirm(
                () =>
                  unresolveIssue({
                    variables: {
                      id: issue._id
                    },
                    update: (cache) => {
                      refetchUnresolvedIssues();
                      updateCacheAfterRemove(cache, ResolvedIssuesDocument, issue._id);
                    }
                  }),
                {
                  title: "Confirm to unresolve issue"
                }
              );
            }
          }
        ]}
        loading={loading}
      />
    </>
  );
};

export default ManageIssues;
