import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_ROLE } from "../constants/general";
import AuthUserContext from "../contexts/AuthUserContext";
import { auth } from "../services/firebaseService";

export const useUser = () => useContext(AuthUserContext);

export const useNoAuth = (redirectPath?: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          if (claims.role === ADMIN_ROLE)
            navigate(redirectPath || `/`, {
              replace: true
            });
        });
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
