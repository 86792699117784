import {
  Business,
  Feedback,
  QuestionAnswer,
  Receipt,
  ReportProblem,
  SupervisedUserCircle,
  SupervisorAccount,
  Work,
  WorkOutline
} from "@mui/icons-material";
import JobApplicantsChoosing from "../pages/JobApplicantsChoosing";
import JobPostings from "../pages/JobPostings";
import ManageAdmins from "../pages/ManageAdmins";
import ManageIndustries from "../pages/ManageIndustries";
import ManageIssues from "../pages/ManageIssues";
import ManagePositions from "../pages/ManagePositions";
import ManageRecruiters from "../pages/ManageRecruiters";
import ManageSkillsAndCertifications from "../pages/ManageSkillsAndCertifications";
import ManageOptionGroups from "../pages/questionnaire-pages/ManageOptionGroups";
import ManageQuestionnaires from "../pages/questionnaire-pages/ManageQuestionnaires";
import ManageVariables from "../pages/questionnaire-pages/ManageVariables";
import QuestionBank from "../pages/questionnaire-pages/QuestionBank";
import UserFeedback from "../pages/UserFeedback";
import { SidebarRoute } from "../utils/globalTypes";

export const ROUTES = {
  QUESTIONNAIRES: {
    name: "Questionnaires",
    component: ManageQuestionnaires,
    path: "/questionnaires",
    subroutes: {
      VARIABLES: {
        name: "Variables",
        path: "/questionnaires/variables",
        component: ManageVariables
      },
      OPTION_GROUPS: {
        name: "Option Groups",
        path: "/questionnaires/optionGroups",
        component: ManageOptionGroups
      },
      QUESTION_BANK: {
        name: "Question Bank",
        path: "/questionnaires/questions",
        component: QuestionBank
      }
    }
  },
  INDUSTRIES: {
    path: "/manage-industries",
    name: "Manage Industries",
    component: ManageIndustries
  },
  SKILLS_AND_CERTS: {
    path: "/manage-skills-and-certifications",
    name: "Manage Skills and Certifications",
    component: ManageSkillsAndCertifications
  },
  POSITIONS: {
    path: "/manage-positions",
    name: "Manage Positions",
    component: ManagePositions
  },
  RECRUITERS: {
    path: "/verify-recruiters",
    name: "Verify Recruiters",
    component: ManageRecruiters
  },
  JOB_POSTINGS: {
    path: "/jobs",
    name: "Job Postings",
    component: JobPostings,
    subroutes: {
      POTENTIAL_APPLICANTS: {
        path: "/jobs/:jobId/filter-applicants",
        name: "Filter Applicants",
        component: JobApplicantsChoosing,
        notExact: true,
        getPath: (jobId: string) => `/jobs/${jobId}/filter-applicants`
      }
    }
  },
  ISSUES: {
    path: "/manage-issues",
    name: "Reported Issues",
    component: ManageIssues
  },
  FEEDBACK: {
    path: "/feedback",
    name: "User Feedback",
    component: UserFeedback
  },
  ADMINS: {
    path: "/admins",
    name: "Admin Users",
    component: ManageAdmins
  }
};

export const SIDEBAR_ROUTES: SidebarRoute[] = [
  {
    route: ROUTES.INDUSTRIES,
    icon: Business
  },
  {
    route: ROUTES.SKILLS_AND_CERTS,
    icon: Receipt
  },
  {
    route: ROUTES.POSITIONS,
    icon: WorkOutline
  },
  {
    name: "Questionnaires",
    icon: QuestionAnswer,
    routes: [
      ROUTES.QUESTIONNAIRES.subroutes.VARIABLES,
      ROUTES.QUESTIONNAIRES.subroutes.OPTION_GROUPS,
      ROUTES.QUESTIONNAIRES.subroutes.QUESTION_BANK,
      ROUTES.QUESTIONNAIRES
    ]
  },
  {
    route: ROUTES.RECRUITERS,
    icon: SupervisedUserCircle
  },
  {
    route: ROUTES.JOB_POSTINGS,
    icon: Work
  },
  {
    route: ROUTES.ISSUES,
    icon: ReportProblem
  },
  {
    route: ROUTES.FEEDBACK,
    icon: Feedback
  },
  {
    route: ROUTES.ADMINS,
    icon: SupervisorAccount
  }
];
