import { ApolloProvider } from "@apollo/client";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ConfirmProvider, MaterialNotification } from "@placehires/react-component-library";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import client from "./apollo/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

const env = process.env.REACT_APP_ACTIVE_ENV || process.env.NODE_ENV;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: env,
  ...(env === "production" && { release: process.env.npm_package_version })
});

export const muiCache = createCache({
  key: "mui",
  prepend: true
});

const Root = () => (
  <CacheProvider value={muiCache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <MaterialNotification theme={theme} />
        <ConfirmProvider>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </ConfirmProvider>
      </BrowserRouter>
    </ThemeProvider>
  </CacheProvider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
