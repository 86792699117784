export const separateString = (str: string, separator = ",") => {
  const whiteSpacesRegex = /^\s*$/;
  return str.split(separator).filter((e) => !whiteSpacesRegex.test(e));
};

/** Sleep for ${ms}ms */
export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
