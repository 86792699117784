import { findSelectedOptions } from "@placehires/react-component-library";
import { useMemo } from "react";
import { useSkillAllQuery } from "../../generated/graphqlHooks";

export const useSkillsObjects = (skillsIds: string[] | undefined) => {
  const queryResult = useSkillAllQuery();
  const skillsObjs = useMemo(
    () => findSelectedOptions(skillsIds, queryResult.data?.skills!, "_id"), // TODO make findSelectedOptions allow undefined for options
    [skillsIds, queryResult.data?.skills]
  );

  return { ...queryResult, data: skillsObjs };
};
