import { PlayCircleFilled } from "@mui/icons-material";
import { useConfirm } from "@placehires/react-component-library";
import GenericTable from "../../components/table/GenericTable";
import { useActivateIndustryMutation, useIndustriesQuery } from "../../generated/graphqlHooks";
import useModalState from "../../hooks/useModalState";
import CreateIndustryModal from "./CreateIndustryModal";

const ManageIndustries = () => {
  const { data: industriesDate, loading: loadingIndustries } = useIndustriesQuery();
  const [activateIndustry] = useActivateIndustryMutation();
  const [createIndustryOpen, openCreateIndustry, closeCreateIndustry] = useModalState();

  const showConfirm = useConfirm();

  return (
    <>
      <GenericTable
        title="Industries"
        description="Industries on the platform"
        columns={[
          "name",
          {
            field: "active",
            type: "boolean",
            width: 150
          }
        ]}
        loading={loadingIndustries}
        rows={industriesDate?.industries}
        toolbarProps={{
          primaryButton: {
            text: "Create new Industry",
            onClick: openCreateIndustry
          }
        }}
        actions={[
          {
            name: "Activate industry",
            icon: PlayCircleFilled,
            onClick: (industry) => {
              showConfirm(
                () =>
                  activateIndustry({
                    variables: {
                      id: industry._id
                    }
                  }),
                {
                  title: "Confirm to activate industry",
                  text: "Active industries are visible to applicants and recruiters"
                }
              );
            }
          }
        ]}
      />
      <CreateIndustryModal open={createIndustryOpen} handleClose={closeCreateIndustry} />
    </>
  );
};

export default ManageIndustries;
