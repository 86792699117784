import { Business, Check } from "@mui/icons-material";
import {
  openNewTab,
  updateCacheAfterRemove,
  useConfirm
} from "@placehires/react-component-library";
import GenericTable from "../components/table/GenericTable";
import {
  useUnverifiedRecruitersQuery,
  useVerifyRecruiterMutation
} from "../generated/graphqlHooks";
import { UnverifiedRecruitersDocument } from "../generated/graphqlTypes";

const { REACT_APP_MAIN_CLIENT_URL } = process.env;
const ManageRecruiters = () => {
  const { data, loading } = useUnverifiedRecruitersQuery();
  const [verifyRecruiter] = useVerifyRecruiterMutation();
  const showConfirm = useConfirm();

  return (
    <GenericTable
      title="Unverified Recruiters"
      description="Unverified recruiters waiting to be verified"
      columns={["firstName", "lastName", "headline"]}
      rows={data?.unverifiedRecruiters}
      actions={[
        {
          name: "View Company",
          icon: Business,
          onClick: (recruiter) => {
            openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/companies/${recruiter.companyId}`);
          }
        },
        {
          name: "Verify Recruiter",
          icon: Check,
          onClick: (recruiter) => {
            showConfirm(
              () =>
                verifyRecruiter({
                  variables: {
                    id: recruiter._id
                  },
                  update: (cache) => {
                    updateCacheAfterRemove(cache, UnverifiedRecruitersDocument, recruiter._id);
                  }
                }),
              {
                title: "Confirm to verify recruiter"
              }
            );
          }
        }
      ]}
      loading={loading}
    />
  );
};

export default ManageRecruiters;
