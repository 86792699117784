/* This file was generated based on "codegen.yml". Do not edit manually. */
/* eslint-disable */
import type * as Types from "./graphqlTypes";
import * as Operations from './graphqlTypes';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;


/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: Apollo.QueryHookOptions<Types.AdminsQuery, Types.AdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AdminsQuery, Types.AdminsQueryVariables>(Operations.AdminsDocument, options);
      }
export function useAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AdminsQuery, Types.AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AdminsQuery, Types.AdminsQueryVariables>(Operations.AdminsDocument, options);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = Apollo.QueryResult<Types.AdminsQuery, Types.AdminsQueryVariables>;
export type SignUpAdminMutationFn = Apollo.MutationFunction<Types.SignUpAdminMutation, Types.SignUpAdminMutationVariables>;

/**
 * __useSignUpAdminMutation__
 *
 * To run a mutation, you first call `useSignUpAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpAdminMutation, { data, loading, error }] = useSignUpAdminMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSignUpAdminMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpAdminMutation, Types.SignUpAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpAdminMutation, Types.SignUpAdminMutationVariables>(Operations.SignUpAdminDocument, options);
      }
export type SignUpAdminMutationHookResult = ReturnType<typeof useSignUpAdminMutation>;
export type SignUpAdminMutationResult = Apollo.MutationResult<Types.SignUpAdminMutation>;
export type SignUpAdminMutationOptions = Apollo.BaseMutationOptions<Types.SignUpAdminMutation, Types.SignUpAdminMutationVariables>;

/**
 * __useJobInterestedApplicationsQuery__
 *
 * To run a query within a React component, call `useJobInterestedApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobInterestedApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobInterestedApplicationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobInterestedApplicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.JobInterestedApplicationsQuery, Types.JobInterestedApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobInterestedApplicationsQuery, Types.JobInterestedApplicationsQueryVariables>(Operations.JobInterestedApplicationsDocument, options);
      }
export function useJobInterestedApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobInterestedApplicationsQuery, Types.JobInterestedApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobInterestedApplicationsQuery, Types.JobInterestedApplicationsQueryVariables>(Operations.JobInterestedApplicationsDocument, options);
        }
export type JobInterestedApplicationsQueryHookResult = ReturnType<typeof useJobInterestedApplicationsQuery>;
export type JobInterestedApplicationsLazyQueryHookResult = ReturnType<typeof useJobInterestedApplicationsLazyQuery>;
export type JobInterestedApplicationsQueryResult = Apollo.QueryResult<Types.JobInterestedApplicationsQuery, Types.JobInterestedApplicationsQueryVariables>;

/**
 * __useJobConsideredMatchApplicationQuery__
 *
 * To run a query within a React component, call `useJobConsideredMatchApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobConsideredMatchApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobConsideredMatchApplicationQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobConsideredMatchApplicationQuery(baseOptions: Apollo.QueryHookOptions<Types.JobConsideredMatchApplicationQuery, Types.JobConsideredMatchApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobConsideredMatchApplicationQuery, Types.JobConsideredMatchApplicationQueryVariables>(Operations.JobConsideredMatchApplicationDocument, options);
      }
export function useJobConsideredMatchApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobConsideredMatchApplicationQuery, Types.JobConsideredMatchApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobConsideredMatchApplicationQuery, Types.JobConsideredMatchApplicationQueryVariables>(Operations.JobConsideredMatchApplicationDocument, options);
        }
export type JobConsideredMatchApplicationQueryHookResult = ReturnType<typeof useJobConsideredMatchApplicationQuery>;
export type JobConsideredMatchApplicationLazyQueryHookResult = ReturnType<typeof useJobConsideredMatchApplicationLazyQuery>;
export type JobConsideredMatchApplicationQueryResult = Apollo.QueryResult<Types.JobConsideredMatchApplicationQuery, Types.JobConsideredMatchApplicationQueryVariables>;

/**
 * __useNumJobChosenApplicationsQuery__
 *
 * To run a query within a React component, call `useNumJobChosenApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumJobChosenApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumJobChosenApplicationsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useNumJobChosenApplicationsQuery(baseOptions: Apollo.QueryHookOptions<Types.NumJobChosenApplicationsQuery, Types.NumJobChosenApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NumJobChosenApplicationsQuery, Types.NumJobChosenApplicationsQueryVariables>(Operations.NumJobChosenApplicationsDocument, options);
      }
export function useNumJobChosenApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NumJobChosenApplicationsQuery, Types.NumJobChosenApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NumJobChosenApplicationsQuery, Types.NumJobChosenApplicationsQueryVariables>(Operations.NumJobChosenApplicationsDocument, options);
        }
export type NumJobChosenApplicationsQueryHookResult = ReturnType<typeof useNumJobChosenApplicationsQuery>;
export type NumJobChosenApplicationsLazyQueryHookResult = ReturnType<typeof useNumJobChosenApplicationsLazyQuery>;
export type NumJobChosenApplicationsQueryResult = Apollo.QueryResult<Types.NumJobChosenApplicationsQuery, Types.NumJobChosenApplicationsQueryVariables>;
export type UpdateApplicationToConsideredMutationFn = Apollo.MutationFunction<Types.UpdateApplicationToConsideredMutation, Types.UpdateApplicationToConsideredMutationVariables>;

/**
 * __useUpdateApplicationToConsideredMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationToConsideredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationToConsideredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationToConsideredMutation, { data, loading, error }] = useUpdateApplicationToConsideredMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateApplicationToConsideredMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicationToConsideredMutation, Types.UpdateApplicationToConsideredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicationToConsideredMutation, Types.UpdateApplicationToConsideredMutationVariables>(Operations.UpdateApplicationToConsideredDocument, options);
      }
export type UpdateApplicationToConsideredMutationHookResult = ReturnType<typeof useUpdateApplicationToConsideredMutation>;
export type UpdateApplicationToConsideredMutationResult = Apollo.MutationResult<Types.UpdateApplicationToConsideredMutation>;
export type UpdateApplicationToConsideredMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicationToConsideredMutation, Types.UpdateApplicationToConsideredMutationVariables>;
export type UpdateApplicationToInterestedMutationFn = Apollo.MutationFunction<Types.UpdateApplicationToInterestedMutation, Types.UpdateApplicationToInterestedMutationVariables>;

/**
 * __useUpdateApplicationToInterestedMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationToInterestedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationToInterestedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationToInterestedMutation, { data, loading, error }] = useUpdateApplicationToInterestedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateApplicationToInterestedMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateApplicationToInterestedMutation, Types.UpdateApplicationToInterestedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateApplicationToInterestedMutation, Types.UpdateApplicationToInterestedMutationVariables>(Operations.UpdateApplicationToInterestedDocument, options);
      }
export type UpdateApplicationToInterestedMutationHookResult = ReturnType<typeof useUpdateApplicationToInterestedMutation>;
export type UpdateApplicationToInterestedMutationResult = Apollo.MutationResult<Types.UpdateApplicationToInterestedMutation>;
export type UpdateApplicationToInterestedMutationOptions = Apollo.BaseMutationOptions<Types.UpdateApplicationToInterestedMutation, Types.UpdateApplicationToInterestedMutationVariables>;
export type FilterApplicationsMutationFn = Apollo.MutationFunction<Types.FilterApplicationsMutation, Types.FilterApplicationsMutationVariables>;

/**
 * __useFilterApplicationsMutation__
 *
 * To run a mutation, you first call `useFilterApplicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFilterApplicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [filterApplicationsMutation, { data, loading, error }] = useFilterApplicationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFilterApplicationsMutation(baseOptions?: Apollo.MutationHookOptions<Types.FilterApplicationsMutation, Types.FilterApplicationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FilterApplicationsMutation, Types.FilterApplicationsMutationVariables>(Operations.FilterApplicationsDocument, options);
      }
export type FilterApplicationsMutationHookResult = ReturnType<typeof useFilterApplicationsMutation>;
export type FilterApplicationsMutationResult = Apollo.MutationResult<Types.FilterApplicationsMutation>;
export type FilterApplicationsMutationOptions = Apollo.BaseMutationOptions<Types.FilterApplicationsMutation, Types.FilterApplicationsMutationVariables>;

/**
 * __useCertificationBasicQuery__
 *
 * To run a query within a React component, call `useCertificationBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCertificationBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.CertificationBasicQuery, Types.CertificationBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CertificationBasicQuery, Types.CertificationBasicQueryVariables>(Operations.CertificationBasicDocument, options);
      }
export function useCertificationBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CertificationBasicQuery, Types.CertificationBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CertificationBasicQuery, Types.CertificationBasicQueryVariables>(Operations.CertificationBasicDocument, options);
        }
export type CertificationBasicQueryHookResult = ReturnType<typeof useCertificationBasicQuery>;
export type CertificationBasicLazyQueryHookResult = ReturnType<typeof useCertificationBasicLazyQuery>;
export type CertificationBasicQueryResult = Apollo.QueryResult<Types.CertificationBasicQuery, Types.CertificationBasicQueryVariables>;

/**
 * __useCertificationAllQuery__
 *
 * To run a query within a React component, call `useCertificationAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificationAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificationAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCertificationAllQuery(baseOptions?: Apollo.QueryHookOptions<Types.CertificationAllQuery, Types.CertificationAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CertificationAllQuery, Types.CertificationAllQueryVariables>(Operations.CertificationAllDocument, options);
      }
export function useCertificationAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CertificationAllQuery, Types.CertificationAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CertificationAllQuery, Types.CertificationAllQueryVariables>(Operations.CertificationAllDocument, options);
        }
export type CertificationAllQueryHookResult = ReturnType<typeof useCertificationAllQuery>;
export type CertificationAllLazyQueryHookResult = ReturnType<typeof useCertificationAllLazyQuery>;
export type CertificationAllQueryResult = Apollo.QueryResult<Types.CertificationAllQuery, Types.CertificationAllQueryVariables>;
export type CreateCertificationMutationFn = Apollo.MutationFunction<Types.CreateCertificationMutation, Types.CreateCertificationMutationVariables>;

/**
 * __useCreateCertificationMutation__
 *
 * To run a mutation, you first call `useCreateCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCertificationMutation, { data, loading, error }] = useCreateCertificationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCertificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCertificationMutation, Types.CreateCertificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCertificationMutation, Types.CreateCertificationMutationVariables>(Operations.CreateCertificationDocument, options);
      }
export type CreateCertificationMutationHookResult = ReturnType<typeof useCreateCertificationMutation>;
export type CreateCertificationMutationResult = Apollo.MutationResult<Types.CreateCertificationMutation>;
export type CreateCertificationMutationOptions = Apollo.BaseMutationOptions<Types.CreateCertificationMutation, Types.CreateCertificationMutationVariables>;
export type DeleteCertificationMutationFn = Apollo.MutationFunction<Types.DeleteCertificationMutation, Types.DeleteCertificationMutationVariables>;

/**
 * __useDeleteCertificationMutation__
 *
 * To run a mutation, you first call `useDeleteCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificationMutation, { data, loading, error }] = useDeleteCertificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCertificationMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCertificationMutation, Types.DeleteCertificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCertificationMutation, Types.DeleteCertificationMutationVariables>(Operations.DeleteCertificationDocument, options);
      }
export type DeleteCertificationMutationHookResult = ReturnType<typeof useDeleteCertificationMutation>;
export type DeleteCertificationMutationResult = Apollo.MutationResult<Types.DeleteCertificationMutation>;
export type DeleteCertificationMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCertificationMutation, Types.DeleteCertificationMutationVariables>;

/**
 * __useUserFeedbackQuery__
 *
 * To run a query within a React component, call `useUserFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedbackQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useUserFeedbackQuery(baseOptions: Apollo.QueryHookOptions<Types.UserFeedbackQuery, Types.UserFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UserFeedbackQuery, Types.UserFeedbackQueryVariables>(Operations.UserFeedbackDocument, options);
      }
export function useUserFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UserFeedbackQuery, Types.UserFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UserFeedbackQuery, Types.UserFeedbackQueryVariables>(Operations.UserFeedbackDocument, options);
        }
export type UserFeedbackQueryHookResult = ReturnType<typeof useUserFeedbackQuery>;
export type UserFeedbackLazyQueryHookResult = ReturnType<typeof useUserFeedbackLazyQuery>;
export type UserFeedbackQueryResult = Apollo.QueryResult<Types.UserFeedbackQuery, Types.UserFeedbackQueryVariables>;

/**
 * __useIndustryQuery__
 *
 * To run a query within a React component, call `useIndustryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIndustryQuery(baseOptions: Apollo.QueryHookOptions<Types.IndustryQuery, Types.IndustryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndustryQuery, Types.IndustryQueryVariables>(Operations.IndustryDocument, options);
      }
export function useIndustryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndustryQuery, Types.IndustryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndustryQuery, Types.IndustryQueryVariables>(Operations.IndustryDocument, options);
        }
export type IndustryQueryHookResult = ReturnType<typeof useIndustryQuery>;
export type IndustryLazyQueryHookResult = ReturnType<typeof useIndustryLazyQuery>;
export type IndustryQueryResult = Apollo.QueryResult<Types.IndustryQuery, Types.IndustryQueryVariables>;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(Operations.IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IndustriesQuery, Types.IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IndustriesQuery, Types.IndustriesQueryVariables>(Operations.IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<Types.IndustriesQuery, Types.IndustriesQueryVariables>;
export type CreateIndustryMutationFn = Apollo.MutationFunction<Types.CreateIndustryMutation, Types.CreateIndustryMutationVariables>;

/**
 * __useCreateIndustryMutation__
 *
 * To run a mutation, you first call `useCreateIndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndustryMutation, { data, loading, error }] = useCreateIndustryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateIndustryMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateIndustryMutation, Types.CreateIndustryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateIndustryMutation, Types.CreateIndustryMutationVariables>(Operations.CreateIndustryDocument, options);
      }
export type CreateIndustryMutationHookResult = ReturnType<typeof useCreateIndustryMutation>;
export type CreateIndustryMutationResult = Apollo.MutationResult<Types.CreateIndustryMutation>;
export type CreateIndustryMutationOptions = Apollo.BaseMutationOptions<Types.CreateIndustryMutation, Types.CreateIndustryMutationVariables>;
export type ActivateIndustryMutationFn = Apollo.MutationFunction<Types.ActivateIndustryMutation, Types.ActivateIndustryMutationVariables>;

/**
 * __useActivateIndustryMutation__
 *
 * To run a mutation, you first call `useActivateIndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateIndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateIndustryMutation, { data, loading, error }] = useActivateIndustryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateIndustryMutation(baseOptions?: Apollo.MutationHookOptions<Types.ActivateIndustryMutation, Types.ActivateIndustryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ActivateIndustryMutation, Types.ActivateIndustryMutationVariables>(Operations.ActivateIndustryDocument, options);
      }
export type ActivateIndustryMutationHookResult = ReturnType<typeof useActivateIndustryMutation>;
export type ActivateIndustryMutationResult = Apollo.MutationResult<Types.ActivateIndustryMutation>;
export type ActivateIndustryMutationOptions = Apollo.BaseMutationOptions<Types.ActivateIndustryMutation, Types.ActivateIndustryMutationVariables>;

/**
 * __useUnresolvedIssuesQuery__
 *
 * To run a query within a React component, call `useUnresolvedIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnresolvedIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnresolvedIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnresolvedIssuesQuery(baseOptions?: Apollo.QueryHookOptions<Types.UnresolvedIssuesQuery, Types.UnresolvedIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnresolvedIssuesQuery, Types.UnresolvedIssuesQueryVariables>(Operations.UnresolvedIssuesDocument, options);
      }
export function useUnresolvedIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnresolvedIssuesQuery, Types.UnresolvedIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnresolvedIssuesQuery, Types.UnresolvedIssuesQueryVariables>(Operations.UnresolvedIssuesDocument, options);
        }
export type UnresolvedIssuesQueryHookResult = ReturnType<typeof useUnresolvedIssuesQuery>;
export type UnresolvedIssuesLazyQueryHookResult = ReturnType<typeof useUnresolvedIssuesLazyQuery>;
export type UnresolvedIssuesQueryResult = Apollo.QueryResult<Types.UnresolvedIssuesQuery, Types.UnresolvedIssuesQueryVariables>;

/**
 * __useResolvedIssuesQuery__
 *
 * To run a query within a React component, call `useResolvedIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolvedIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolvedIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResolvedIssuesQuery(baseOptions?: Apollo.QueryHookOptions<Types.ResolvedIssuesQuery, Types.ResolvedIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ResolvedIssuesQuery, Types.ResolvedIssuesQueryVariables>(Operations.ResolvedIssuesDocument, options);
      }
export function useResolvedIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ResolvedIssuesQuery, Types.ResolvedIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ResolvedIssuesQuery, Types.ResolvedIssuesQueryVariables>(Operations.ResolvedIssuesDocument, options);
        }
export type ResolvedIssuesQueryHookResult = ReturnType<typeof useResolvedIssuesQuery>;
export type ResolvedIssuesLazyQueryHookResult = ReturnType<typeof useResolvedIssuesLazyQuery>;
export type ResolvedIssuesQueryResult = Apollo.QueryResult<Types.ResolvedIssuesQuery, Types.ResolvedIssuesQueryVariables>;
export type ResolveIssueMutationFn = Apollo.MutationFunction<Types.ResolveIssueMutation, Types.ResolveIssueMutationVariables>;

/**
 * __useResolveIssueMutation__
 *
 * To run a mutation, you first call `useResolveIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveIssueMutation, { data, loading, error }] = useResolveIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResolveIssueMutation(baseOptions?: Apollo.MutationHookOptions<Types.ResolveIssueMutation, Types.ResolveIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ResolveIssueMutation, Types.ResolveIssueMutationVariables>(Operations.ResolveIssueDocument, options);
      }
export type ResolveIssueMutationHookResult = ReturnType<typeof useResolveIssueMutation>;
export type ResolveIssueMutationResult = Apollo.MutationResult<Types.ResolveIssueMutation>;
export type ResolveIssueMutationOptions = Apollo.BaseMutationOptions<Types.ResolveIssueMutation, Types.ResolveIssueMutationVariables>;
export type UnresolveIssueMutationFn = Apollo.MutationFunction<Types.UnresolveIssueMutation, Types.UnresolveIssueMutationVariables>;

/**
 * __useUnresolveIssueMutation__
 *
 * To run a mutation, you first call `useUnresolveIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnresolveIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unresolveIssueMutation, { data, loading, error }] = useUnresolveIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnresolveIssueMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnresolveIssueMutation, Types.UnresolveIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnresolveIssueMutation, Types.UnresolveIssueMutationVariables>(Operations.UnresolveIssueDocument, options);
      }
export type UnresolveIssueMutationHookResult = ReturnType<typeof useUnresolveIssueMutation>;
export type UnresolveIssueMutationResult = Apollo.MutationResult<Types.UnresolveIssueMutation>;
export type UnresolveIssueMutationOptions = Apollo.BaseMutationOptions<Types.UnresolveIssueMutation, Types.UnresolveIssueMutationVariables>;

/**
 * __useJobBasicQuery__
 *
 * To run a query within a React component, call `useJobBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.JobBasicQuery, Types.JobBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobBasicQuery, Types.JobBasicQueryVariables>(Operations.JobBasicDocument, options);
      }
export function useJobBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobBasicQuery, Types.JobBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobBasicQuery, Types.JobBasicQueryVariables>(Operations.JobBasicDocument, options);
        }
export type JobBasicQueryHookResult = ReturnType<typeof useJobBasicQuery>;
export type JobBasicLazyQueryHookResult = ReturnType<typeof useJobBasicLazyQuery>;
export type JobBasicQueryResult = Apollo.QueryResult<Types.JobBasicQuery, Types.JobBasicQueryVariables>;

/**
 * __useJobWithCompanyQuery__
 *
 * To run a query within a React component, call `useJobWithCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobWithCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobWithCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobWithCompanyQuery(baseOptions: Apollo.QueryHookOptions<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>(Operations.JobWithCompanyDocument, options);
      }
export function useJobWithCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>(Operations.JobWithCompanyDocument, options);
        }
export type JobWithCompanyQueryHookResult = ReturnType<typeof useJobWithCompanyQuery>;
export type JobWithCompanyLazyQueryHookResult = ReturnType<typeof useJobWithCompanyLazyQuery>;
export type JobWithCompanyQueryResult = Apollo.QueryResult<Types.JobWithCompanyQuery, Types.JobWithCompanyQueryVariables>;

/**
 * __useMatchedJobsQuery__
 *
 * To run a query within a React component, call `useMatchedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatchedJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>(Operations.MatchedJobsDocument, options);
      }
export function useMatchedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>(Operations.MatchedJobsDocument, options);
        }
export type MatchedJobsQueryHookResult = ReturnType<typeof useMatchedJobsQuery>;
export type MatchedJobsLazyQueryHookResult = ReturnType<typeof useMatchedJobsLazyQuery>;
export type MatchedJobsQueryResult = Apollo.QueryResult<Types.MatchedJobsQuery, Types.MatchedJobsQueryVariables>;

/**
 * __useMatchedOrPausedJobsQuery__
 *
 * To run a query within a React component, call `useMatchedOrPausedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchedOrPausedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchedOrPausedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMatchedOrPausedJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MatchedOrPausedJobsQuery, Types.MatchedOrPausedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MatchedOrPausedJobsQuery, Types.MatchedOrPausedJobsQueryVariables>(Operations.MatchedOrPausedJobsDocument, options);
      }
export function useMatchedOrPausedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MatchedOrPausedJobsQuery, Types.MatchedOrPausedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MatchedOrPausedJobsQuery, Types.MatchedOrPausedJobsQueryVariables>(Operations.MatchedOrPausedJobsDocument, options);
        }
export type MatchedOrPausedJobsQueryHookResult = ReturnType<typeof useMatchedOrPausedJobsQuery>;
export type MatchedOrPausedJobsLazyQueryHookResult = ReturnType<typeof useMatchedOrPausedJobsLazyQuery>;
export type MatchedOrPausedJobsQueryResult = Apollo.QueryResult<Types.MatchedOrPausedJobsQuery, Types.MatchedOrPausedJobsQueryVariables>;

/**
 * __usePostedJobsQuery__
 *
 * To run a query within a React component, call `usePostedJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostedJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostedJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostedJobsQuery(baseOptions?: Apollo.QueryHookOptions<Types.PostedJobsQuery, Types.PostedJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PostedJobsQuery, Types.PostedJobsQueryVariables>(Operations.PostedJobsDocument, options);
      }
export function usePostedJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PostedJobsQuery, Types.PostedJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PostedJobsQuery, Types.PostedJobsQueryVariables>(Operations.PostedJobsDocument, options);
        }
export type PostedJobsQueryHookResult = ReturnType<typeof usePostedJobsQuery>;
export type PostedJobsLazyQueryHookResult = ReturnType<typeof usePostedJobsLazyQuery>;
export type PostedJobsQueryResult = Apollo.QueryResult<Types.PostedJobsQuery, Types.PostedJobsQueryVariables>;
export type ApproveJobMutationFn = Apollo.MutationFunction<Types.ApproveJobMutation, Types.ApproveJobMutationVariables>;

/**
 * __useApproveJobMutation__
 *
 * To run a mutation, you first call `useApproveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveJobMutation, { data, loading, error }] = useApproveJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.ApproveJobMutation, Types.ApproveJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ApproveJobMutation, Types.ApproveJobMutationVariables>(Operations.ApproveJobDocument, options);
      }
export type ApproveJobMutationHookResult = ReturnType<typeof useApproveJobMutation>;
export type ApproveJobMutationResult = Apollo.MutationResult<Types.ApproveJobMutation>;
export type ApproveJobMutationOptions = Apollo.BaseMutationOptions<Types.ApproveJobMutation, Types.ApproveJobMutationVariables>;
export type DisapproveJobMutationFn = Apollo.MutationFunction<Types.DisapproveJobMutation, Types.DisapproveJobMutationVariables>;

/**
 * __useDisapproveJobMutation__
 *
 * To run a mutation, you first call `useDisapproveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisapproveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disapproveJobMutation, { data, loading, error }] = useDisapproveJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisapproveJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.DisapproveJobMutation, Types.DisapproveJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DisapproveJobMutation, Types.DisapproveJobMutationVariables>(Operations.DisapproveJobDocument, options);
      }
export type DisapproveJobMutationHookResult = ReturnType<typeof useDisapproveJobMutation>;
export type DisapproveJobMutationResult = Apollo.MutationResult<Types.DisapproveJobMutation>;
export type DisapproveJobMutationOptions = Apollo.BaseMutationOptions<Types.DisapproveJobMutation, Types.DisapproveJobMutationVariables>;
export type PauseJobMutationFn = Apollo.MutationFunction<Types.PauseJobMutation, Types.PauseJobMutationVariables>;

/**
 * __usePauseJobMutation__
 *
 * To run a mutation, you first call `usePauseJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseJobMutation, { data, loading, error }] = usePauseJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePauseJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.PauseJobMutation, Types.PauseJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PauseJobMutation, Types.PauseJobMutationVariables>(Operations.PauseJobDocument, options);
      }
export type PauseJobMutationHookResult = ReturnType<typeof usePauseJobMutation>;
export type PauseJobMutationResult = Apollo.MutationResult<Types.PauseJobMutation>;
export type PauseJobMutationOptions = Apollo.BaseMutationOptions<Types.PauseJobMutation, Types.PauseJobMutationVariables>;
export type UnpauseJobMutationFn = Apollo.MutationFunction<Types.UnpauseJobMutation, Types.UnpauseJobMutationVariables>;

/**
 * __useUnpauseJobMutation__
 *
 * To run a mutation, you first call `useUnpauseJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpauseJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpauseJobMutation, { data, loading, error }] = useUnpauseJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpauseJobMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnpauseJobMutation, Types.UnpauseJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnpauseJobMutation, Types.UnpauseJobMutationVariables>(Operations.UnpauseJobDocument, options);
      }
export type UnpauseJobMutationHookResult = ReturnType<typeof useUnpauseJobMutation>;
export type UnpauseJobMutationResult = Apollo.MutationResult<Types.UnpauseJobMutation>;
export type UnpauseJobMutationOptions = Apollo.BaseMutationOptions<Types.UnpauseJobMutation, Types.UnpauseJobMutationVariables>;

/**
 * __useJobTemplateBasicQuery__
 *
 * To run a query within a React component, call `useJobTemplateBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTemplateBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTemplateBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobTemplateBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.JobTemplateBasicQuery, Types.JobTemplateBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobTemplateBasicQuery, Types.JobTemplateBasicQueryVariables>(Operations.JobTemplateBasicDocument, options);
      }
export function useJobTemplateBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobTemplateBasicQuery, Types.JobTemplateBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobTemplateBasicQuery, Types.JobTemplateBasicQueryVariables>(Operations.JobTemplateBasicDocument, options);
        }
export type JobTemplateBasicQueryHookResult = ReturnType<typeof useJobTemplateBasicQuery>;
export type JobTemplateBasicLazyQueryHookResult = ReturnType<typeof useJobTemplateBasicLazyQuery>;
export type JobTemplateBasicQueryResult = Apollo.QueryResult<Types.JobTemplateBasicQuery, Types.JobTemplateBasicQueryVariables>;

/**
 * __useJobTemplateAllQuery__
 *
 * To run a query within a React component, call `useJobTemplateAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTemplateAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTemplateAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobTemplateAllQuery(baseOptions?: Apollo.QueryHookOptions<Types.JobTemplateAllQuery, Types.JobTemplateAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.JobTemplateAllQuery, Types.JobTemplateAllQueryVariables>(Operations.JobTemplateAllDocument, options);
      }
export function useJobTemplateAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.JobTemplateAllQuery, Types.JobTemplateAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.JobTemplateAllQuery, Types.JobTemplateAllQueryVariables>(Operations.JobTemplateAllDocument, options);
        }
export type JobTemplateAllQueryHookResult = ReturnType<typeof useJobTemplateAllQuery>;
export type JobTemplateAllLazyQueryHookResult = ReturnType<typeof useJobTemplateAllLazyQuery>;
export type JobTemplateAllQueryResult = Apollo.QueryResult<Types.JobTemplateAllQuery, Types.JobTemplateAllQueryVariables>;
export type CreateJobTemplateMutationFn = Apollo.MutationFunction<Types.CreateJobTemplateMutation, Types.CreateJobTemplateMutationVariables>;

/**
 * __useCreateJobTemplateMutation__
 *
 * To run a mutation, you first call `useCreateJobTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobTemplateMutation, { data, loading, error }] = useCreateJobTemplateMutation({
 *   variables: {
 *      jobTemplateFields: // value for 'jobTemplateFields'
 *   },
 * });
 */
export function useCreateJobTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateJobTemplateMutation, Types.CreateJobTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateJobTemplateMutation, Types.CreateJobTemplateMutationVariables>(Operations.CreateJobTemplateDocument, options);
      }
export type CreateJobTemplateMutationHookResult = ReturnType<typeof useCreateJobTemplateMutation>;
export type CreateJobTemplateMutationResult = Apollo.MutationResult<Types.CreateJobTemplateMutation>;
export type CreateJobTemplateMutationOptions = Apollo.BaseMutationOptions<Types.CreateJobTemplateMutation, Types.CreateJobTemplateMutationVariables>;
export type UpdateJobTemplateMutationFn = Apollo.MutationFunction<Types.UpdateJobTemplateMutation, Types.UpdateJobTemplateMutationVariables>;

/**
 * __useUpdateJobTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateJobTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobTemplateMutation, { data, loading, error }] = useUpdateJobTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      jobTemplateFields: // value for 'jobTemplateFields'
 *   },
 * });
 */
export function useUpdateJobTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateJobTemplateMutation, Types.UpdateJobTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateJobTemplateMutation, Types.UpdateJobTemplateMutationVariables>(Operations.UpdateJobTemplateDocument, options);
      }
export type UpdateJobTemplateMutationHookResult = ReturnType<typeof useUpdateJobTemplateMutation>;
export type UpdateJobTemplateMutationResult = Apollo.MutationResult<Types.UpdateJobTemplateMutation>;
export type UpdateJobTemplateMutationOptions = Apollo.BaseMutationOptions<Types.UpdateJobTemplateMutation, Types.UpdateJobTemplateMutationVariables>;
export type DeleteJobTemplateMutationFn = Apollo.MutationFunction<Types.DeleteJobTemplateMutation, Types.DeleteJobTemplateMutationVariables>;

/**
 * __useDeleteJobTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteJobTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobTemplateMutation, { data, loading, error }] = useDeleteJobTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobTemplateMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteJobTemplateMutation, Types.DeleteJobTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteJobTemplateMutation, Types.DeleteJobTemplateMutationVariables>(Operations.DeleteJobTemplateDocument, options);
      }
export type DeleteJobTemplateMutationHookResult = ReturnType<typeof useDeleteJobTemplateMutation>;
export type DeleteJobTemplateMutationResult = Apollo.MutationResult<Types.DeleteJobTemplateMutation>;
export type DeleteJobTemplateMutationOptions = Apollo.BaseMutationOptions<Types.DeleteJobTemplateMutation, Types.DeleteJobTemplateMutationVariables>;

/**
 * __usePositionBasicQuery__
 *
 * To run a query within a React component, call `usePositionBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePositionBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>(Operations.PositionBasicDocument, options);
      }
export function usePositionBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>(Operations.PositionBasicDocument, options);
        }
export type PositionBasicQueryHookResult = ReturnType<typeof usePositionBasicQuery>;
export type PositionBasicLazyQueryHookResult = ReturnType<typeof usePositionBasicLazyQuery>;
export type PositionBasicQueryResult = Apollo.QueryResult<Types.PositionBasicQuery, Types.PositionBasicQueryVariables>;

/**
 * __usePositionAllQuery__
 *
 * To run a query within a React component, call `usePositionAllQuery` and pass it any options that fit your needs.
 * When your component renders, `usePositionAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePositionAllQuery({
 *   variables: {
 *   },
 * });
 */
export function usePositionAllQuery(baseOptions?: Apollo.QueryHookOptions<Types.PositionAllQuery, Types.PositionAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PositionAllQuery, Types.PositionAllQueryVariables>(Operations.PositionAllDocument, options);
      }
export function usePositionAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PositionAllQuery, Types.PositionAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PositionAllQuery, Types.PositionAllQueryVariables>(Operations.PositionAllDocument, options);
        }
export type PositionAllQueryHookResult = ReturnType<typeof usePositionAllQuery>;
export type PositionAllLazyQueryHookResult = ReturnType<typeof usePositionAllLazyQuery>;
export type PositionAllQueryResult = Apollo.QueryResult<Types.PositionAllQuery, Types.PositionAllQueryVariables>;
export type CreatePositionMutationFn = Apollo.MutationFunction<Types.CreatePositionMutation, Types.CreatePositionMutationVariables>;

/**
 * __useCreatePositionMutation__
 *
 * To run a mutation, you first call `useCreatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPositionMutation, { data, loading, error }] = useCreatePositionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      industries: // value for 'industries'
 *   },
 * });
 */
export function useCreatePositionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePositionMutation, Types.CreatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePositionMutation, Types.CreatePositionMutationVariables>(Operations.CreatePositionDocument, options);
      }
export type CreatePositionMutationHookResult = ReturnType<typeof useCreatePositionMutation>;
export type CreatePositionMutationResult = Apollo.MutationResult<Types.CreatePositionMutation>;
export type CreatePositionMutationOptions = Apollo.BaseMutationOptions<Types.CreatePositionMutation, Types.CreatePositionMutationVariables>;
export type DeletePositionMutationFn = Apollo.MutationFunction<Types.DeletePositionMutation, Types.DeletePositionMutationVariables>;

/**
 * __useDeletePositionMutation__
 *
 * To run a mutation, you first call `useDeletePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePositionMutation, { data, loading, error }] = useDeletePositionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePositionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePositionMutation, Types.DeletePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePositionMutation, Types.DeletePositionMutationVariables>(Operations.DeletePositionDocument, options);
      }
export type DeletePositionMutationHookResult = ReturnType<typeof useDeletePositionMutation>;
export type DeletePositionMutationResult = Apollo.MutationResult<Types.DeletePositionMutation>;
export type DeletePositionMutationOptions = Apollo.BaseMutationOptions<Types.DeletePositionMutation, Types.DeletePositionMutationVariables>;

/**
 * __useOptionGroupsBaseFieldsQuery__
 *
 * To run a query within a React component, call `useOptionGroupsBaseFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionGroupsBaseFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionGroupsBaseFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptionGroupsBaseFieldsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OptionGroupsBaseFieldsQuery, Types.OptionGroupsBaseFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OptionGroupsBaseFieldsQuery, Types.OptionGroupsBaseFieldsQueryVariables>(Operations.OptionGroupsBaseFieldsDocument, options);
      }
export function useOptionGroupsBaseFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OptionGroupsBaseFieldsQuery, Types.OptionGroupsBaseFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OptionGroupsBaseFieldsQuery, Types.OptionGroupsBaseFieldsQueryVariables>(Operations.OptionGroupsBaseFieldsDocument, options);
        }
export type OptionGroupsBaseFieldsQueryHookResult = ReturnType<typeof useOptionGroupsBaseFieldsQuery>;
export type OptionGroupsBaseFieldsLazyQueryHookResult = ReturnType<typeof useOptionGroupsBaseFieldsLazyQuery>;
export type OptionGroupsBaseFieldsQueryResult = Apollo.QueryResult<Types.OptionGroupsBaseFieldsQuery, Types.OptionGroupsBaseFieldsQueryVariables>;

/**
 * __useOptionGroupsQuery__
 *
 * To run a query within a React component, call `useOptionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptionGroupsQuery(baseOptions?: Apollo.QueryHookOptions<Types.OptionGroupsQuery, Types.OptionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OptionGroupsQuery, Types.OptionGroupsQueryVariables>(Operations.OptionGroupsDocument, options);
      }
export function useOptionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OptionGroupsQuery, Types.OptionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OptionGroupsQuery, Types.OptionGroupsQueryVariables>(Operations.OptionGroupsDocument, options);
        }
export type OptionGroupsQueryHookResult = ReturnType<typeof useOptionGroupsQuery>;
export type OptionGroupsLazyQueryHookResult = ReturnType<typeof useOptionGroupsLazyQuery>;
export type OptionGroupsQueryResult = Apollo.QueryResult<Types.OptionGroupsQuery, Types.OptionGroupsQueryVariables>;
export type CreateOptionGroupMutationFn = Apollo.MutationFunction<Types.CreateOptionGroupMutation, Types.CreateOptionGroupMutationVariables>;

/**
 * __useCreateOptionGroupMutation__
 *
 * To run a mutation, you first call `useCreateOptionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionGroupMutation, { data, loading, error }] = useCreateOptionGroupMutation({
 *   variables: {
 *      optionGroupFields: // value for 'optionGroupFields'
 *   },
 * });
 */
export function useCreateOptionGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateOptionGroupMutation, Types.CreateOptionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateOptionGroupMutation, Types.CreateOptionGroupMutationVariables>(Operations.CreateOptionGroupDocument, options);
      }
export type CreateOptionGroupMutationHookResult = ReturnType<typeof useCreateOptionGroupMutation>;
export type CreateOptionGroupMutationResult = Apollo.MutationResult<Types.CreateOptionGroupMutation>;
export type CreateOptionGroupMutationOptions = Apollo.BaseMutationOptions<Types.CreateOptionGroupMutation, Types.CreateOptionGroupMutationVariables>;
export type DeleteOptionGroupMutationFn = Apollo.MutationFunction<Types.DeleteOptionGroupMutation, Types.DeleteOptionGroupMutationVariables>;

/**
 * __useDeleteOptionGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOptionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionGroupMutation, { data, loading, error }] = useDeleteOptionGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOptionGroupMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteOptionGroupMutation, Types.DeleteOptionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteOptionGroupMutation, Types.DeleteOptionGroupMutationVariables>(Operations.DeleteOptionGroupDocument, options);
      }
export type DeleteOptionGroupMutationHookResult = ReturnType<typeof useDeleteOptionGroupMutation>;
export type DeleteOptionGroupMutationResult = Apollo.MutationResult<Types.DeleteOptionGroupMutation>;
export type DeleteOptionGroupMutationOptions = Apollo.BaseMutationOptions<Types.DeleteOptionGroupMutation, Types.DeleteOptionGroupMutationVariables>;

/**
 * __useQuestionsWithVariablesQuery__
 *
 * To run a query within a React component, call `useQuestionsWithVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsWithVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsWithVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionsWithVariablesQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionsWithVariablesQuery, Types.QuestionsWithVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionsWithVariablesQuery, Types.QuestionsWithVariablesQueryVariables>(Operations.QuestionsWithVariablesDocument, options);
      }
export function useQuestionsWithVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionsWithVariablesQuery, Types.QuestionsWithVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionsWithVariablesQuery, Types.QuestionsWithVariablesQueryVariables>(Operations.QuestionsWithVariablesDocument, options);
        }
export type QuestionsWithVariablesQueryHookResult = ReturnType<typeof useQuestionsWithVariablesQuery>;
export type QuestionsWithVariablesLazyQueryHookResult = ReturnType<typeof useQuestionsWithVariablesLazyQuery>;
export type QuestionsWithVariablesQueryResult = Apollo.QueryResult<Types.QuestionsWithVariablesQuery, Types.QuestionsWithVariablesQueryVariables>;
export type CreateQuestionMutationFn = Apollo.MutationFunction<Types.CreateQuestionMutation, Types.CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      questionFields: // value for 'questionFields'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuestionMutation, Types.CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuestionMutation, Types.CreateQuestionMutationVariables>(Operations.CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<Types.CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuestionMutation, Types.CreateQuestionMutationVariables>;
export type CreateQuestionsMutationFn = Apollo.MutationFunction<Types.CreateQuestionsMutation, Types.CreateQuestionsMutationVariables>;

/**
 * __useCreateQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionsMutation, { data, loading, error }] = useCreateQuestionsMutation({
 *   variables: {
 *      questionsFields: // value for 'questionsFields'
 *   },
 * });
 */
export function useCreateQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuestionsMutation, Types.CreateQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuestionsMutation, Types.CreateQuestionsMutationVariables>(Operations.CreateQuestionsDocument, options);
      }
export type CreateQuestionsMutationHookResult = ReturnType<typeof useCreateQuestionsMutation>;
export type CreateQuestionsMutationResult = Apollo.MutationResult<Types.CreateQuestionsMutation>;
export type CreateQuestionsMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuestionsMutation, Types.CreateQuestionsMutationVariables>;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>(Operations.QuestionnairesDocument, options);
      }
export function useQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>(Operations.QuestionnairesDocument, options);
        }
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<Types.QuestionnairesQuery, Types.QuestionnairesQueryVariables>;
export type CreateQuestionnaireMutationFn = Apollo.MutationFunction<Types.CreateQuestionnaireMutation, Types.CreateQuestionnaireMutationVariables>;

/**
 * __useCreateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireMutation, { data, loading, error }] = useCreateQuestionnaireMutation({
 *   variables: {
 *      questionnaireFields: // value for 'questionnaireFields'
 *   },
 * });
 */
export function useCreateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuestionnaireMutation, Types.CreateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuestionnaireMutation, Types.CreateQuestionnaireMutationVariables>(Operations.CreateQuestionnaireDocument, options);
      }
export type CreateQuestionnaireMutationHookResult = ReturnType<typeof useCreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationResult = Apollo.MutationResult<Types.CreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuestionnaireMutation, Types.CreateQuestionnaireMutationVariables>;

/**
 * __useQuestionnaireVariablesQuery__
 *
 * To run a query within a React component, call `useQuestionnaireVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnaireVariablesQuery(baseOptions?: Apollo.QueryHookOptions<Types.QuestionnaireVariablesQuery, Types.QuestionnaireVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionnaireVariablesQuery, Types.QuestionnaireVariablesQueryVariables>(Operations.QuestionnaireVariablesDocument, options);
      }
export function useQuestionnaireVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionnaireVariablesQuery, Types.QuestionnaireVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionnaireVariablesQuery, Types.QuestionnaireVariablesQueryVariables>(Operations.QuestionnaireVariablesDocument, options);
        }
export type QuestionnaireVariablesQueryHookResult = ReturnType<typeof useQuestionnaireVariablesQuery>;
export type QuestionnaireVariablesLazyQueryHookResult = ReturnType<typeof useQuestionnaireVariablesLazyQuery>;
export type QuestionnaireVariablesQueryResult = Apollo.QueryResult<Types.QuestionnaireVariablesQuery, Types.QuestionnaireVariablesQueryVariables>;
export type CreateQuestionnaireVariableMutationFn = Apollo.MutationFunction<Types.CreateQuestionnaireVariableMutation, Types.CreateQuestionnaireVariableMutationVariables>;

/**
 * __useCreateQuestionnaireVariableMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireVariableMutation, { data, loading, error }] = useCreateQuestionnaireVariableMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateQuestionnaireVariableMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateQuestionnaireVariableMutation, Types.CreateQuestionnaireVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateQuestionnaireVariableMutation, Types.CreateQuestionnaireVariableMutationVariables>(Operations.CreateQuestionnaireVariableDocument, options);
      }
export type CreateQuestionnaireVariableMutationHookResult = ReturnType<typeof useCreateQuestionnaireVariableMutation>;
export type CreateQuestionnaireVariableMutationResult = Apollo.MutationResult<Types.CreateQuestionnaireVariableMutation>;
export type CreateQuestionnaireVariableMutationOptions = Apollo.BaseMutationOptions<Types.CreateQuestionnaireVariableMutation, Types.CreateQuestionnaireVariableMutationVariables>;
export type DeleteQuestionnaireVariableMutationFn = Apollo.MutationFunction<Types.DeleteQuestionnaireVariableMutation, Types.DeleteQuestionnaireVariableMutationVariables>;

/**
 * __useDeleteQuestionnaireVariableMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireVariableMutation, { data, loading, error }] = useDeleteQuestionnaireVariableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireVariableMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteQuestionnaireVariableMutation, Types.DeleteQuestionnaireVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteQuestionnaireVariableMutation, Types.DeleteQuestionnaireVariableMutationVariables>(Operations.DeleteQuestionnaireVariableDocument, options);
      }
export type DeleteQuestionnaireVariableMutationHookResult = ReturnType<typeof useDeleteQuestionnaireVariableMutation>;
export type DeleteQuestionnaireVariableMutationResult = Apollo.MutationResult<Types.DeleteQuestionnaireVariableMutation>;
export type DeleteQuestionnaireVariableMutationOptions = Apollo.BaseMutationOptions<Types.DeleteQuestionnaireVariableMutation, Types.DeleteQuestionnaireVariableMutationVariables>;

/**
 * __useUnverifiedRecruitersQuery__
 *
 * To run a query within a React component, call `useUnverifiedRecruitersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnverifiedRecruitersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnverifiedRecruitersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnverifiedRecruitersQuery(baseOptions?: Apollo.QueryHookOptions<Types.UnverifiedRecruitersQuery, Types.UnverifiedRecruitersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.UnverifiedRecruitersQuery, Types.UnverifiedRecruitersQueryVariables>(Operations.UnverifiedRecruitersDocument, options);
      }
export function useUnverifiedRecruitersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.UnverifiedRecruitersQuery, Types.UnverifiedRecruitersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.UnverifiedRecruitersQuery, Types.UnverifiedRecruitersQueryVariables>(Operations.UnverifiedRecruitersDocument, options);
        }
export type UnverifiedRecruitersQueryHookResult = ReturnType<typeof useUnverifiedRecruitersQuery>;
export type UnverifiedRecruitersLazyQueryHookResult = ReturnType<typeof useUnverifiedRecruitersLazyQuery>;
export type UnverifiedRecruitersQueryResult = Apollo.QueryResult<Types.UnverifiedRecruitersQuery, Types.UnverifiedRecruitersQueryVariables>;
export type VerifyRecruiterMutationFn = Apollo.MutationFunction<Types.VerifyRecruiterMutation, Types.VerifyRecruiterMutationVariables>;

/**
 * __useVerifyRecruiterMutation__
 *
 * To run a mutation, you first call `useVerifyRecruiterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyRecruiterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyRecruiterMutation, { data, loading, error }] = useVerifyRecruiterMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyRecruiterMutation(baseOptions?: Apollo.MutationHookOptions<Types.VerifyRecruiterMutation, Types.VerifyRecruiterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.VerifyRecruiterMutation, Types.VerifyRecruiterMutationVariables>(Operations.VerifyRecruiterDocument, options);
      }
export type VerifyRecruiterMutationHookResult = ReturnType<typeof useVerifyRecruiterMutation>;
export type VerifyRecruiterMutationResult = Apollo.MutationResult<Types.VerifyRecruiterMutation>;
export type VerifyRecruiterMutationOptions = Apollo.BaseMutationOptions<Types.VerifyRecruiterMutation, Types.VerifyRecruiterMutationVariables>;

/**
 * __useSkillBasicQuery__
 *
 * To run a query within a React component, call `useSkillBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSkillBasicQuery(baseOptions: Apollo.QueryHookOptions<Types.SkillBasicQuery, Types.SkillBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkillBasicQuery, Types.SkillBasicQueryVariables>(Operations.SkillBasicDocument, options);
      }
export function useSkillBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkillBasicQuery, Types.SkillBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkillBasicQuery, Types.SkillBasicQueryVariables>(Operations.SkillBasicDocument, options);
        }
export type SkillBasicQueryHookResult = ReturnType<typeof useSkillBasicQuery>;
export type SkillBasicLazyQueryHookResult = ReturnType<typeof useSkillBasicLazyQuery>;
export type SkillBasicQueryResult = Apollo.QueryResult<Types.SkillBasicQuery, Types.SkillBasicQueryVariables>;

/**
 * __useSkillAllQuery__
 *
 * To run a query within a React component, call `useSkillAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillAllQuery(baseOptions?: Apollo.QueryHookOptions<Types.SkillAllQuery, Types.SkillAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SkillAllQuery, Types.SkillAllQueryVariables>(Operations.SkillAllDocument, options);
      }
export function useSkillAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SkillAllQuery, Types.SkillAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SkillAllQuery, Types.SkillAllQueryVariables>(Operations.SkillAllDocument, options);
        }
export type SkillAllQueryHookResult = ReturnType<typeof useSkillAllQuery>;
export type SkillAllLazyQueryHookResult = ReturnType<typeof useSkillAllLazyQuery>;
export type SkillAllQueryResult = Apollo.QueryResult<Types.SkillAllQuery, Types.SkillAllQueryVariables>;
export type CreateSkillMutationFn = Apollo.MutationFunction<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>;

/**
 * __useCreateSkillMutation__
 *
 * To run a mutation, you first call `useCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillMutation, { data, loading, error }] = useCreateSkillMutation({
 *   variables: {
 *      skillFields: // value for 'skillFields'
 *   },
 * });
 */
export function useCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>(Operations.CreateSkillDocument, options);
      }
export type CreateSkillMutationHookResult = ReturnType<typeof useCreateSkillMutation>;
export type CreateSkillMutationResult = Apollo.MutationResult<Types.CreateSkillMutation>;
export type CreateSkillMutationOptions = Apollo.BaseMutationOptions<Types.CreateSkillMutation, Types.CreateSkillMutationVariables>;
export type CreateSkillsMutationFn = Apollo.MutationFunction<Types.CreateSkillsMutation, Types.CreateSkillsMutationVariables>;

/**
 * __useCreateSkillsMutation__
 *
 * To run a mutation, you first call `useCreateSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillsMutation, { data, loading, error }] = useCreateSkillsMutation({
 *   variables: {
 *      skillsFields: // value for 'skillsFields'
 *   },
 * });
 */
export function useCreateSkillsMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateSkillsMutation, Types.CreateSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateSkillsMutation, Types.CreateSkillsMutationVariables>(Operations.CreateSkillsDocument, options);
      }
export type CreateSkillsMutationHookResult = ReturnType<typeof useCreateSkillsMutation>;
export type CreateSkillsMutationResult = Apollo.MutationResult<Types.CreateSkillsMutation>;
export type CreateSkillsMutationOptions = Apollo.BaseMutationOptions<Types.CreateSkillsMutation, Types.CreateSkillsMutationVariables>;
export type DeleteSkillMutationFn = Apollo.MutationFunction<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>;

/**
 * __useDeleteSkillMutation__
 *
 * To run a mutation, you first call `useDeleteSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillMutation, { data, loading, error }] = useDeleteSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSkillMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>(Operations.DeleteSkillDocument, options);
      }
export type DeleteSkillMutationHookResult = ReturnType<typeof useDeleteSkillMutation>;
export type DeleteSkillMutationResult = Apollo.MutationResult<Types.DeleteSkillMutation>;
export type DeleteSkillMutationOptions = Apollo.BaseMutationOptions<Types.DeleteSkillMutation, Types.DeleteSkillMutationVariables>;