import { Visibility } from "@mui/icons-material";
import { FC, useState } from "react";
import GenericTable from "../../../components/table/GenericTable";
import { useQuestionnairesQuery } from "../../../generated/graphqlHooks";
import { Questionnaire } from "../../../generated/graphqlTypes";
import useModalState from "../../../hooks/useModalState";
import CreateQuestionnaireModal from "./CreateQuestionnaireModal";

const ManageQuestionnaires: FC = () => {
  const { data, loading } = useQuestionnairesQuery();
  const [open, handleOpen, handleClose] = useModalState();
  const [viewedOption, setViewedOption] = useState<Questionnaire>();

  return (
    <>
      <GenericTable
        title="Questionnaires"
        description="Questionnaires that help us match applicants"
        columns={[
          { field: "industryName", headerName: "Industry", width: 200 },
          { field: "userTarget", headerName: "User Target", width: 150 },
          {
            field: "singleVariables",
            valueGetter: ({ row: questionnaire }) =>
              questionnaire.singleVarConfigs?.map((variableConfig) => variableConfig.variable.name),
            width: 400
          },
          {
            field: "adhVariables",
            headerName: "ADH Variables",
            valueGetter: ({ row: questionnaire }) =>
              questionnaire.twoVariables?.map((variable) => variable.name),
            width: 400
          }
        ]}
        rows={data?.questionnaires}
        loading={loading}
        toolbarProps={{
          primaryButton: {
            text: "Create new questionnaire",
            onClick: handleOpen
          }
        }}
        actions={[
          {
            name: "View options",
            icon: Visibility,
            onClick: (questionnaire) => {
              setViewedOption(questionnaire as any);
              handleOpen();
            }
          }
        ]}
      />
      <CreateQuestionnaireModal
        open={open}
        handleClose={() => {
          handleClose();
          setTimeout(() => setViewedOption(undefined), 500);
        }}
        defaultValues={viewedOption}
      />
    </>
  );
};

export default ManageQuestionnaires;
