import { Delete, Visibility } from "@mui/icons-material";
import { updateCacheAfterRemove } from "@placehires/react-component-library";
import { FC, useState } from "react";
import GenericTable from "../../../components/table/GenericTable";
import {
  useDeleteOptionGroupMutation,
  useOptionGroupsQuery
} from "../../../generated/graphqlHooks";
import { OptionGroup, OptionGroupsDocument } from "../../../generated/graphqlTypes";
import { useConfirmDelete } from "../../../hooks/confirmHooks";
import useModalState from "../../../hooks/useModalState";
import CreateOrViewOptionGroupModal from "./CreateOrViewOptionGroupModal";

const ManageOptionGroups: FC = () => {
  const { data, loading } = useOptionGroupsQuery();
  const [deleteOptionGroup] = useDeleteOptionGroupMutation();
  const [open, handleOpen, handleClose] = useModalState();
  const [viewedOption, setViewedOption] = useState<OptionGroup>();
  const confirmDelete = useConfirmDelete();

  return (
    <>
      <GenericTable
        title="Questionnaire option groups"
        description="Groups of commonly used options for questions"
        columns={[
          "name",
          {
            field: "withScores",
            width: 154,
            type: "boolean"
          }
        ]}
        rows={data?.optionGroups}
        loading={loading}
        toolbarProps={{
          primaryButton: {
            text: "Create new option group",
            onClick: handleOpen
          }
        }}
        actions={[
          {
            name: "View options",
            icon: Visibility,
            onClick: (optionGroup) => {
              setViewedOption(optionGroup);
              handleOpen();
            }
          },
          {
            name: "Delete group",
            icon: Delete,
            onClick: (group) => {
              confirmDelete(() =>
                deleteOptionGroup({
                  variables: {
                    id: group._id
                  },
                  update: (cache) => {
                    updateCacheAfterRemove(cache, OptionGroupsDocument, group._id);
                  }
                }).catch((_) => {})
              );
            }
          }
        ]}
      />
      <CreateOrViewOptionGroupModal
        open={open}
        handleClose={() => {
          handleClose();
          setTimeout(() => setViewedOption(undefined), 500);
        }}
        defaultValues={viewedOption}
      />
    </>
  );
};

export default ManageOptionGroups;
