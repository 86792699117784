import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { alertAction, alertError } from "@placehires/react-component-library";
import { createUploadLink } from "apollo-upload-client";
import fetch from "isomorphic-fetch";
import omitDeep from "omit-deep-lodash";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { ErrorCode } from "../generated/graphqlTypes";
import { getUserToken, logout } from "../services/authService";

// Creating a mutation link that removes __typename from requests
const mutationLink = new ApolloLink((operation, forward) => {
  operation.variables = omitDeep(operation.variables, "__typename");
  return forward(operation);
});

// Creating auth link that attaches token to requests
const authLink = setContext(async (_) => {
  const token = await getUserToken();
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Creating http link for queries and mutations, with file uploads
const httpLinkWithUpload = createUploadLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}graphql`,
  credentials: "include",
  fetch
});

// Creating websocket client and link for subscriptions
const wsClient = new SubscriptionClient(process.env.REACT_APP_WEBSOCKET_URI as string, {
  lazy: true,
  reconnect: true,
  minTimeout: 3000,
  connectionParams: async () => {
    const authToken = await getUserToken();
    return { authToken };
  }
});
const wsLink = new WebSocketLink(wsClient);

// Creating a link based on operation
const operationBasedLink = split(
  // Split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLinkWithUpload
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const { message, extensions } = err;
      if (
        extensions &&
        extensions.code !== ErrorCode.BAD_USER_INPUT &&
        extensions.code !== ErrorCode.GRAPHQL_VALIDATION_FAILED
      ) {
        const { code, actionRequired } = extensions;
        if (code === ErrorCode.UNAUTHENTICATED || code === ErrorCode.FORBIDDEN) {
          if (code === ErrorCode.UNAUTHENTICATED) logout();
          else if (!message) alertError("You are not authorized to perform that operation");
          else if (actionRequired) {
            alertAction(message);
          } else alertError(message);
        }
        console.error(err);
      } else {
        alertError();
        console.error(err);
      }
    });
  }
  if (networkError && !("statusCode" in networkError && networkError?.statusCode < 500)) {
    alertError();
    console.log(`[Network error]: ${networkError}`);
  }
});

// Creating apollo client
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, mutationLink, authLink, operationBasedLink]),
  cache: new InMemoryCache()
});

// Export function close websocket connection (for logout)
export const closeWebsocket = () => {
  wsClient.close();
};

export default client;
