import { Collapse, Drawer, Hidden, List, ListItem, ListItemText, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { FC, ReactNode, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SIDEBAR_ROUTES } from "../constants/routes";
import sidebarImage from "../images/sidebar.jpg";
import { SidebarRoute } from "../utils/globalTypes";
import {
  BLACK_COLOR,
  BOX_SHADOW,
  hexToRgbValues,
  SIDEBAR_WIDTH,
  WHITE_COLOR
} from "../utils/styles";

type SidebarProps = {
  logo: string;
  logoText: string;
  toggleDrawerOpen: boolean;
  closeDrawer: () => void;
};

const Sidebar: FC<SidebarProps> = ({ logo, logoText, toggleDrawerOpen, closeDrawer }) => {
  const { classes, cx } = useStyles();
  const location = useLocation();
  // A map that maps sidebar item name to boolean, indicating if the item is expanded or not
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

  const brand = (
    <div className={classes.logo}>
      <img src={logo} alt="logo" className={classes.logoImage} />
      <Typography variant="h6" display="inline">
        {logoText}
      </Typography>
    </div>
  );

  const renderLinks = (routes: SidebarRoute[]) => {
    return (
      <List>
        {routes.map((sidebarRoute, key) => {
          const { icon: Icon } = sidebarRoute;
          let path: string;
          let name: string;
          let accordion: ReactNode = null;
          let activeClassName = "";
          let onClick = () => closeDrawer();
          // If it's a single route
          if ("route" in sidebarRoute) {
            path = sidebarRoute.route.path;
            name = sidebarRoute.route.name!;
            activeClassName = classes.activeItem;
            // If it's a list of routes
          } else {
            path = location.pathname;
            name = sidebarRoute.name;
            accordion = (
              <b
                className={cx(classes.accordion, {
                  [classes.expandedAccordion]: expandedItems[name]
                })}
              />
            );
            onClick = () => {
              setExpandedItems((prev) => ({
                ...prev,
                [name]: !prev[name]
              }));
            };
          }

          return (
            <div key={key}>
              <NavLink
                end
                to={path}
                className={({ isActive }) =>
                  cx(classes.item, {
                    [activeClassName]: isActive
                  })
                }
                onClick={onClick}
              >
                <ListItem button className={classes.itemLink}>
                  {typeof Icon !== "string" ? (
                    <Icon className={classes.itemIcon} />
                  ) : (
                    <div className={classes.itemIcon}>{Icon}</div>
                  )}
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "body2",
                      component: "div"
                    }}
                  >
                    {name}
                    {accordion}
                  </ListItemText>
                </ListItem>
              </NavLink>
              {"routes" in sidebarRoute && (
                <Collapse in={expandedItems[name]} timeout="auto" unmountOnExit>
                  {renderLinks(
                    sidebarRoute.routes.map((route) => ({
                      route,
                      icon: "\u2013"
                    }))
                  )}
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    );
  };

  const links = renderLinks(SIDEBAR_ROUTES);

  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={toggleDrawerOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={closeDrawer}
          ModalProps={{
            keepMounted: true
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={classes.background} />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: cx(classes.drawerPaper)
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          <div className={classes.background} />
        </Drawer>
      </Hidden>
    </div>
  );
};

const activeItemStyle = (color: string) => ({
  backgroundColor: color,
  boxShadow:
    "0 12px 20px -10px rgba(" +
    hexToRgbValues(color) +
    ",.28), 0 4px 20px 0 rgba(" +
    hexToRgbValues(BLACK_COLOR) +
    ",.12), 0 7px 8px -5px rgba(" +
    hexToRgbValues(color) +
    ",.2)"
});

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    color: WHITE_COLOR,
    border: "none",
    ...BOX_SHADOW
  },
  logo: {
    position: "relative",
    padding: theme.spacing(2.5, 2),
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      height: "1px",
      right: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
      backgroundColor: "rgba(" + hexToRgbValues("#b4b4b4") + ", 0.3)"
    }
  },
  logoImage: {
    width: "35px",
    margin: theme.spacing(0, 1.25)
  },
  sidebarWrapper: {
    width: SIDEBAR_WIDTH,
    overflow: "auto",
    zIndex: 1
  },
  background: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: `url(${sidebarImage})`,
    "&:after": {
      position: "absolute",
      width: "100%",
      height: "100%",
      content: '""',
      background: BLACK_COLOR,
      opacity: ".8"
    }
  },
  item: {
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: WHITE_COLOR
    }
  },
  activeItem: {
    "& $itemLink": {
      ...activeItemStyle(theme.palette.primary.main),
      "&:hover,&:focus": {
        ...activeItemStyle(theme.palette.primary.main)
      }
    }
  },
  itemLink: {
    width: "auto",
    margin: theme.spacing(1.25, 2, 0),
    padding: theme.spacing(1.25, 2),
    "&:hover": {
      outline: "none",
      boxShadow: "none",
      backgroundColor: "rgba(200, 200, 200, 0.2)"
    }
  },
  itemIcon: {
    marginRight: theme.spacing(2),
    opacity: 0.8,
    width: 24,
    textAlign: "center"
  },
  accordion: {
    right: 18,
    width: 0,
    height: 0,
    display: "inline-block",
    position: "absolute",
    borderTop: "4px solid",
    marginTop: 8,
    transition: "all 150ms ease-in",
    borderLeft: "4px solid transparent",
    marginLeft: 2,
    borderRight: "4px solid transparent"
  },
  expandedAccordion: {
    transform: "rotate(180deg)"
  }
}));

export default Sidebar;
