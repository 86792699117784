import { ApolloError } from "@apollo/client";
import { download } from "@placehires/react-component-library";
import { camelCase, omitBy } from "lodash";
import { parse, ParseConfig, unparse } from "papaparse";

export const parseFirstCsvRow = async (csvFile: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    parse<string[]>(csvFile, {
      step(results, parser) {
        parser.abort();
        resolve(results.data);
      },
      complete() {},
      error: reject
    });
  });
};

export const parseCsvIntoInsertItems = async (
  csvFile: File,
  options: Omit<ParseConfig, "header" | "complete" | "error"> = {}
): Promise<unknown[]> => {
  return new Promise((resolve, reject) => {
    parse(csvFile, {
      header: true,
      transformHeader: camelCase,
      skipEmptyLines: "greedy",
      complete: (res) => resolve(res.data),
      error: reject,
      ...options
    });
  });
};

export const reportFailedInsertItems = (
  err: ApolloError,
  reportFileNameWithoutExtension: string
) => {
  const writeErrors = err.graphQLErrors[0].extensions?.writeErrors;
  if (writeErrors && writeErrors.length) {
    const csv = unparse(
      writeErrors.map((error: any) => ({
        ...omitBy(error.op, (v, k) => k === "__v"),
        "Error Message (Fail Reason)": error.errmsg
      }))
    );
    download(`${reportFileNameWithoutExtension}.csv`, csv);
  }
};
