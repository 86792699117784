import { Grid } from "@mui/material";
import {
  FormikDialog,
  FormikTextField,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import React from "react";
import { object, string } from "yup";
import { useSignUpAdminMutation } from "../../generated/graphqlHooks";
import { AdminsDocument, ErrorCode } from "../../generated/graphqlTypes";

type CreateAdminProps = {
  open: boolean;
  handleClose: () => void;
};

const CreateAdminModal: React.FC<CreateAdminProps> = ({ open, handleClose }) => {
  const [createAdmin] = useSignUpAdminMutation();

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Create new Admin"
      formikConfig={{
        initialValues: {
          firstName: "",
          lastName: "",
          email: "",
          password: ""
        },
        validationSchema: object({
          email: string().email(),
          password: string().min(6, "Please use at least 6 characters")
        }),
        onSaveSubmit: async (values) => {
          await createAdmin({
            variables: {
              userInput: values
            },
            update: (cache, mutationResult) => {
              updateCacheAfterAdd(cache, AdminsDocument, mutationResult.data?.signUpAdmin);
            }
          });
        },
        errorsToFieldName: {
          [ErrorCode.CONFLICT]: {
            fieldName: "email",
            customErrorMessage: "User with this email already exists!"
          }
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormikTextField required name="firstName" autoFocus fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField required name="lastName" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField required name="email" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField required name="password" type="password" fullWidth />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default CreateAdminModal;
