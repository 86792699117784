import { People } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import { openNewTab } from "@placehires/react-component-library";
import GenericTable from "../components/table/GenericTable";
import { useUserFeedbackQuery } from "../generated/graphqlHooks";

const { REACT_APP_MAIN_CLIENT_URL } = process.env;
const UserFeedback = () => {
  const { data, loading } = useUserFeedbackQuery({
    variables: {
      page: 1,
      itemsPerPage: 5
    }
  });

  return (
    <GenericTable
      title="User feedback"
      description="User feedback with rating"
      columns={[
        {
          field: "rating",
          headerName: "Rating",
          width: 200,
          renderCell: (rating) => {
            return <Rating name="readonly" value={rating.value as number} readOnly />;
          }
        },
        "message"
      ]}
      rows={data?.feedback}
      actions={[
        {
          name: "View user who submitted feedback ",
          icon: People,
          onClick: (userFeedback) => {
            openNewTab(`${REACT_APP_MAIN_CLIENT_URL}/app/${userFeedback.sender}`);
          }
        }
      ]}
      loading={loading}
    />
  );
};

export default UserFeedback;
