import { adaptV4Theme, createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#4a148c"
      },
      secondary: {
        main: "#8d0982"
      },
      error: {
        main: "#d32f2f"
      },
      background: {
        default: "#eee"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {}
      }
    }
  })
);

theme = responsiveFontSizes(theme);

export type PaletteColorName = "primary" | "secondary" | "error" | "warning" | "info" | "success";

export default theme;
