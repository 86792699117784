import {
  FormikDialog,
  FormikTextField,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import React from "react";
import {
  useCreateCertificationMutation,
  useCreateSkillMutation
} from "../../generated/graphqlHooks";
import {
  CertificationAllDocument,
  ErrorCode,
  SkillAllDocument
} from "../../generated/graphqlTypes";

type CreateSkillModalProps = {
  open: boolean;
  handleClose: () => void;
  type: "skill" | "certification";
};

const CreateSkillAndCertificationModal: React.FC<CreateSkillModalProps> = ({
  open,
  handleClose,
  type
}) => {
  const [createSkill] = useCreateSkillMutation();
  const [createCertification] = useCreateCertificationMutation();

  const modalTitle = type === "skill" ? "Create new skill" : "Create new certification";

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title={modalTitle}
      formikConfig={{
        initialValues: {
          name: ""
        },
        onSaveSubmit: async ({ name }) => {
          if (type === "skill") {
            await createSkill({
              variables: {
                skillFields: {
                  name
                }
              },
              update: (cache, mutationResult) => {
                updateCacheAfterAdd(cache, SkillAllDocument, mutationResult.data?.createSkill, {
                  unshift: true
                });
              }
            });
          } else {
            await createCertification({
              variables: {
                name
              },
              update: (cache, mutationResult) => {
                updateCacheAfterAdd(
                  cache,
                  CertificationAllDocument,
                  mutationResult.data?.createCertification,
                  {
                    unshift: true
                  }
                );
              }
            });
          }
        },
        errorsToFieldName: () => ({
          [ErrorCode.CONFLICT]: {
            fieldName: "name",
            customErrorMessage: `${
              type === "skill" ? "Skill" : "Certificate"
            } with this name already exists!`
          }
        })
      }}
    >
      <FormikTextField
        required
        autoFocus
        name="name"
        label={type === "skill" ? "Skill name" : "Certification name"}
        fullWidth
      />
    </FormikDialog>
  );
};

export default CreateSkillAndCertificationModal;
