import { Delete, Edit } from "@mui/icons-material";
import { updateCacheAfterRemove } from "@placehires/react-component-library";
import React, { useState } from "react";
import GenericTable from "../../components/table/GenericTable";
import { useDeletePositionMutation, usePositionAllQuery } from "../../generated/graphqlHooks";
import { Position, PositionAllDocument } from "../../generated/graphqlTypes";
import { useConfirmDelete } from "../../hooks/confirmHooks";
import useModalState from "../../hooks/useModalState";
import CreateAndUpdateJobTemplateModal from "./CreateAndUpdateJobTemplateModal";
import CreatePositionModal from "./CreatePositionModal";

const ManagePositions = () => {
  const { data, loading } = usePositionAllQuery();
  const [deletePosition] = useDeletePositionMutation();
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
  const [positionModalOpen, handlePositionModalOpen, handlePositionModalClose] = useModalState();
  const [jobTemplateModalOpen, handleJobTemplateModalOpen, handleJobTemplateModalClose] =
    useModalState();
  const confirmDelete = useConfirmDelete();

  return (
    <>
      <GenericTable
        title="Positions"
        description="Manage all positions and their job templates"
        columns={[
          "name",
          {
            field: "jobTemplate",
            headerName: "Has a Template",
            width: 200,
            type: "boolean"
          }
        ]}
        actions={[
          {
            name: (position) => `${position.jobTemplate ? "Edit" : "Create"} Job template`,
            icon: Edit,
            onClick: (position) => {
              setSelectedPosition(position);
              handleJobTemplateModalOpen();
            }
          },
          {
            name: "Delete Position",
            icon: Delete,
            onClick: (position) => {
              confirmDelete(() => {
                deletePosition({
                  variables: {
                    id: position._id
                  },
                  update: (cache) => {
                    updateCacheAfterRemove(cache, PositionAllDocument, position._id);
                  }
                }).catch((_) => {});
              });
            }
          }
        ]}
        rows={data?.positions}
        loading={loading}
        toolbarProps={{
          primaryButton: {
            text: "Create new Position",
            onClick: handlePositionModalOpen
          }
        }}
      />

      <CreatePositionModal open={positionModalOpen} handleClose={handlePositionModalClose} />
      <CreateAndUpdateJobTemplateModal
        position={selectedPosition}
        open={jobTemplateModalOpen}
        handleClose={handleJobTemplateModalClose}
      />
    </>
  );
};

export default ManagePositions;
